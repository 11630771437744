/* eslint space-before-function-paren: 0 */
/* eslint object-curly-spacing: 0 */

let tutorials = [
  {
    title: 'Kostenloser Interaktiver Java Kurs',
    videoId: 'l3iQuwcE0qI',
    videoScript: `<h4>Hey!</h4>
<p>Das ist ein interaktiver Java Kurs für Anfänger.
Kein Gelaber, los geht's!</p>
<p>Eines der wichtigsten Statements in Java ist dieses hier:</p>
<div class="codeBlock">System.out.println();</div>
<p>Mit dieser Zeile lässt sich ein beliebiger Text auf der Konsole ausgeben. Die Konsole ist nichts anderes als ein Kasten, der dir Ergebnisse deiner Programme in Textform anzeigen kann.
Später wird es auch möglich sein, über die Konsole Nutzereingaben einzulesen und als Input für dein Programm zu verwenden.</p>
<p>Innerhalb der runden Klammern lässt sich in Gänsefüßchen der Text übergeben, den du auf der Konsole ausgeben willst. Beispielsweise erscheint nach Ausführung von</p>
<div class="codeBlock">System.out.println("Hallo");</div>
<p>das Wort <code>Hallo</code> auf der Konsole. Wichtig dabei ist, nicht das Semikolon am Ende zu vergessen, sonst wirst du eine Fehlermeldung bekommen.</p>
<p>Vielleicht hast du bereits das folgende kryptische Statement gesehen:</p>
<div class="codeBlock">public static void main(String[] args) {
}</div>
<p>Dabei handelt es sich um die <code>main</code>-Methode. Alles was innerhalb der geschweiften Klammern steht, wird Zeile für Zeile ausgeführt. Was genau diese Schlüsselwörter bedeuten, würde ich dir am liebsten sofort erklären. Allerdings musst du dafür verstehen, was objektorientierte Programmierung ist. Ich möchte dir zuerst allgemeinere Grundkonzepte von Programmierung näherbringen, bevor wir uns dieses Thema genauer anschauen. Aber keine Sorge, wir werden das noch alles im Detail besprechen.</p>
<p>Normalerweise würde an dieser Stelle nun eine komplizierte Erklärung kommen, was du alles installieren musst, um auf deinem PC Java programmieren zu können. Stattdessen kannst du hier direkt im Web-Browser loslegen. Später wirst du das natürlich nicht mehr so machen, aber so gibt es zumindest keine Ausrede mehr, nicht direkt zu starten!</p>`,
    taskDescription: `<h4>Jetzt bist du dran!</h4>
<p>Schreibe das Statement</p>
<div class="codeBlock">System.out.println("Orcas fressen Pinguine!");</div>
<p>innerhalb der <code>main</code>-Methode. Klicke anschließend auf "Execute", um dein Programm auszuführen.</p>`,
    jDoodleId: '4pEV',
    resultDescription: '<p>Wenn dein Programm funktioniert, solltest du nach Ausführung <code>Orcas fressen Pinguine!</code> auf der Konsole sehen. Versuche als nächstes, mehrere Zeilen Text hintereinander auszugeben. Wenn alles funktioniert hat, kannst du unten rechts zum nächsten Thema wechseln.',
    possibleMistakes: [
      'Du hast das Semikolon (<b>;</b>) nach dem Statement vergessen',
      'Du hast falsche Gänsefüßchen verwendet',
      'Das Statement ist an der falschen Stelle - Es muss innerhalb der geschweiften Klammern nach <code>main(String args[])</code> stehen',
      'Du hast irgendwo einen Zeichendreher - Geh am besten nochmal die Zeile Zeichen für Zeichen durch'
    ],
    solutionCode: `public class Example {
    public static void main(String[] args) {
        System.out.println("Orcas fressen Pinguine!");
        System.out.println("Dies ist die zweite Zeile");
        System.out.println("Und noch eine dritte");
    }
}`
  },
  {
    title: 'String Variablen und Konkatenation',
    videoId: 'ZYWh2Ecfamc',
    videoScript: `<p>Ein wichtiges Konzept in der Programmierung sind Variablen. Eine Variable ist ein Platzhalter für einen Wert, der sich im laufe eines Programms ändern kann. Wir beschäftigen uns zuerst mit Variablen, die Text speichern. Diese nennt man <code>String</code>-Variablen oder <code>String</code>s.</p>
<p>Eine Variable kann folgendermaßen <i>deklariert</i>, also erstmalig beschrieben werden:<p>
<div class="codeBlock">String superHero;</div>
<p>Dabei ist <code>String</code> der Variablentyp, in unserem Fall ein Text, und <code>superHero</code> der Variablenname. Dieser ist (bis auf einige Einschränkungen bzgl. Zahlen und Sonderzeichen) frei wählbar. Um einer Variable einen Wert zuzuweisen, kann man den Zuweisungsoperator <code>=</code> verwenden:</p>
<div class="codeBlock">superHero = "Iron Man";</div>
<p>Häufig werden diese beiden Schritte zusammengefasst zu einer Zeile:</p>
<div class="codeBlock">String superHero = "Iron Man";</div>
<p>Anstatt nun den immer gleichen Text auf der Konsole auszugeben, können wir die Variable als Platzhalter verwenden:</p>
<div class="codeBlock">String superHero = "Iron Man";
System.out.println("Der Superheld ist " + superHero);

superHero = "Thor";
System.out.println("Der Superheld ist jetzt " + superHero);</div>
<div class="info">
    <p><code>+</code> ist der Verkettungs-Operator, der unterschiedliche <code>String</code>s zu einem einzelnen zusammenfügt. Man spricht auch von <i>Konkatenation</i> (Verkettung).</p>
</div>
<p>Das Ergebnis des obigen Programmschnipsels wäre dann:</p>
<pre>Der Superheld ist Iron Man
Der Superheld ist jetzt Thor</pre>`,
    taskDescription: `<p>Deine Aufgabe ist es nun, zwei <code>String</code>-Variablen namens <code>firstName</code> und <code>lastName</code> zu deklarieren und ihnen Werte zuzuweisen. Anschließend soll auf der Konsole folgender Text ausgegeben werden:</p>
<pre>Guten Tag, VORNAME NACHNAME!</pre>
<p>Hierbei soll VORNAME und NACHNAME jeweils den Werten der Variablen entsprechen.</p>`,
    jDoodleId: '4pEV',
    resultDescription: 'Überprüfe anschließend die Konsolenausgabe, ob auch wirklich exakt der gewünschte Text ausgegeben wurde.',
    possibleMistakes: [
      'Du hast ein Semikolon vergessen',
      'Du hast ein Gänsefüßchen zu viel oder zu wenig',
      'Du hast das Leerzeichen zwischen den zwei Variablen vergessen',
      'Du hast zwar die Variablen deklariert, ihnen aber keine Werte zugewiesen'
    ],
    solutionCode: `public class Example {
    public static void main(String[] args) {
        String firstName = "Peter";
        String lastName = "Lustig";

        System.out.println("Guten Tag, " + firstName + " " + lastName + "!");
    }
}`
  },
  {
    title: 'Ganzzahlen: Das int-Keyword</br>und die vier Grundrechenarten',
    videoId: '8cYBB0vqhxo',
    videoScript: `<p>Neben <code>String</code>s gibt es noch viele weitere Datentypen. Um mit Zahlen umgehen zu können, kannst du das <code>int</code>-Keyword verwenden. <code>int</code> steht für Integer, was Ganzzahl bedeutet. Damit können also Zahlen wie z.B. 42, 69, 0, oder -31415 dargestellt werden. Deklaration und Zuweisung einer <code>int</code>-Variable funktioniert analog zu <code>String</code>s:</p>
<div class="codeBlock">int n = 12;</div>
<p>Um verschiedene Werte miteinander zu verrechnen, gibt es Operatoren für die vier Grundrechenarten: <code>+</code>, <code>-</code>, <code>*</code>, <code>/</code>. Betrachten wir folenden Programmcode:</p>
<div class="codeBlock">int a = 5;
int b = 7;

int c = 2 * (a - 3) + b;</div>
<p><code>c</code> hat am Ende den Wert <code>2 * (5 - 3) + 7</code>, was 11 ergibt.</p>
<div class="attention">
    <p>Vorsicht! Bei Divisionen mit <code>int</code> wird das Ergebnis zur nächsten ganzen Zahl abgerundet. Somit ergibt also beispielsweise <code>7 / 2</code> <code>3</code>, denn 7÷2 = 3.5 .</p>
</div>
<p>Das Ergebnis einer Rechnung kann auch einer Variable zugewiesen werden, die selbst in der Rechnung vorkommt:</p>
<div class="codeBlock">int n = 2;
n = n + 3;</div>
<p>Hier wird der Wert der Variable <code>n</code> mit 3 addiert und anschließend wieder in <code>n</code> gespeichert. <code>n</code> hat also danach den Wert 5.</p>
<p>Du hast sicher bemerkt, dass das <code>+</code>-Zeichen hiermit zwei Bedeutungen hat: Konkatenation und Addition:</p>
<div class="info">
    <p>Wenn ein <code>String</code> mit einem <code>int</code> konkateniert wird, wird die Zahl automatisch vor der eigentlichen Operation zu einem Text umgewandelt. Das Ergebnis ist dann also auch wieder ein <code>String</code>:</p>
    <p><code>"Hallo" + 42</code> wird ausgewertet (<i>evaluiert</i>) zu <code>"Hallo42"</code></p>
</div>`,
    taskDescription: `<p>Aufgabe:</p>
<ul>
    <li>Deklariere eine <code>int</code>-Variable <code>counter</code>, die mit dem ursprünglichen Wert <code>7</code> initialisiert wird</li>
    <li>Anschließend soll <code>counter</code> mit dem Text <code>Aktuelle Zahl: COUNTER</code> ausgegeben werden</li>
    <li><code>counter</code> wird um eins erhöht</li>
    <li>Zuletzt folgt noch einmal eine Ausgabe mit dem neuen Wert</li>
</ul>`,
    jDoodleId: '4pEV',
    resultDescription: `<p>Die Ausgabe sollte so aussehen:</p>
<pre>Aktuelle Zahl: 7
Aktuelle Zahl: 8</pre>`,
    possibleMistakes: [
      'Semikolon vergessen',
      'Leerzeichen nach dem Doppelpunkt vergessen',
      '<code>counter</code> wird zwar mit <code>1</code> addiert, aber nicht neu zugewiesen'
    ],
    solutionCode: `public class Example {
    public static void main(String[] args) {
        int counter = 7;
        System.out.println("Aktuelle Zahl: " + counter);

        counter = counter + 1;
        System.out.println("Aktuelle Zahl: " + counter);
    }
}`
  },
  {
    title: 'Bedingungen und Vergleiche: If-Else',
    videoId: 'Ip9Q9F6vHfI',
    videoScript: `<p>Bisher haben wir ziemlich langweilige Programme geschrieben, die keine wirkliche Programmlogik enthalten. Oft wollen wir aber einen bestimmten Programmteil nur dann ausführen, wenn eine bestimmte Bedingung zutrifft. Eine derartige Überprüfung kann mit einer <code>if</code>-Abfrage umgesetzt werden:</p>
<div class="codeBlock">if (BEDINGUNG) {
    // Dieser Teil wird nur ausgeführt, wenn BEDINGUNG zutrifft
}</div>
<p>BEDINGUNG könnte hierbei beispielsweise ein Vergleich zweier Variablen oder einer Variable mit einem <i>Literal</i> (z.B. einer konkreten Zahl) sein:</p>
<div class="codeBlock">int a = 2;
int b = 3;

if (a < 10) {
    System.out.println("a ist kleiner als zehn");
}
if (a > 2) {
    System.out.println("a ist größer als zwei");
}
if (b <= 3) {
    System.out.println("b ist kleiner oder gleich drei");
}
if (a >= b) {
    System.out.println("a ist größer oder gleich b");
}
if (a == b) {
    System.out.println("a und b sind genau gleich");
}
if (a != b) {
    System.out.println("a und b sind nicht gleich");
}</div>
<p>Das Ergebnis in diesem Beispiel wäre dann folgende Konsolenausgabe:</p>
<pre>a ist kleiner als zehn
b ist kleiner oder gleich drei
a und b sind nicht gleich</pre>
<div class="attention">
    <p>Achtung: <code>=</code> und <code>==</code> sind zwei komplett unterschiedliche Operatoren!</p>
    <ul>
        <li><code>=</code> ist der Zuweisungsoperator, der einer Variable einen neuen Wert gibt</li>
        <li><code>==</code> ist der Gleichheitsoperator, der zwei Werte auf exakte Übereinstimmung überprüft</li>
    </ul>
</div>
<p>Damit ein bestimmter Programmteil ausgeführt wird, wenn die Bedingung in der <code>if</code>-Abfrage genau nicht wahr ist, kannst du das <code>else</code>-Keyword verwenden:</p>
<div class="codeBlock">int a = 7;

if (a > 10) {
    System.out.println("a ist größer als 10");
} else {
    System.out.println("a ist nicht größer als 10");
}</div>
<p>Du kannst auch in einem <code>else</code>-Block eine weitere Abfrage durchführen, indem du <code>else if</code> verwendest:</p>
<div class="codeBlock">if (a > 11) {
    System.out.println("a ist größer als 11");
} else if (a > 7) {
    System.out.println("a ist nicht größer als 11, aber größer als 7");
} else if (a == 7) {
    System.out.println("a ist nicht größer als 7, sondern genau 7");
}</div>
<div class="attention">
    <p>Eine Sache noch: Vergleiche niemals, niemals, niemals <code>String</code>s mit <code>==</code> bzw. <code>!=</code>!
Dabei kann ziemlich viel schief gehen. Wie du <code>String</code>s auf Gleichheit prüfen kannst zeige ich dir später noch.</p>
</div>`,
    taskDescription: `<p>In der folgenden Aufgabe sind bereits die Variablen <code>a</code>, <code>b</code>, und <code>op</code> gegeben. Das Programm soll die Zahlen <code>a</code> und <code>b</code> miteinander verrechnen. Dabei entscheidet <code>op</code> darüber, welche Grundrechenoperation verwendet wird:</p>
<ul>
    <li>Für <code>op == 0</code> soll <code>a</code> mit <code>b</code> addiert werden</li>
    <li>Für <code>op == 1</code> soll <code>b</code> von <code>a</code> subtrahiert werden</li>
    <li>Für <code>op == 2</code> soll <code>a</code> mit <code>b</code> multipliziert werden</li>
    <li>Für <code>op == 3</code> soll <code>a</code> durch <code>b</code> dividiert werden</li>
    <li>Falls <code>op</code> eine andere Zahl ist, soll <code>Ungültige Operation.</code> ausgegeben werden</li>
</ul>
<p>Die Rechnung soll (im Falle korrekter Eingaben) wie folgt auf der Konsole ausgegeben werden: <code>7 - 2 = 5</code></p>
<p>(Für die beispielhaften Eingaben <code>a = 7</code>, <code>b = 2</code>, <code>op = 1</code>)</p>
<div class="info">
    <p>Alles was in einer Zeile rechts von <code>//</code> steht, ist ein Kommentar. Kommentare sind für die Programmausführung irrelevant und werden ignoriert:</p>
    <div class="codeBlock">// Diese Zeile wird komplett ignoriert</div>
    <p>Mehrere Zeilen hintereinander lassen sich auch folgendermaßen auskommentieren:</p>
    <div class="codeBlock">/*
 * Erste Zeile
 * Zweite Zeile
 */</div>
</div>`,
    jDoodleId: '4rGt',
    resultDescription: '<p>Teste dein Programm mit unterschiedlichen Eingaben. ' +
      'Was passiert, wenn <code>b = 0</code> und <code>op = 3</code> ist? ' +
      'Behebe dieses Problem durch einen weiteren <code>if</code>-<code>else</code>-Block.</p>',
    possibleMistakes: [
      'Semikolon vergessen',
      '<code>=</code> statt <code>==</code> verwendet',
      'Fehlermeldung bei falscher Operation vergessen',
      'Leerzeichen vor/nach Operation oder vor/nach dem = vergessen',
      'Klammern um Addition vergessen, dadurch werden <code>a</code> und <code>b</code> in <code>String</code>s umgewandelt und konkateniert, anstatt miteinander addiert zu werden',
      'Klammern um Subtraktion vergessen, dadurch kommt der Fehler <code>bad operand types for binary operator \'-\'</code>',
      'Sonderfall "Division durch 0" nicht beachtet'
    ],
    solutionCode: `public class Calculator {
    public static void main(String[] args) {
        /*
         * Die beiden Operanden
         */
        int a = 6;
        int b = 2;

        /*
         * Art der Rechenoperation
         *
         * 0 steht für Addition
         * 1 steht für Subtraktion
         * 2 steht für Multiplikation
         * 3 steht für Division
         */
        int op = 0;

        if (op == 0) {
            System.out.println(a + " + " + b + " = " + (a + b));
        } else if (op == 1) {
            System.out.println(a + " - " + b + " = " + (a - b));
        } else if (op == 2) {
            System.out.println(a + " * " + b + " = " + (a * b));
        } else if (op == 3) {
            if (b == 0) {
                System.out.println("Es darf nicht durch null geteilt werden!");
            } else {
                System.out.println(a + " / " + b + " = " + (a / b));
            }
        } else {
            System.out.println("Ungültige Operation.");
        }
    }
}`
  },
  {
    title: 'While Schleife',
    videoId: '6Io2iDgnMr4',
    videoScript: `<p>Stell dir vor du möchtest 1000 Zeilen auf der Konsole ausgeben. Dafür bräuchtest du 1000 Zeilen Code, in denen jeweils immer der gleiche Befehl steht. Das wäre ziemlich dämlich, oder?</p>
<p>Für Probleme dieser Art gibt es Schleifen, die die gleiche Abfolge von Statements wiederholt ausführen. Eine dieser Schleifen ist die <code>while</code>-Schleife:</p>
<div class="codeBlock">while (BEDINGUNG) {
    // Das was wiederholt ausgeführt werden soll
}</div>
<div class="info">
    <p><code>while</code>-Schleifen funktionieren ähnlich wie <code>if</code>-Abfragen, nur mit dem Unterschied, dass nach Ausführung des inneren Blocks erneut die Bedingung überprüft wird. Der Inhalt der <code>while</code>-Schleife wird also ausgeführt, <i>solange</i> die Bedingung wahr ist.</p>
</div>
<p>Schauen wir uns diesen Code an:</p>
<div class="codeBlock">int i = 0;
while (i < 3) {
    System.out.println(i);
    i = i + 1;
}</div>
<p>Folgende Dinge passieren:</p>
<ul>
    <li>Im ersten Durchlauf ist <code>i == 0</code>, was kleiner als 3 ist. Also wird der Schleifenblock ausgeführt. Auf der Konsole erscheint <code>0</code> und <code>i</code> wird auf 1 erhöht.</li>
    <li>Im zweiten Durchlauf ist <code>i == 1</code>. 1 ist kleiner als 3, also wird <code>1</code> ausgegeben und <code>i</code> auf 2 erhöht.</li>
    <li>Im dritten Durchlauf ist <code>i == 2</code>, was immer noch kleiner als 3 ist. Ausgabe: <code>2</code>, <code>i</code> wird auf 3 gesetzt.</li>
    <li>Im vierten Durchlauf ist <code>i == 3</code>. Da 3 nicht kleiner ist als 3, ist die Bedingung der <code>while</code>-Schleife nicht mehr erfüllt. Das Programm springt zum Ende der Schleife.</li>
</ul>
<p>Die Ausgabe ist also:</p>
<pre>0
1
2</pre>
<div class="info">
    <p>Falls die Bedingung einer <code>while</code>-Schleife immer wahr ist, wird das Programm sich nicht selbst beenden.* Man spricht dann davon, dass das Programm nicht <i>terminiert</i>. Stattdessen wird es so lange weiter laufen, bis es durch einen anderen Prozess beendet wird.** Es kann durchaus gewünscht sein, dass ein Programm "unendlich" lange läuft. Beispielsweise ein Server, der auf Anfragen wartet, sollte sich (im Normalfall) nicht nach Bearbeitung einer einzigen Anfrage beenden, sondern auf weitere warten.</p>
</div>
<p>*Es gibt trotzdem noch Möglichkeiten, ein solches Programm zu beenden. Wir ignorieren das an dieser Stelle aber.</p>
<p>**Hier im Online-Editor gibt es aber trotzdem Limits, wie lange ein Programm maximal laufen darf, und wie viele Zeilen maximal ausgegeben werden können.</p>`,
    taskDescription: '<p>In dieser Aufgabe sollst du alle Vielfachen einer gegebenen Zahl <code>n</code> bis zu einem gewissen Maximum <code>max</code> auf der Konsole ausgeben. Beispielsweise sollen für die Eingabe <code>n = 7</code> und <code>max = 21</code> zeilenweise 7, 14, und 21 ausgegeben werden.</p>',
    jDoodleId: '4rHs',
    resultDescription: '<h4>Bonus</h4><p>Teste was passiert, wenn <code>n</code> kleiner ist als 1. Wie könntest du dieses Problem beheben?</>',
    possibleMistakes: [
      'Semikolon... du weißt schon',
      'Mit falscher Zahl begonnen: Die erste auszugebende Zahl ist <code>n</code>',
      'Maximum falsch: Falls <code>max</code> durch <code>n</code> teilbar ist, soll <code>max</code> die letzte Zahl sein, die ausgegeben wird. Du musst also mit <code><=</code> vergleichen.',
      'Schrittweite falsch: Nach jedem Schleifendurchlauf muss die nächste Zahl genau <code>n</code> größer sein als die aktuelle'
    ],
    solutionCode: `public class Multiples {
    public static void main(String[] args) {
        // Zahl, deren Vielfache ausgegeben werden sollen
        int n = 7;

        // Höchste Zahl, die ausgegeben werden könnte
        int max = 50;

        // Überprüfen ob n positiv ist
        if (n < 1) {
            System.out.println("n muss positiv sein");
        } else {
            int i = n;
            while (i <= max) {
                System.out.println(i);
                i = i + n;
            }
        }
    }
}`
  },
  {
    title: 'For Schleife',
    videoId: '-5lIkItQECc',
    videoScript: `<p>Mindestens so wichtig wie <code>while</code>-Schleifen sind <code>for</code>-Schleifen. Diese können alles, was <code>while</code>-Schleifen auch können, bestehen aber insgesamt aus drei Teilen:</p>
<ul>
    <li>Initialisierung: Dieser Teil wird einmal zu Beginn der Schleife ausgeführt. Hier wird häufig eine Variable deklariert und initialisiert.</li>
    <li>Bedingung (Analog zur <code>while</code>-Schleife): Legt die Bedingung fest, die gelten muss, damit die Schleife erneut ausgeführt wird</li>
    <li>Inkrement: Wird nach jedem Schleifendurchlauf ausgeführt und eignet sich daher perfekt, um eine Variable zu erhöhen (<i>inkrementieren</i>) oder verringern (<i>dekrementieren</i>)</li>
</ul>
<p>Diese drei Teile werden innerhalb der runden Klammern durch Semikolons getrennt:</p>
<div class="codeBlock">for (INITIALISIERUNG; BEDINGUNG; INKREMENT) {
    // Statements für jeden Schleifendurchlauf
}</div>
<p>Eine <code>for</code>-Schleife, die alle Zahlen von 1 bis 100 ausgibt, würde also beispielsweise so aussehen:</p>
<div class="codeBlock">for (int i = 1; i <= 100; i = i + 1) {
    System.out.println(i);
}</div>
<p>Die Ausführung ist identisch zu folgendem Code mit einer <code>while</code>-Schleife:</p>
<div class="codeBlock">int i = 1;
while (i <= 100) {
    System.out.println(i);
    i = i + 1;
}</div>
<div class="info">
    <p>Weil es extrem häufig vorkommt, dass eine Variable um eine gewisse Zahl erhöht oder verringert werden soll, gibt es dafür Kurzschreibweisen:</p>
    <ul>
        <li><code>a = a + b</code> kann abgekürzt werden zu <code>a += b</code>*</li>
        <li><code>a = a - b</code> kann abgekürzt werden zu <code>a -= b</code>*</li>
        <li><code>a = a + 1</code> kann abgekürzt werden zu <code>a++</code> oder <code>++a</code>**</li>
        <li><code>a = a - 1</code> kann abgekürzt werden zu <code>a--</code> oder <code>--a</code>**</li>
    </ul>
    <p>Somit erhöhen alle der drei unteren Zeilen die Variable <code>i</code> um 1:</p>
    <div class="codeBlock">int i = 0;

i = i + 1;
i += 1;
i++;

// i ist jetzt 3</div>
</div>
<p>* Diese sogenannten <i>Verbundzuweisungen</i> funktionieren übrigens auch für andere Operationen, wie beispielsweise Multiplikation (<code>*=</code>) oder Division (<code>/=</code>)</p>
<p>** <code>a++</code> und <code>++a</code>, sowie <code>a--</code> und <code>--a</code> sind nicht exakt identisch. Falls du wissen willst, worin genau der Unterschied liegt, kannst du <a class="externalLink" href="https://de.wikipedia.org/wiki/Inkrement_und_Dekrement#Wertzuweisungszeitpunkt_in_%C3%BCbergeordneten_Ausdr%C3%BCcken" target="_blank">hier</a> auf Wikipedia nachlesen. Für jetzt kannst du aber einfach immer eine der beiden Varianten verwenden.</p>
`,
    taskDescription: '<p>Schreibe noch einmal ein Programm, das die Vielfachen einer gegebenen Zahl <code>n</code> berechnet, aber dieses Mal mit Benutzung einer <code>for</code>-Schleife.</p>',
    jDoodleId: '4rHs',
    resultDescription: `<p>Modifiziere anschließend deinen Code so, dass für alle zahlen von 1 bis <code>n</code> jeweils alle Vielfachen ausgegeben werden. Beispielsweise soll für <code>n = 3</code> und <code>max = 5</code> die Ausgabe so aussehen:</p>
<pre>Vielfache von 1:
1
2
3
4
5
Vielfache von 2:
2
4
Vielfache von 3:
3</pre>
<div class="info">
    <p>Tipp: Schonmal über eine <code>for</code>-Schleife in einer <code>for</code>-Schleife nachgedacht?</p>
</div>`,
    possibleMistakes: [
      'Variablen verwechselt: Schau nochmal genau hin, welche Variable für was zuständig ist',
      'Initialisierung oder Bedingung falsch: Es sollen alle Vielfachen der Zahlen 1 bis n ausgegeben werden, die jeweils mindestens die Start-Zahl und höchstens <code>max</code> sein können',
      'Schrittweite falsch',
      'Ausgabe an der falschen Stelle',
      'Falsche Variable ausgegeben'
    ],
    solutionCode: `public class Multiples {
    public static void main(String[] args) {
        int n = 3;
        int max = 5;

        for (int d = 1; d <= n; d++) {
            System.out.println("Vielfache von " + d + ":");
            for (int i = d; i <= max; i += d) {
                System.out.println(i);
            }
        }
    }
}`
  },
  {
    title: 'boolean-Keyword und</br>Logische Operatoren (AND, OR, NOT, XOR)',
    videoId: 'LrcKvIjFkMk',
    videoScript: `<p>Mit dem <code>int</code>-Keyword kennst du bereits einen der insgesamt 8 Basistypen in Java. (<code>String</code> ist kein Basistyp, mehr dazu später.) Ein weiterer Basistyp, oder auch <i>primitiver Datentyp</i> genannt, ist <code>boolean</code>. Eine <code>boolean</code>-Variable speichert einen Wahrheitswert, der entweder <code>true</code> oder <code>false</code> ist:</p>
<div class="codeBlock">boolean a = true; // a ist true, also wahr
boolean b = false; // b ist false, also falsch</div>
<p>Tatsächlich hast du bereits mit <code>boolean</code> gearbeitet, denn jede Bedingung einer <code>while</code>- oder <code>for</code>-Schleife ist ein Ausdruck, der zu einem <code>boolean</code>-Wert evaluiert. Du kannst also den Wahrheitswert jeder Bedingung folgendermaßen in einer Variable zwischenspeichern:</p>
<div class="codeBlock">int i = 20;

boolean condition0 = i > 20; // false
boolean condition1 = i == 7; // false
boolean condition2 = i * i > 100; // true</div>
<p>Für <code>boolean</code>s gibt es zusätzliche Logik-Operatoren:</p>
<ul>
    <li><code>!</code> ist der NOT-Operator. <code>!true</code> evaluiert zu <code>false</code>, und <code>!false</code> evaluiert zu <code>true</code>. Hiermit lässt sich also der Wahrheitswert genau umkehren.</li>
    <li><code>&&</code> ist der AND-Operator. <code>a && b</code> evaluiert genau dann zu <code>true</code>, falls sowohl <code>a</code> als auch <code>b</code> <code>true</code> sind.</li>
    <li><code>||</code> ist der OR-Operator. <code>a || b</code> evaluiert genau dann zu <code>true</code>, falls entweder <code>a</code> <code>true</code> ist, oder <code>b</code> <code>true</code> ist, oder beide <code>true</code> sind.</li>
    <li><code>^</code> ist der XOR-Operator (Exklusiv-Oder). <code>a ^ b</code> evaluiert genau dann zu <code>true</code>, falls entweder <code>a</code> <code>true</code> ist, oder <code>b</code> <code>true</code> ist, aber <strong>nicht</strong> beide.</li>
</ul>
<p>Beispiele:</p>
<div class="codeBlock">int a = 4;
int b = 8;

boolean b0 = (a == 4 && b == 8); // true
boolean b1 = (a == 4 && b < 8); // false

boolean b2 = (a * b > 30 || a < 0); // true
boolean b3 = (false || true); // true

boolean b4 = !(a == 4); // false
boolean b5 = (false == (b != 8)); // true
boolean b6 = (a == 4 || b == 4) && (a == 8 || b == 8); // true

boolean b7 = b0 || b1; // true
boolean b8 = !b7; // false
boolean b9 = !(!true); // true

boolean b10 = ((a == 3) ^ (b == 7)); // false
boolean b11 = ((a == 4) ^ (b == 7)); // true
boolean b12 = ((a == 3) ^ (b == 8)); // true
boolean b13 = ((a == 4) ^ (b == 8)); // false</div>
<p>(Hinweis für Klugscheißer: Die Klammern um Logik-Ausdrücke sind an manchen Stellen nicht notwendig. Sie sorgen allerdings für bessere Lesbarkeit und vermeiden Fehler im Code.)</p>`,
    taskDescription: `<p>In dieser Aufgabe sollst du unterschiedliche Konsolenausgaben durchführen, abhängig davon, wie die Variablen <code>a</code> und <code>b</code> gesetzt sind:</p>
<ul>
    <li>Falls <code>a</code> bzw. <code>b</code> wahr ist, soll auf der Konsole</br><code>A ist wahr</code> bzw. <code>B ist wahr</code> erscheinen</li>
    <li>Falls <code>a</code> bzw. <code>b</code> falsch ist, soll auf der Konsole</br><code>A ist falsch</code> bzw. <code>B ist falsch</code> erscheinen</li>
    <li>Falls <code>a</code> und <code>b</code> beide wahr sind, soll zusätzlich</br><code>A und B sind beide wahr</code> ausgegeben werden</li>
    <li>Falls <code>a</code> oder <code>b</code> oder beide wahr sind, soll zusätzlich</br><code>Entweder A oder B ist wahr, oder beide</code> ausgegeben werden</li>
    <li>Falls die Variablen den gleichen Wert besitzen, soll</br><code>A und B sind entweder beide wahr, oder beide falsch</code> ausgegeben werden</li>
    <li>Falls genau eine der beiden Variablen wahr ist, aber nicht beide, soll</br><code>Entweder A oder B ist wahr, aber nicht beide</code> ausgegeben werden</li>
</ul>
<div class="info">
    <p>Hinweis für guten Code-Style:</p>
    <p>Der Ausdruck <code>a == true</code> ist äquivalent zu <code>a</code>. Man kann sich hier also den Vergleich sparen. Analog dazu kann <code>a == false</code> zu <code>!a</code> abgekürzt werden.</p>
</div>`,
    jDoodleId: '4rU3',
    resultDescription: '<p>Falls du beim Thema Logik noch nicht voll durchsteigst ist das kein Problem. In vielen Fällen wirst du nur ein <code>&&</code> oder <code>||</code> verwenden, um zwei Bedingungen zu verknüpfen. Komplizierte Logik-Ausdrücke sind meistens auch schlechter Code-Style, weil man nicht auf den ersten Blick erkennen kann, was an der jeweiligen Stelle eigentlich überprüft werden soll.</p>',
    possibleMistakes: [
      'Klammern um Logik-Operation vergessen: Das kann schnell zu Problemen führen',
      'Du hast irgendeinen Sonderfall nicht beachtet: Überprüfe alle Ausgaben nochmal mit allen 4 möglichen Eingaben'
    ],
    solutionCode: `public class Logic {
    public static void main(String[] args) {
        boolean a = false;
        boolean b = false;

        if (a) {
            System.out.println("A ist wahr");
        } else { // Statt else hätte auch eine zweite if-Abfrage mit !a funktioniert
            System.out.println("A ist falsch");
        }

        if (b) {
            System.out.println("B ist wahr");
        } else {
            System.out.println("B ist falsch");
        }

        if (a && b) {
            System.out.println("A und B sind beide wahr");
        }

        if (a || b) {
            System.out.println("Entweder A oder B ist wahr, oder beide");
        }

        if (a == b) { // (a && b) || (!a && !b) wäre auch korrekt, ist aber offensichtlich umständlicher
            System.out.println("A und B sind entweder beide wahr, oder beide falsch");
        }

        if (a ^ b) { // (a && !b) || (!a && b) bzw. (a || b) && !(a && b) ist auch korrekt
            System.out.println("Entweder A oder B ist wahr, aber nicht beide");
        }
    }
}`
  },
  {
    title: 'Division mit Rest: Der Restwertoperator',
    videoId: '-O2hkYpiqpM',
    videoScript: `<p>In der Grundschule hast du die Divison mit Rest gelernt. Keine Panik, wir machen hier kein schriftliches Dividieren, versprochen. Dafür haben wir ja einen Computer ;)</p>
<p>Ich habe dich schon vorgewarnt, dass in Java Divisionen mit <code>int</code> immer abgerundet werden. Wenn du zum Beispiel <code>8 / 3</code> rechnest, erhältst du als Ergebnis 2, denn 8 ÷ 3 ist (nach Grundschulrechnung) 2 Rest 2. Java ignoriert den Rest, und spuckt das abgerundete Ergebnis aus. Was aber, wenn du dich genau für diesen Rest interessierst?</p>
<p>Hierfür gibt es den Restwertoperator (fälschlicherweise häufig auch <i>Modulo</i> genannt), der mit dem Prozentzeichen beschrieben wird: <code>%</code></p>
<div class="codeBlock">int a = 5 % 2; // 1, denn 5 ÷ 2 = 2 R 1
int b = 5 % 3; // 2, denn 5 ÷ 3 = 1 R 2
int c = 5 % 4; // 1, denn 5 ÷ 4 = 1 R 1
int d = 5 % 5; // 0, denn 5 ÷ 5 = 1 R 0
int e = 5 % 6; // 5, denn 5 ÷ 6 = 0 R 5</div>
<div class="attention">
    <p>Vorsicht bei Division durch 0! Sowohl der Divisionsoperator, als auch der Restwertoperator spucken den Fehler <code>java.lang.ArithmeticException: / by zero</code> aus, falls du versuchst durch <code>0</code> zu teilen</p>
</div>
<p>Der Restwertoperator <code>%</code> wird sehr häufig verwendet, um zu überprüfen, ob eine Zahl durch eine andere Zahl teilbar ist. Beispielsweise kann mit folgendem Code herausgefunden werden, ob ein <code>int</code> durch 3 teilbar ist:</p>
<div class="codeBlock">int a = 42;
int b = 69;
int c = 20;

boolean aDividesThree = a % 3 == 0; // true,  denn 42 ÷ 3 = 7  R 0, und 0 == 0
boolean bDividesThree = b % 3 == 0; // true,  denn 69 ÷ 3 = 23 R 0, und 0 == 0
boolean cDividesThree = c % 3 == 0; // false, denn 20 ÷ 3 = 6  R 2, und 2 != 0</div>`,
    taskDescription: '<p>Deine Aufgabe ist es nun, ein Programm zu schreiben, das alle Teiler einer gegebenen Zahl <code>n</code> ausgibt. Für <code>n = 42</code> sollen zum Beispiel die Zahlen 1, 2, 3, 6, 7, 14, 21 und 42 ausgegeben werden. Du kannst davon ausgehen, dass <code>n</code> immer mindestens 1 ist.</p>',
    jDoodleId: '4rVe',
    resultDescription: '',
    possibleMistakes: [
      'Schleife falsch: Verwende eine <code>for</code>-Schleife, die von <code>1</code> bis <code>n</code> zählt.',
      'Modulo falsch verwendet: Du musst überprüfen, ob <code>n % i == 0</code> ist, damit <code>i</code> ein Teiler von <code>n</code> ist'
    ],
    solutionCode: `public class Dividers {
    public static void main(String[] args) {
        int n = 42;

        for (int i = 1; i <= n; i++) { // Für alle Zahlen von 1 bis n
            if (n % i == 0) { // Falls n durch i teilbar ist
                System.out.println(i);
            }
        }
    }
}`
  },
  {
    title: 'Übung: Primzahlen Berechnen',
    videoId: '2I7AZNIQ0Iw',
    videoScript: `<p>Lass uns mal ein etwas größeres Beispiel besprechen: Ein Programm, das alle Primzahlen bis zu einer bestimmten Zahl berechnet.</p>
<div class="info">
    <p>Zur Erinnerung: Primzahlen sind alle positiven Ganzzahlen, die genau zwei unterschiedliche Teiler haben, nämlich 1 und sich selbst.</p>
    <p>Beispiele:</p>
    <ul>
        <li>7 ist eine Primzahl, weil sie nur durch die Zahlen 1 und 7 teilbar ist</li>
        <li>10 ist keine Primzahl, weil 10 durch 1, 2, 5, und 10 teilbar ist</li>
        <li>Die 1 selbst ist keine Primzahl, weil sie nur einen einzigen Teiler hat, nämlich 1</li>
    </ul>
</div>
<p>Um einen einfachen <i>Algorithmus</i>, also ein Rechenverfahren, zu entwickeln, der herausfindet, ob eine gegebene Zahl <code>n</code> eine Primzahl ist oder nicht, kann man folgendermaßen vorgehen:</p>
<ul>
    <li>Überprüfe, ob <code>n</code> kleiner ist als 2, falls ja ist <code>n</code> definitiv keine Primzahl</li>
    <li>Iteriere durch alle Zahlen von <code>2</code> bis <code>n - 1</code></li>
    <li>Falls mindestens eine dieser Zahlen ein Teiler von <code>n</code> ist, ist <code>n</code> keine Primzahl</li>
    <li>Ansonsten hat <code>n</code> keine Teiler zwischen <code>2</code> und <code>n - 1</code> und muss daher eine Primzahl sein</li>
</ul>`,
    taskDescription: `<p>a) Schreibe ein Programm, das überprüft, ob eine gegebene Zahl <code>n</code> eine Primzahl ist.</p>
<p>b) Modifiziere anschließend dein Programm so, dass es alle Primzahlen bis zu einer gewissen Zahl <code>max</code> (z.B. <code>max = 100</code>) ausgibt. (Hier reicht die Ausgabe der Zahl ohne weiteren Text.)</p>
<p>Die ersten Primzahlen lauten: 2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 71, 73, 79, 83, 97, ...</p>`,
    jDoodleId: '4syu',
    resultDescription: `<p>c) Gib alle Primzahlen von <code>1_000_000</code> bis <code>1_001_000</code> aus. Was fällt dir hinsichtlich der Ausführungsdauer auf? Wie könnte man das Problem beheben? Überlege dir hierzu, was genau in jeder Iteration passiert.</p>
<div class="info">
    <p>In Java lassen sich Zahlen übersichtlicher darstellen, indem man Tausender-Blöcke durch einen Unterstrich trennt:</p>
    <p><code>10000</code> ist das gleiche wie <code>10_000</code>.</p>
</div>`,
    possibleMistakes: [
      '<code>prime</code> wurde kein initialer Wert gegeben: <code>error: variable prime might not have been initialized</code>',
      'Fall für Zahlen kleiner 2 vergessen',
      'Grenzen der <code>for</code>-Schleife falsch: Es muss von <code>2</code> bis <code>n - 1</code> iteriert werden',
      '<code>prime</code> genau falsch herum gesetzt: <code>prime</code> muss nur dann <code>false</code> sein, falls <code>n < 2</code> oder es einen Teiler zwischen <code>2</code> und <code>n - 1</code> gibt'
    ],
    solutionCode: {
      'Lösungsvorschlag Teilaufgabe a)': `public class Primes {
    public static void main(String[] args) {
        int n = 97;

        // Wir nehmen erstmal an, dass n eine Primzahl ist
        boolean prime = true;

        if (n < 2) {
            // Keine Zahl kleiner 2 ist eine Primzahl
            prime = false;
        } else {
            // Iteriere von 2 bis n - 1
            for (int i = 2; i < n; i++) {
                // Überprüfe, ob i ein Teiler von n ist
                if (n % i == 0) {
                    /*
                     * n hat mindestens einen Teiler im Intervall [2, n-1]
                     * und ist damit keine Primzahl
                     */
                    prime = false;
                }
            }
        }

        if (prime) {
            System.out.println(n + " ist eine Primzahl");
        } else {
            System.out.println(n + " ist keine Primzahl");
        }
    }
}`,
      'Lösungsvorschlag Teilaufgabe b)': `public class Primes {
    public static void main(String[] args) {
        int max = 100;

        // Teste alle Zahlen von 2 bis max
        for (int n = 2; n <= max; n++) {
            boolean prime = true;

            /*
             * Überprüfung, ob n < 2 ist nicht notwendig,
             * weil wir erst bei n = 2 anfangen
             */

            for (int i = 2; i < n; i++) {
                if (n % i == 0) {
                    prime = false;
                }
            }

            if (prime) {
                System.out.println(n);
            }
        }
    }
}`,
      'Lösungsvorschlag Teilaufgabe c)': `/*
 * Die Ausführungsdauer nimmt mit der Größe der Eingabe-Zahlen stark zu,
 * denn für jede Zahl n muss durch alle Zahlen von 2 bis n-1 iteriert werden.
 *
 * Möglichkeiten, die Laufzeit zu verbessern:
 *
 * 1) Sobald herausgefunden wurde, dass eine Zahl einen Teiler hat,
 *    müssen die restlichen Zahlen bis n-1 nicht mehr überprüft werden.
 *    Die Schleife kann also abgebrochen werden.
 *    Wie das geht, zeige ich dir auf der nächsten Seite.
 *
 * 2) Anstatt bis n-1 zu laufen, kann bereits bei der Quadratwurzel
 *    von n gestoppt werden*. Unsere Schleifen-Bedingung kann also
 *    verbessert werden zu: i * i <= n
 *
 *    *Falls dich der Grund interessiert, ist hier der Beweis:
 *    Jede (natürliche) Zahl, die keine Primzahl ist, ist zerlegbar in
 *    zwei Faktoren a und b, sodass a * b = n.
 *    Falls wir bereits überprüft haben,
 *    dass alle Zahlen bis zu √n keine Teiler von n sind,
 *    müssten beide dieser Faktoren größer sein als √n.
 *    Dann wäre aber n = a * b größer als √n * √n und damit n > n.
 *    Das ist ein Widerspruch, und somit muss n eine Primzahl sein.
 *
 *    Im folgenden siehst du eine verbesserte Implementierung
 *    mit Umsetzung dieser Idee. Überzeuge dich gerne selbst davon,
 *    dass dies um ein Vielfaches schneller ist, als der naive Ansatz:
 */
public class Primes {
    public static void main(String[] args) {
        int max = 1_001_000;

        for (int n = 1_000_000; n <= max; n++) {
            boolean prime = true;

            // Iteriere von 2 bis √n
            for (int i = 2; i * i <= n; i++) {
                if (n % i == 0) {
                    prime = false;
                }
            }

            if (prime) {
                System.out.println(n);
            }
        }
    }
}`
    }
  },
  {
    title: 'Schleife Abbrechen oder Fortsetzen:</br>break und continue',
    videoId: 'PKKXSoEBynw',
    videoScript: `<div class="info">
    <p>Mit dem <code>break</code>-Keyword kann eine Schleife abgebrochen werden, selbst wenn die Schleifen-Bedingung noch erfüllt ist:</p>
<div class="codeBlock">for (int i = 0; i < 100; i++) {
    System.out.println(i);
    if (i == 2) {
        break;
    }
}</div>
</div>
<p>Der obige Programmcode gibt nur die Zahlen <code>0</code>, <code>1</code>, und <code>2</code> aus, obwohl die Bedingung (<code>i < 100</code>) noch erfüllt wäre.</p>
<p><code>break</code> bezieht sich dabei immer auf die nächst äußere Schleife. Fall also mehrere Schleifen verschachtelt sind, wird die Ausführung der äußeren nicht beeinflusst:</p>
<div class="codeBlock">for (int i = 0; i < 3; i++) {
    for (int j = 0; j < 1000; j++) {
        System.out.println(j);
        if (j == 2) {
            break;
        }
    }
}</div>
<p>Der obige Code gibt <code>0</code> <code>1</code> <code>2</code> <code>0</code> <code>1</code> <code>2</code> <code>0</code> <code>1</code> <code>2</code> aus.</p>
<div class="info">
    <p>Neben <code>break</code> gibt es ein weiteres wichtiges Keyword für Schleifen: <code>continue</code>. Hiermit lässt sich der restliche Teil des Schleifen-Körpers überspringen, sodass die Schleife mit der nächsten Iteration fortsetzt:</p>
    <div class="codeBlock">for (int i = 1; i <= 5; i++) {
        System.out.println(i);
        if (i == 2 || i == 3) {
            continue;
        }
        System.out.println("Nochmal " + i);
    }</div>
</div>
<p>Dieses Beispiel liefert folgende Ausgabe:</p>
<pre>1
Nochmal 1
2
3
4
Nochmal 4
5
Nochmal 5</pre>`,
    taskDescription: '<p>Im folgenden siehst du noch einmal eine etwas abgewandelte Version des Lösungsvorschlags der vorherigen Primzahlen-Aufgabe. Deine Aufgabe ist es, einen Schleifendurchlauf immer dann abzubrechen, falls bereits festgestellt wurde, dass es sich um keine Primzahl handeln kann. Vergleiche dabei die CPU-Zeiten vorher und nachher.</p>',
    jDoodleId: '4sCx',
    possibleMistakes: [
      '<code>break</code> falsch gesetzt'
    ],
    solutionCode: `public class Primes {
    public static void main(String[] args) {
        int min = 2_000_000_000;
        int max = 2_000_005_000;

        if (min < 2) {
            min = 2;
        }

        for (int n = min; n <= max; n++) {
            boolean prime = true;

            for (int i = 2; i * i <= n; i++) {
                if (n % i == 0) {
                    prime = false;
                    /*
                     * Weil i ein Teiler von n ist,
                     * ist n ganz sicher keine Primzahl.
                     * Also müssen die restlichen Zahlen
                     * nicht mehr überprüft werden.
                     */
                    break;
                }
            }

            if (prime) {
                System.out.println(n);
            }
        }
    }
}`
  },
  {
    title: 'Fließkommazahlen: Das double-Keyword',
    videoId: 'ZYCTJbbkr4w',
    videoScript: `<p>Du kennst bereits die Basistypen <code>int</code> und <code>boolean</code>. In <code>boolean</code>-Variablen können lediglich Wahrheitswerte gespeichert werden, und in <code>int</code>-Variablen Ganzzahlen. Um mit Fließkommazahlen hantieren zu können, gibt es das <code>double</code>-Keyword:</p>
<div class="codeBlock">double x = 1.4142;</div>
<p>Hierbei werden alle Stellen nach dem Komma durch einen Punkt getrennt.</p>
<p><code>double</code>s können ähnlich wie <code>int</code>-Werte miteinander verrechnet werden, mit Ausnahme der Division, bei der hier nicht mehr abgerundet wird:</p>
<div class="codeBlock">double a = 12.0;
double b = 3.5;

double d0 = a + b; // 15.5
double d1 = a - b; //  8.5
double d2 = a * b; // 42.0
double d3 = a / b; //  3.4285714285714284
double d4 = a % b; //  1.5

boolean b0 = a < b;  // false
boolean b1 = a <= b; // false
boolean b2 = a >= b; // true
boolean b3 = a > b;  // true</div>
<div class="attention">
    <p>Achtung! Vergleiche niemals, niemals, niemals <code>double</code>-Werte mit <code>==</code> bzw. <code>!=</code>. Den Grund hierfür liefert folgendes Beispiel:</p>
    <p>Die Berechnung <code>1.6 + 1.8</code> sollte eigentlich <code>3.4</code> ergeben. Allerdings ist das tatsächliche Ergebnis <code>3.4000000000000004</code>. Ein Vergleich mit <code>==</code> würde hier <code>false</code> liefern!</p>
    <p>Das liegt an der Art und Weise, wie <a class="externalLink" href="https://de.wikipedia.org/wiki/IEEE_754" target="_blank">Fließkommazahlen in Binärdarstellung</a> gespeichert werden. Für dich ist die genaue Funktionsweise irrelevant, aber merke dir stets dass Fließkommazahlen fehlerbehaftet sind!</p>
    <p>Es ist besser, <code>double</code>s mit Fehlertoleranz zu vergleichen:</p>
    <div class="codeBlock">double res = 1.6 + 1.8;

if (res > 3.399 && res < 3.401) {
    // ...
}</div>
</div>
<p>Im Gegensatz zu <code>int</code> liefert eine Division durch <code>0.0</code> bei <code>double</code>s keinen Fehler, sondern abhängig vom Dividenden sonderbare Ergebnisse:</p>
<ul>
    <li><code>1.0 / 0.0</code> ergibt <code>Infinity</code>, weil der Dividend <code>1.0</code> positiv ist</li>
    <li><code>-1.0 / 0.0</code> ergibt <code>-Infinity</code>, weil der Dividend <code>-1.0</code> negativ ist</li>
    <li><code>0.0 / 0.0</code> ergibt <code>NaN</code> (<i>Not a Number</i>), weil der Dividend <code>0.0</code> ist</li>
</ul>
<p><code>Infinity</code>, <code>-Infinity</code>, und <code>NaN</code> sind zwar mathematisch gesehen keine Zahlen, wurden aber trotzdem als <code>double</code>-Konstanten definiert. Auch bei anderen Operationen als <code>/</code> kann es u.A. bei Falscheingaben zu solchen Ergebnissen kommen.</p>
<h4>Übersicht Bisherige Basistypen</h4>
<table class="infoTable">
    <tr>
        <th>Basistyp</th>
        <th>Art</th>
        <th>Beispiele</th>
    </tr>
    <tr>
        <td><code>boolean</code></td>
        <td>Wahrheitswert</td>
        <td><code>false</code>, <code>true</code></td>
    </tr>
    <tr class="catBlue">
        <td><code>int</code></td>
        <td>Ganzzahl</td>
        <td><code>-10</code>, <code>0</code>, <code>7</code>, <code>42</code>, <code>9999</code></td>
    </tr>
    <tr class="catGreen">
        <td><code>double</code></td>
        <td>Fließkommazahl</td>
        <td><code>-1.125</code>, <code>0.0</code>, <code>3.14159</code>, <code>Double.NaN</code>, <code>Double.NEGATIVE_INFINITY</code></td>
    </tr>
</table>
<div class="info">
    <p>Weil bestimmte mathematische Funktionen und Konstanten sehr häufig benötigt werden, gibt es diese bereits in der Standard-Java-Bibliothek:</p>
    <div class="codeBlock">double pi = Math.PI;   // 3.141592653589793
double euler = Math.E; // 2.718281828459045

double a = Math.sqrt(9.0);     // Die Quadratwurzel aus 9.0 ist 3.0
double b = Math.pow(2.0, 3.0); // 2.0 hoch 3.0 ist 8.0
double c = Math.sin(pi / 2.0); // Sinus von pi / 2.0 ist 1.0
double d = Math.abs(-10.0);    // Der absolute Wert (Betrag) von -10.0 ist 10.0
double e = Math.min(1.5, 1.7); // Der kleinere der beien Werte ist 1.5
double f = Math.max(1.5, 1.7); // Der größere der beiden Werte ist 1.7

// Und noch viele weitere...</div>`,
    taskDescription: `<p>Du kennst doch sicher die <a class="externalLink" href="https://de.m.wikipedia.org/wiki/Quadratische_Gleichung#L%C3%B6sungsformel_f%C3%BCr_die_allgemeine_quadratische_Gleichung_(a-b-c-Formel)" target="_blank">Mitternachtsformel</a> aus dem Mathe-Unterricht:</p>
<div class="tutorialImgCenter">solution_formula.svg</div>
<p>Falls das jetzt komplettes Neuland für dich ist: Kein Problem! Es ist für diese Aufgabe nicht wichtig zu verstehen, was passiert. Du sollst lediglich die obige Formel in Programmcode umwandeln.</p>
<p>Das ± bedeutet, dass es zwei Ergebnisse gibt, eines für <code>-b + ...</code>, und eines für <code>-b - ...</code>. Gib beide Lösungen wie folgt aus: <code>Die beiden Nullstellen der Parabel lauten FIRST_SOLUTION und SECOND_SOLUTION.</code></p>`,
    jDoodleId: '4sPR',
    resultDescription: `<p>Das Ergebnis für das gegebene Beispiel sollten die Nullstellen <code>1.5</code> und <code>-2.0</code> sein.</p>
<p>Was passiert für die Eingaben <code>a = 1.0</code>, <code>b = 0.5</code>, <code>c = 3.0</code>? Modifiziere deinen Code so, dass stattdessen eine Fehlermeldung ausgegeben wird.</p>`,
    possibleMistakes: [
      'Klammern vergessen: Punkt vor Strich und so',
      'Vorzeichenfehler',
      'b² falsch implementiert: Verwende entweder <code>b * b</code> oder <code>Math.pow(b, 2.0)</code>'
    ],
    solutionCode: `public class QuadraticFormula {
    public static void main(String[] args) {
        // 1x² + 0.5x - 3
        double a = 1.0;
        double b = 0.5;
        double c = -3.0;

        // Zuerst alles unter der Wurzel berechnen
        double discriminant = Math.pow(b, 2.0) - 4.0 * a * c;

        if (discriminant < 0.0) {
            /*
             * Falls der Teil unter der Wuzel negativ ist,
             * gibt es keine reellen Lösungen.
             */
            System.out.println("Es gibt keine (reellen) Lösungen");
        } else {
            // Ergebnis der Wurzel ausrechnen
            double root = Math.sqrt(discriminant);

            // Beide Ergebnisse ausrechnen
            double firstSolution = (-b + root) / (2.0 * a);
            double secondSolution = (-b - root) / (2.0 * a);

            System.out.println("Die beiden Nullstellen der Parabel lauten " + firstSolution + " und " + secondSolution + ".");
        }
    }
}`
  },
  {
    title: 'Arrays',
    videoId: 'SMN2HMsyXOM',
    videoScript: `<p>Bisher haben wir in Variablen immer nur einzelne Werte gespeichert. Mit Hilfe von <i>Arrays</i> lassen sich eine vorher festgelegte Anzahl an Werten in nur einer einzigen Referenzvariable speichern.</p>
<div class="info">
    <p><i>Arrays</i> sind Felder, in denen man (nahezu) beliebig viele Werte speichern kann.</p>
    <p>Arrays lassen sich folgendermaßen erstellen:</p>
    <div class="codeBlock">int[] array; // Deklaration der Referenzvariable
array = new int[100]; // Erstellen und Zuweisen des tatsächlichen Array Objekts</div>
    <p>Oder kurz:</p>
    <div class="codeBlock">int[] array = new int[100];</div>
</div>
<p>Hierbei kann <code>int</code> natürlich auch ein anderer Typ sein.</p>
<ul>
    <li>Die eckigen Klammern <code>[]</code> stehen dafür, dass es sich nicht nur um ein <code>int</code> handelt, sondern um ein ganzes Array.</li>
    <li><code>array</code> ist wie bei anderen Variablen einfach nur ein frei wählbarer Name</li>
    <li>Das <code>new</code>-Keyword sorgt für die tatsächliche Erzeugung des Array Objekts, bei der der Speicherplatz für die Elemente reserviert (<i>alloziert</i>) wird. Mehr dazu später bei objektorientierter Programmierung.</i></li>
    <li>Die Zahl zwischen den eckigen Klammern auf der rechten Seite ist die Anzahl der Elemente im Array. In diesem Fall würde also Platz für 100 Elemente erstellt werden.</li>
</ul>
<div class="info">
    <p>Bei Basistypen wird das Array mit Standardwerten befüllt.</p>
    <table class="infoTable">
    <tr>
        <th>Basistyp</th>
        <th>Standardwert</th>
        <th>Beispiel im Array</th>
    </tr>
    <tr>
        <td><code>boolean</code></td>
        <td><code>false</code></td>
        <td><code>new boolean[12]</code>: 12 Einträge mit Inhalt <code>false</code></td>
    </tr>
    <tr>
        <td><code>int</code></td>
        <td><code>0</code></td>
        <td><code>new int[99]</code>: 99 Einträge mit Inhalt <code>0</code></td>
    </tr>
    <tr>
        <td><code>double</code></td>
        <td><code>0.0</code></td>
        <td><code>new double[7776]</code>: 7776 Einträge mit Inhalt <code>0.0</code></td>
    </tr>
</table>
</div>
<p>Eine Möglichkeit, Arrays direkt mit Initialwerten zu erstellen, ist folgende Schreibweise mit geschweiften Klammern:</p>
<div class="codeBlock">int[] squares = {1, 4, 9, 16, 25};</div>
<p>Die Größe eines Arrays lässt sich über <code>array.length</code> ausgeben:</p>
<div class="codeBlock">int l = squares.length; // 5</div>
<p>Um Werte im Array an einer bestimmten Position (<i>Index</i>) auszulesen oder zu verändern, werden ebenfalls eckige Klammern verwendet:</p>
<div class="codeBlock">int[] numbers = new int[10]; // 10 Werte, die alle 0 sind

int n = numbers[3]; // Wert an Index 3 auslesen
System.out.println(n); // 0

numbers[3] = 7; // Wert an Index 3 auf 7 setzen
System.out.println(numbers[3]); // 7</div>
<div class="attention">
    <p>Achtung! In Java (und so ziemlich jeder anderen seriösen Programmiersprache auch) beginnt man das Zählen bei 0 und nicht bei 1. Das bedeutet, dass das erste Element im Array an Index 0 steht:</p>
    <div class="codeBlock">double[] values = {0.5, -0.2, 1.0, 0.3};

double firstValue = values[0];  //  0.5
double secondValue = values[1]; // -0.2
double thirdValue = values[2];  //  1.0
double fourthValue = values[3]; //  0.3</div>
    <p>Das bedeutet auch, dass sich das letzte Element eines Arrays immer über <code>array[array.length - 1]</code> ausgeben lässt, nicht über <code>array[array.length]</code>! Falsche Zugriffe auf ein Array werfen eine <code>java.lang.ArrayIndexOutOfBoundsException</code>.</p>
</div>
<div class="info">
    <p>Sobald ein Array erstellt ist, lässt sich dessen Größe nicht nachträglich ändern. Falls man genau das aber möchte, so muss man ein neues Array mit entsprechender Größe erstellen, und alle Werte von dem alten Array kopieren.</p>
</div>`,
    taskDescription: '<p>Erstelle ein <code>int</code>-Array und befülle es mit den Zahlen 1 bis 2000. Gib anschließend die ersten 5 und die letzten 5 Werte auf der Konsole aus.</p>',
    jDoodleId: '4sRa',
    resultDescription: '',
    possibleMistakes: [
      'Array-Größe falsch gesetzt: Es gibt genau 2000 Elemente',
      '<code>for</code>-Schleife hat falsche Grenzen: Du musst von Index 0 bis index 1999 iterieren',
      'Werte falsch gesetzt: Die kleinste Zahl im Array soll 1 sein, nicht 0',
      'Beim Auslesen falsche Indizes: Du sollst alle Zahlen von Index 0 bis Index 4, und alle Zahlen von Index 1995 bis Index 1999 ausgeben'
    ],
    solutionCode: `public class ArrayExample {
    public static void main(String[] args) {
        // Von 1 bis 2000 gibt es genau 2000 Zahlen
        int[] numbers = new int[2000];

        // Iteriere durch alle Indizes von 0 bis 1999
        for (int i = 0; i < numbers.length; i++) {
            /*
             * Der Inhalt muss immer 1 größer sein als der Index,
             * weil die kleinste Zahl 1 sein soll und nicht 0
             */
            numbers[i] = i + 1;
        }

        // Iteriere durch die ersten 5 Indizes (0 bis 4)
        for (int i = 0; i < 5; i++) {
            System.out.println(numbers[i]);
        }

        System.out.println("...");

        // Iteriere von Index 1995 bis Index 1999
        for (int i = numbers.length - 5; i < numbers.length; i++) {
            System.out.println(numbers[i]);
        }
    }
}`
  },
  {
    title: 'Was zur Hölle ist Objektorientierte Programmierung und Wofür Brauche ich das?',
    videoId: 'IOcKgoTqdgg',
    videoScript: `<p>Kommen wir zum großen Thema: Objektorientierte Programmierung.</p>
<p>Java ist eine (sehr) objektorientierte Sprache. Wie du bereits gesehen hast, ist es nicht einmal möglich, ein einfaches Programm zu schreiben, ohne das Keyword <code>class</code> zu verwenden. Um zu verstehen, was Klassen und Objekte sind, möchte ich dir zuerst zeigen, was passieren kann, wenn du nicht objektorientiert programmierst. Im Gegensatz zu anderen Tutorials werde ich dir jetzt aber nicht realitätsferne Beispiele mit Autos, oder lustigen Tieren nennen, sondern eines mit wirklicher Anwendung.</p>
<p>Stelle dir vor, du möchtest ein Programm schreiben, in dem du Rechtecke benötigst. Diese könnten zum Beispiel Position und Größe von Figuren in einem Spiel darstellen. Wenn du also die Eigenschaften (<i>Attribute</i>) eines solchen Rechtecks speichern möchtest, könntest du folgendermaßen Variablen dafür erstellen:</p>
<div class="codeBlock">// Rechteck
double posX = 5.0; // Die x-Position des Rechtecks
double posY = 2.0; // Die y-Position des Rechtecks
double width = 4.0; // Die Breite des Rechtecks
double height = 3.0; // Die Höhe des Rechtecks</div>
<p>Je nachdem, was dein Programm machen soll, könntest du diese Werte dann verwenden um z.B. den Flächeninhalt zu berechnen:</p>
<div class="codeBlock">double area = width * height; // 12.0</div>
<p>Was aber, wenn du gerne mehrere solcher Rechtecke speichern würdest, sagen wir drei Stück?</p>
<div class="codeBlock">// Erstes Rechteck
double firstRectanglePosX = 5.0;
double firstRectanglePosY = 2.0;
double firstRectangleWidth = 4.0;
double firstRectangleHeight = 3.0;

// Zweites Rechteck
double secondRectanglePosX = 1.0;
double secondRectanglePosY = 0.5;
double secondRectangleWidth = 2.0;
double secondRectangleHeight = 3.5;

// Drittes Rechteck
double thirdRectanglePosX = -4.0;
double thirdRectanglePosY = 0.0;
double thirdRectangleWidth = 8.0;
double thirdRectangleHeight = 1.0;

// Berechnungen
double areaFirstRectangle = firstRectangleWidth * firstRectangleHeight;
double areaSecondRectangle = secondRectangleWidth * secondRectangleHeight;
double areaThirdRectangle = thirdRectangleWidth * thirdRectangleHeight;
...</div>
<p>Du siehst, dass das ganze sehr schnell sehr unübersichtlich wird. Und das sind lediglich drei Rechteck <i>Objekte</i>, die wir speichern wollen. Was wenn es mehrere tausend sind? Oder beliebig viele, also z.B. abhängig von einer Variable <code>int numberOfRectangles;</code>?</p>
<p>Wir könnten jetzt anfangen, die Attribute der einzelnen Rechtecke in Arrays zusammen zu fassen. Es gibt aber eine viel bessere Variante, einzelne Basistypen zu bündeln: Klassen</p>
<div class="info">
    <p>Eine Klasse ist nichts anderes als eine Vorlage, wie mehrere Variablen (und Methoden*) zu einem einzelnen Objekt zusammengefasst werden können.</p>
    <p>Eine Klasse wird nur ein einziges mal geschrieben, egal wie viele Objekte dieser Klasse man dann tatsächlich erstellen möchte. Die Erstellung eines tatsächlichen Objekts nennt man auch <i>Instanziierung</i>.</p>
</div>
<p>In unserem Beispiel ist also Rechteck bzw. <code>Rectangle</code> die Klasse, <code>firstRectangle</code> und <code>secondRectangle</code> könnten dann zwei Objekte (<i>Instanzen</i>) der Klasse <code>Rectangle</code> sein, und die vier Eigenschaften <code>posX</code>, <code>posY</code>, <code>width</code> und <code>height</code> die Attribute der Klasse <code>Rectangle</code></p>
<div class="info">
    <p>Du kennst bereits eine Klasse, die in Java standardmäßig immer dabei ist: <code>String</code>. Im Gegensatz dazu sind <code>int</code>, <code>boolean</code> und <code>double</code> <strong>keine</strong> Klassen, sondern primitive Datentypen (Basistypen). Basistypen sind deutlich simpler, können nicht vom Programmierer neu erfunden werden, und unterscheiden sich in der Art und Weise wie sie im Arbeitsspeicher abgelegt werden.</p>
    <p>Übrigens: Arrays sind auch Objekte. Allerdings nehmen diese in Java ebenfalls eine Sonderrolle ein, beispielsweise in der Art und Weise wie diese instanziiert werden.</p>
</div>
<p>Und so schaut dann eine Klasse im Code aus:</p>
<div class="codeBlock">class Rectangle {
    double posX;
    double posY;
    double width;
    double height;
}</div>
<p>Die Struktur einer Klasse lässt sich in einem <a class="externalLink" href="https://de.wikipedia.org/wiki/Klassendiagramm" target="_blank">UML Klassendiagramm</a> darstellen:</p>
<div class="tutorialImgCenter">java_rectangle_uml_class_diagram.svg</div>
<p>Um ein Objekt dieser Klasse zu erstellen, verwendet man das <code>new</code>-Keyword, gefolgt von dem Klassennamen und runden Klammern:</p>
<div class="codeBlock">// Referenzvariable für ein Rechteck deklarieren
Rectangle firstRectangle;

// Neues Rechteck instanziieren und der Variable zuweisen
firstRectangle = new Rectangle();

// Oder kurz:
Rectangle secondRectangle = new Rectangle();
Rectangle thirdRectangle = new Rectangle();</div>
<p>Standardmäßig sind jedoch alle Attribute dieser drei Rechtecke <code>0.0</code>. Um auf diese zuzugreifen kann man folgende Punkt-Schreibweise verwenden:</p>
<div class="codeBlock">firstRectangle.width = 4.0;
firstRectangle.height = 3.0;

System.out.println(firstRectangle.width); // 4.0</div>
<p>Die <code>main</code>-Methode, die du bereits in jedem Beispiel verwendet hast, muss in nur einer Klasse vorkommen. Wir können beispielsweise ein Programm folgendermaßen zusammensetzen:</p>
<div class="codeBlock">public class Rectangle {
    double posX;
    double posY;
    double width;
    double height;

    public static void main(String[] args) {
        Rectangle rect = new Rectangle();
        rect.posX = 1.0;
        rect.posY = -1.0;
        rect.width = 2.0;
        rect.height = 3.0;

        double area = rect.width * rect.height;

        System.out.println("Das Rechteck befindet sich an der Koordinate (" + rect.posX + " | " + rect.posY + ")");
        System.out.println("Der Flächeninhalt des Rechtecks ist " + area);
    }
}</div>
<p>Hierbei ist das <code>public</code>-Keyword ein sogenannter <i>Access Modifier</i> und bedeutet, dass die Klasse auch von anderen Java-Dateien aus erreicht werden kann. Wenn man <code>public</code> weglässt, kann der Compiler die <code>main</code>-Methode nicht finden. Mehr zu Access Modifiers später.</p>
<div class="info">
    <p>Regeln für guten Code Style (Clean Code):</p>
    <ul>
        <li>Klassennamen immer groß</br>
            <table>
                <tr>
                    <td>❌ <code>class rectangle { ... }</code></td>
                    <td>✅ <code>class Rectangle { ... }</code></td>
                </tr>
            </table>
        </li>
        <li>Variablennamen immer klein</br>
            <table>
                <tr>
                    <td>❌ <code>int Number;</code></td>
                    <td>✅ <code>int number;</code></td>
                </tr>
            </table>
        </li>
        <li>Generell (fast) alle Benennungen immer in CamelCase</br>
            <table>
                <tr>
                    <td>❌ <code>class RECTANGLE { ... }</code></td>
                    <td>✅ <code>class Rectangle { ... }</code></td>
                </tr>
                <tr>
                    <td>❌ <code>class Superfancyrectangle { ... }</code></td>
                    <td>✅ <code>class SuperFancyRectangle { ... }</code></td>
                </tr>
                <tr>
                    <td>❌ <code>Rectangle myrectangle;</code></td>
                    <td>✅ <code>Rectangle myRectangle;</code></td>
                </tr>
            </table>
        </li>
        <li>Unterstriche sind für C-Nerds</br>
            <table>
                <tr>
                    <td>❌ <code>String first_name;</code></td>
                    <td>✅ <code>String firstName;</code></td>
                </tr>
            </table>
        </li>
    </ul>
</div>
<h4>Wozu brauche ich das jetzt also?</h4>
<p>Objektorientierte Programmierung ist kein Muss! Beispielsweise die Sprache C kommt (mehr oder weniger) ohne Klassen und Objekte aus. Theoretisch lässt sich jedes Programm auch direkt in Maschinencode schreiben. Allerdings ist es für uns Menschen viel einfacher, Dinge zu abstrahieren. Unter einem einzelnen Rechteck-Objekt verstehen wir mehr, als unter vier Fließkommazahlen, aus denen sich dieses zusammen baut. Objektorientierte Programmierung ist also für uns Programmierer eine Hilfe, damit wir komplexe Zusammenhänge auf möglichst einfache Weise darstellen und verstehen können.</p>
<p>(*Zu Methoden kommen wir gleich noch)</p>`,
    taskDescription: `<p>In einem (hypothetischen) sozialen Netzwerk werden Accounts von Menschen als Objekte im System gespeichert. Jedes dieser Objekte stellt genau einen Account (und damit einen Menschen) dar.</p>
<p>Erstelle eine Klasse <code>User</code>, die die folgenden Attribute enthält:</p>
<div class="tutorialImgCenter">java_user_uml_class_diagram.svg</div>
<ul>
    <li><code>id</code> (Ein <code>int</code>, das irgendeine Art von Identifikationsnummer repräsentiert)</li>
    <li><code>firstName</code> (Ein <code>String</code>, der einen Vornamen darstellt)</li>
    <li><code>lastName</code> (Ein <code>String</code>, der einen Nachnamen darstellt)</li>
</ul>
<p>Erstelle anschließend in der <code>main</code>-Methode die folgenden drei <code>User</code>-Objekte:</p>
<ul>
    <li>Parry Lage: Hat die <code>id</code> <code>14_159_265</code></li>
    <li>Leon Tusk: Hat die <code>id</code> <code>69_420</code></li>
    <li>Beff Jezos: Hat die <code>id</code> <code>2</code></li>
</ul>
<p>Du kannst das Programm ausführen um zu sehen ob ein Syntax- bzw. Compilerfehler vorliegt, es wird aber keine Programmausgabe geben.</p>`,
    jDoodleId: '4ydg',
    resultDescription: `<p>Da jeder Nutzer mit anderen Nutzern befreundet sein kann, werden zusätzlich alle Freunde eines Nutzers als Array in jedem <code>User</code>-Objekt gespeichert.</p>
<p>Ergänze die Klasse <code>User</code> also nun mit einem weiteren Attribut <code>User[] friends</code> und setze die folgenden Verbindungen zwischen den Objekten:</p>
<ul>
    <li>Parry Lage ist mit Leon Tusk befreundet</li>
    <li>Leon Tusk ist mit Parry Lage befreundet</li>
    <li>Beff Jezos ist mit keinem der beiden befreundet</li>
</ul>
<div class="tutorialImgCenter">java_user_uml_class_diagram_2.svg</div>
<p><i>(Der Pfeil im Diagramm bedeutet, dass jeder <code>User</code> ein zusätzliches Attribut <code>friends</code> hat, das selbst 0 bis beliebig viele <code>User</code> Objekte enthält.)</i></p>
<p>Füge anschließend eine Programm-Logik hinzu, die auf der Konsole ausgibt, ob Leon Tusk ein Freund von Beff Jezos ist. Verwende hierbei nicht dein eigenes Wissen, sondern hole die Informationen ausschließlich aus den <code>User</code>-Objekten.</p>`,
    possibleMistakes: [
      '<code>No "public class" found</code>: Du hast das <code>public</code> vor <code>class</code> vergessen, oder gar keine Klasse implementiert',
      '<code>cannot find symbol</code>: Hast du den korrekten Typ <code>User</code> bei jeder Variablen-Deklaration stehen? Hast du alle <code>String</code>s in Gänsefüßchen?',
      '<code>error: reached end of file</code>: Geschweifte Klammer am Ende vergessen',
      '<code>java.lang.NullPointerException</code>: Hast du alle Arrays zuerst über <code>new User[...]</code> erstellt, bevor du darauf zugreifst?'
    ],
    solutionCode: {
      'Lösungsvorschlag Teilaufgabe a)': `public class User {
    int id;
    String firstName;
    String lastName;

    public static void main(String[] args) {
        // User "Parry Lage" erstellen
        User parryLage = new User();
        parryLage.id = 14_159_265;
        parryLage.firstName = "Parry";
        parryLage.lastName = "Lage";

        // User "Leon Tusk" erstellen
        User leonTusk = new User();
        leonTusk.id = 69_420;
        leonTusk.firstName = "Leon";
        leonTusk.lastName = "Tusk";

        // User "Beff Jezos" erstellen
        User beffJezos = new User();
        beffJezos.id = 2;
        beffJezos.firstName = "Beff";
        beffJezos.lastName = "Jezos";
    }
}`,
      'Lösungsvorschlag Teilaufgabe b)': `public class User {
    int id;
    String firstName;
    String lastName;
    User[] friends; // Alle User, mit denen dieser User befreundet ist

    public static void main(String[] args) {
        // User "Parry Lage" erstellen
        User parryLage = new User();
        parryLage.id = 14_159_265;
        parryLage.firstName = "Parry";
        parryLage.lastName = "Lage";
        parryLage.friends = new User[1]; /* Leeres User Array mit Platz
                                            für einen User erstellen */

        // User "Leon Tusk" erstellen
        User leonTusk = new User();
        leonTusk.id = 69_420;
        leonTusk.firstName = "Leon";
        leonTusk.lastName = "Tusk";
        leonTusk.friends = new User[1]; /* Leeres User Array mit Platz
                                           für einen User erstellen */
        leonTusk.friends[0] = parryLage; /* Der erste (und einzige) Freund
                                            von Leon Tusk ist Parry Lage */

        // Nachträglich Leon Tusk als Freund von Parry Lage setzen
        parryLage.friends[0] = leonTusk;

        // User "Beff Jezos" erstellen
        User beffJezos = new User();
        beffJezos.id = 2;
        beffJezos.firstName = "Beff";
        beffJezos.lastName = "Jezos";
        beffJezos.friends = new User[0];

        // Überprüfen, ob Leon Tusk in der Freundesliste von Parry Lage ist
        boolean leonIsFriendOfParry = false;
        for (int i = 0; i < parryLage.friends.length; i++) {
            if (parryLage.friends[i] == leonTusk) {
                // Leon ist in der Freundesliste von Parry
                leonIsFriendOfParry = true;
                break; // Wir können aufhören zu suchen
            }
        }
        if (leonIsFriendOfParry) {
            System.out.println("Leon ist ein Freund von Parry");
        } else {
            System.out.println("Leon ist kein Freund von Parry");
        }
    }
}`
    }
  },
  {
    title: 'Konstruktoren',
    videoId: 'GmkEB7ebEwU',
    videoScript: `<p>Wie dir vielleicht schon aufgefallen ist, werden beim Erstellen von Objekten sehr häufig direkt im Anschluss Attribute dieses Objekts gesetzt. Um diesen Teil etwas zu verschönern gibt es einen besonderen Code-Block: Den <i>Konstruktor</i></p>
<p>Ein Konstruktor nimmt Werte (<i>Parameter</i> / <i>Argumente</i>) entgegen, die direkt für die Instanziierung des Objekts verwendet werden können. In unserem Rechteck-Beispiel könnte das so aussehen:</p>
<div class="codeBlock">public class Rectangle {
    double posX;
    double posY;
    double width;
    double height;

    // Konstruktor
    Rectangle(double initialPosX, double initialPosY, /* Übergebene Parameter */
                double initialWidth, double initialHeight) {
        // Den Objektvariablen die Werte der lokalen Variablen zuweisen
        posX = initialPosX;
        posY = initialPosY;
        width = initialWidth;
        height = initialHeight;
    }
}</div>
<p>In diesem Beispiel werden dem Konstruktor also alle vier Attribute als lokale Variablen übergeben und anschließend im Objekt selbst gespeichert.</p>
<p>Wie du siehst, müssen hierbei die Benennungen der Parameter angepasst werden, weil es sonst Namenskonflikte gibt. Es gibt aber eine Möglichkeit, trotzdem identische Namen zu verwenden: Das <code>this</code>-Keyword:</p>
<div class="codeBlock">class Rectangle {
    double posX;
    double posY;
    double width;
    double height;

    // Konstruktor
    Rectangle(double posX, double posY, double width, double height) {
        this.posX = posX;
        this.posY = posY;
        this.width = width;
        this.height = height;
        /*     ^        ^
         *     |      Bezieht sich auf die übergebene lokale Variable height
         *     |
         *   Bezieht sich auf das Objektattribut height
         */
    }
}</div>
<div class="info">
    <p>Das <code>this</code>-Keyword ermöglicht es uns zu betonen, dass wir etwas aus dem eigenen Objekt meinen.</p>
</div>
<p>Der Compiler weiß also durch Verwendung von <code>this</code>, dass in Zeile 9 die in Zeile 2 deklarierte Objektvariable <code>posX</code> gemeint ist, und nicht der gleichnamige Parameter aus Zeile 8. Gleichzeitig ist auch klar, dass ohne Verwendung von <code>this</code> eben doch die übergebene lokale Variable gemeint ist, und nicht die Objektvariable.</p>
<p>Wenn wir nun ein neues <code>Rectangle</code> Objekt instanziieren, können wir direkt die Eigenschaften übergeben:</p>
<div class="codeBlock">// Erstelle ein Rechteck an Position (1|2) mit Breite 16 und Höhe 9
Rectangle rect = new Rectangle(1.0, 2.0, 16.0, 9.0);</div>`,
    taskDescription: `<p>Erstelle einen Konstruktor für die in der letzten Aufgabe beschriebenen Klasse <code>User</code>. Dieser soll wie im obigen Beispiel lediglich alle Attribute der Klasse als Parameter entgegennehmen und dann die Objektattribute entsprechend setzen.</p>
<p>Erstelle anschließend in der <code>main</code>-Methode die folgenden <code>User</code>-Objekte:</p>
<ul>
    <li>Ted Shmosby, befreundet mit Barnacle, Stuart und Ranjit</li>
    <li>Barnacle Stinson, befreundet mit Ted und Ranjit</li>
    <li>Stuart Bowers, befreundet mit Ted</li>
    <li>Ranjit Singh, befreundet mit Ted und Barnacle</li>
</ul>
<p>Die <code>id</code>s kannst du frei wählen.</p>`,
    jDoodleId: '4ygp',
    resultDescription: `<p>Speichere anschließend die vier <code>User</code> in einem Array</p>
<div class="codeBlock">User[] users = {tedShmosby, barnacleStinson, stuartBowers, ranjitSingh};</div>
<p>und gib aus diesem Array die Namen aller <code>User</code> aus, die mehr als einen Freund haben.</p>`,
    possibleMistakes: [
      '<code>this</code> falsch verwendet',
      'Array-Größen falsch',
      '<code>error: cannot find symbol</code>: Du hast eine Variable verwendet, bevor sie deklariert wurde. Erstelle zuerst die leeren Arrays, und befülle sie nachdem das <code>User</code>-Objekt instanziiert wurde.'
    ],
    solutionCode: {
      'Lösungsvorschlag Teilaufgabe a)': `public class User {
    int id;
    String firstName;
    String lastName;
    User[] friends;

    User(int id, String firstName, String lastName, User[] friends) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.friends = friends;
    }

    public static void main(String[] args) {
        User tedShmosby = new User(0, "Ted", "Shmosby", new User[3]);
        User barnacleStinson = new User(1, "Barnacle", "Stinson", new User[2]);
        User stuartBowers = new User(2, "Stuart", "Bowers", new User[1]);
        User ranjitSingh = new User(3, "Ranjit", "Singh", new User[2]);

        tedShmosby.friends[0] = barnacleStinson;
        tedShmosby.friends[1] = stuartBowers;
        tedShmosby.friends[2] = ranjitSingh;

        barnacleStinson.friends[0] = tedShmosby;
        barnacleStinson.friends[1] = ranjitSingh;

        stuartBowers.friends[0] = tedShmosby;

        ranjitSingh.friends[0] = tedShmosby;
        ranjitSingh.friends[1] = barnacleStinson;
    }
}`,
      'Lösungsvorschlag Teilaufgabe b)': `public class User {
    int id;
    String firstName;
    String lastName;
    User[] friends;

    User(int id, String firstName, String lastName, User[] friends) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.friends = friends;
    }

    public static void main(String[] args) {
        User tedShmosby = new User(0, "Ted", "Shmosby", new User[3]);
        User barnacleStinson = new User(1, "Barnacle", "Stinson", new User[2]);
        User stuartBowers = new User(2, "Stuart", "Bowers", new User[1]);
        User ranjitSingh = new User(3, "Ranjit", "Singh", new User[2]);

        tedShmosby.friends[0] = barnacleStinson;
        tedShmosby.friends[1] = stuartBowers;
        tedShmosby.friends[2] = ranjitSingh;

        barnacleStinson.friends[0] = tedShmosby;
        barnacleStinson.friends[1] = ranjitSingh;

        stuartBowers.friends[0] = tedShmosby;

        ranjitSingh.friends[0] = tedShmosby;
        ranjitSingh.friends[1] = barnacleStinson;

        User[] users = {tedShmosby, barnacleStinson, stuartBowers, ranjitSingh};
        for (int i = 0; i < users.length; i++) {
            User user = users[i];
            if (user.friends.length > 1) {
                System.out.println(user.firstName + " " + user.lastName
                    + " hat mehr als einen Freund.");
            }
        }
    }
}`
    }
  },
  {
    title: 'Methoden',
    videoId: 'YbYenQqDohc',
    videoScript: `<p>Ab jetzt fängt es an Spaß zu machen!</p>
<p>Bisher hast du die komplette Programm-Logik immer so gut wie komplett in die <code>main</code>-Methode geschrieben. Damit ist jetzt Schluss! Ich präsentiere dir hiermit <i>Methoden</i>:</p>
<div class="info">
    <p>Eine Methode ist ein Programm-Stück, das (wie der Konstruktor) Parameter entgegen nimmt und irgendetwas in deinem Objekt macht. Anschließend wird ein Wert zurück gegeben (<i>returned</i>).</p>
    <p>Methoden sind also quasi Daten-Fabriken, die gewisse Inputs bekommen und daraus neue Outputs erzeugen. Außerdem kann eine Methode auch den Zustand von Objekten verändern.</p>
</div>
<div class="codeBlock">int square(int x) {
    int result = x * x;
    return result;
}</div>
<p>In diesem Beispiel nimmt die Methode <code>square</code> einen Parameter <code>x</code> entgegen und erzeugt eine neue Zahl, die dem Quadrat von <code>x</code> entspricht. <code>return</code> ist hierbei das Keyword, das die Ausführung der Methode beendet und den Wert (hier <code>result</code>) zurückgibt. Nachdem <code>return</code> ausgeführt wurde, können keine weiteren Statements innerhalb der Methode abgearbeitet werden. Das <code>int</code> ganz oben links in Zeile 1 ist der Typ des Rückgabe-Werts. Diese Methode produziert also ein <code>int</code>.</p>
<p>Um eine Methode aufzurufen, verwendet man folgende Schreibweise mit in Klammern übergebenen Parametern:</p>
<div class="codeBlock">int threeSquared = square(3); // 9</div>
<div class="info">
    <p><code>square</code> ähnelt sehr einer mathematischen Funktion, die einen Wert entgegen nimmt und einen neuen erzeugt, ohne dabei Nebeneffekte auf Zustände von Objekten zu haben. Deshalb werden solche Methoden auch oft <i>Funktion</i> genannt.</p>
</div>
<p>Wie beschrieben können Methoden aber auch den Zustand eines Objekts verändern:</p>
<div class="codeBlock">class Rectangle {
    double posX;
    double posY;
    double width;
    double height;

    Rectangle(double posX, double posY, double width, double height) {
        this.posX = posX;
        this.posY = posY;
        this.width = width;
        this.height = height;
    }

    double increaseWidth(double amount) {
        width += amount; // Auf this.width wird amount addiert
        return width; // Die neue Breite des Rechtecks wird zurückgegeben
    }
}</div>
<p>Die Methode <code>increaseWidth</code> verändert die Breite des Rechtecks um den Wert <code>amount</code> und gibt die neue Größe zurück.</p>
<p>Methoden können auch mehrere Parameter haben:</p>
<div class="codeBlock">double increaseSize(double amountX, double amountY) {
    width += amountX;
    height += amountY;
    return width * height; // Diese Methode gibt den neuen Flächeninhalt zurück
}</div>
<p>...Oder keine Parameter haben:</p>
<div class="codeBlock">boolean isVertical() {
    return height > width;
    // Gibt genau dann true zurück, falls das Rechteck höher ist als breit
}</div>
<p>...Oder keinen Rückgabewert haben. In diesem Fall wird der <code>return</code>-Type als <code>void</code> (engl. "Leere", "Nichts") angegeben:</p>
<div class="codeBlock">void resetPosition() { // Kein Rückgabewert, keine Parameter
    posX = 0.0;
    posY = 0.0;
    // Rechteck wurde zum Ursprung verschoben
}</div>
<div class="info">
    <p>Hiermit ist also ein weiteres Rätsel der <code>main</code>-Methode gelüftet: <code>void</code> steht dafür, dass <code>main</code> lediglich eine Prozedur ist, die zwar Befehle ausführt, aber keinen Rückgabewert erzeugt.</p>
</div>
<div class="attention">
    <p>Während eine Methode 0 bis beliebig viele Parameter haben kann, gibt es immer entweder keinen oder genau einen Rückgabewert. Es ist in Java <strong>unmöglich</strong>, mehrere Werte zurück zu geben. (Dieses vermeintliche Problem lässt sich aber auf unterschiedliche Arten umgehen.)</p>
</div>
<p>Methoden lassen sich in einem <a class="externalLink" href="https://de.wikipedia.org/wiki/Klassendiagramm" target="_blank">UML Klassendiagramm</a> im unteren Block auflisten:</p>
<div class="tutorialImgCenter">java_rectangle_uml_class_diagram_2.svg</div>`,
    taskDescription: `<p>Implementiere folgende Programmteile basierend auf der vorherigen Aufgabe:</p>
<div class="tutorialImgCenter">java_user_uml_class_diagram_3.svg</div>
<ul>
    <li>Ein Konstruktor, der die drei Parameter <code>id</code>, <code>firstName</code>, und <code>lastName</code> entgegennimmt und die Objektattribute entsprechend setzt. Das vierte Attribut (<code>friends</code>) soll immer mit einem neuen leeren Array initialisiert werden.</li>
    <li>Eine Methode <code>String fullName()</code>, die den Vor- und Nachnamen des <code>User</code>s mit Leerzeichen getrennt zurück gibt.</li>
    <li>Eine Methode <code>boolean hasMoreFriendsThan(User user)</code>, die genau dann <code>true</code> zurück gibt, wenn das eigene <code>friends</code> Array größer ist, als <code>user.friends</code></li>
    <li>Eine Methode <code>boolean hasFriend(User user)</code>, die genau dann <code>true</code> zurück gibt, wenn <code>user</code> in <code>friends</code> enthalten ist</li>
    <li>Eine Methode <code>addFriend(User user)</code>, die keinen Rückgabewert hat, sondern <code>friends</code> durch ein neues Array ersetzt, das alle Elemente aus friends enthält, und zusätzlich als letztes Element <code>user</code>. <code>addFriend()</code> soll also das existierende <code>friends</code> Array um einen weiteren <code>User</code> erweitern.</li>
</ul>`,
    jDoodleId: '4yBS',
    resultDescription: '<p>Teste anschließend deine Eingaben, indem du über die <code>addFriend()</code> Methode den einzelnen <code>User</code> Objekten Freunde hinzufügst und dann die restlichen Methoden mit beispielhaften Eingaben ausführst.</p>',
    possibleMistakes: [
      '<code>error: \';\' expected</code> bzw. <code>cannot find symbol</code>: Klammern bei Funktion vergessen',
      '<code>NullPointerException</code>: Im Konstruktor <code>friends</code> nicht initialisiert',
      '<code>error: not a statement</code>: <code>return</code> vergesssen',
      '<code>return</code>-Typ falsch',
      'Auf falsches <code>User</code> Objekt bezogen: Achte immer darauf, ob du gerade den <code>User</code> der Klasse meinst (Objektattribut), oder den übergebenen <code>User</code> (lokale Variable).',
      '<code>for</code>-Schleife(n) falsch',
      'Indizes bzw. größe von Arrays falsch'
    ],
    solutionCode: {
      'Lösungsvorschlag Konstruktor': `// ...

    User(int id, String firstName, String lastName) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;

        // Initialisiere friends mit einem leeren Array
        friends = new User[0];
    }

// ...`,
      'Lösungsvorschlag <code>fullName()</code>': `// ...

    String fullName() {
        return firstName + " " + lastName;
    }

// ...`,
      'Lösungsvorschlag <code>hasMoreFriendsThan()</code>': `// ...

    boolean hasMoreFriendsThan(User user) {
        // true, falls die Bedingung wahr ist, sonst false
        return friends.length > user.friends.length;
    }

// ...`,
      'Lösungsvorschlag <code>hasFriend()</code>': `// ...

    boolean hasFriend(User user) {
        // Iteriere durch alle Elemente in friends
        for (int i = 0; i < friends.length; i++) {
            if (friends[i] == user) {
                /*
                 * Falls das aktuelle Element der gesuchte User ist
                 * wird die Methode abgebrochen und true zurückgegeben
                 */
                return true;
            }
        }
        // Falls user nicht gefunden wurde wird false zurückgegeben
        return false;
    }

// ...`,
      'Lösungsvorschlag <code>addFriend()</code>': `// ...

    void addFriend(User user) {
        // Erstelle neues Array mit mehr Platz als in friends
        User[] friendsCopy = new User[friends.length + 1];

        // Kopiere alle Elemente aus friends in das neue Array
        for (int i = 0; i < friends.length; i++) {
            friendsCopy[i] = friends[i];
        }

        // Kopiere user an den letzten Index
        friendsCopy[friendsCopy.length - 1] = user;

        // Ersetze das Objektattribut mit dem neuen Array
        friends = friendsCopy;
    }

// ...`,
      'Lösungsvorschlag Gesamt': `public class User {
    int id;
    String firstName;
    String lastName;
    User[] friends;

    User(int id, String firstName, String lastName) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;

        friends = new User[0];
    }

    String fullName() {
        return firstName + " " + lastName;
    }

    boolean hasMoreFriendsThan(User user) {
        return friends.length > user.friends.length;
    }

    boolean hasFriend(User user) {
        for (int i = 0; i < friends.length; i++) {
            if (friends[i] == user) {
                return true;
            }
        }
        return false;
    }

    void addFriend(User user) {
        User[] friendsCopy = new User[friends.length + 1];

        for (int i = 0; i < friends.length; i++) {
            friendsCopy[i] = friends[i];
        }

        friendsCopy[friendsCopy.length - 1] = user;
        friends = friendsCopy;
    }

    public static void main(String[] args) {
        User tedShmosby = new User(0, "Ted", "Shmosby");
        User barnacleStinson = new User(1, "Barnacle", "Stinson");
        User stuartBowers = new User(2, "Stuart", "Bowers");
        User ranjitSingh = new User(3, "Ranjit", "Singh");

        tedShmosby.addFriend(barnacleStinson);
        tedShmosby.addFriend(stuartBowers);
        tedShmosby.addFriend(ranjitSingh);

        barnacleStinson.addFriend(tedShmosby);
        barnacleStinson.addFriend(ranjitSingh);

        stuartBowers.addFriend(tedShmosby);

        ranjitSingh.addFriend(tedShmosby);
        ranjitSingh.addFriend(barnacleStinson);

        System.out.println("Der vollständige Name von Ranjit lautet: "
            + ranjitSingh.fullName());

        if (tedShmosby.hasMoreFriendsThan(stuartBowers)) {
            System.out.println(tedShmosby.fullName()
                + " hat mehr Freunde als " + stuartBowers.fullName());
        }

        if (tedShmosby.hasFriend(barnacleStinson)) {
            System.out.println("Barnacle ist ein Freund von Ted");
        } else {
            System.out.println("Barnacle ist kein Freund von Ted");
        }

        if (barnacleStinson.hasFriend(tedShmosby)) {
            System.out.println("Ted ist ein Freund von Barnacle");
        } else {
            System.out.println("Ted ist kein Freund von Barnacle");
        }
    }
}`
    }
  },
  {
    title: 'return Keyword in void Methoden',
    videoId: 'kVe7lFFAJOI',
    videoScript: `<p>Das <code>return</code> Keyword kann nicht nur verwendet werden, um bei Beendigung eines Methodendurchlaufs einen Wert zurück zu geben, sondern auch um eine <code>void</code> Methode sofort zu beenden. Beispielsweise die Methode</p>
<div class="codeBlock">void meineMethode(int n) {
    if (n < 0) {
        return;
    }
    System.out.println(n);
}</div>
<p>bricht die Ausführung sofort ab, falls <code>n</code> negativ ist. <code>return</code> benötigt hierbei kein weiteres Argument. Die Konsolenausgabe geschieht in diesem Fall also nur, falls <code>n</code> nicht negativ ist. Demnach ist die Methode äquivalent zu folgender:</p>
<div class="codeBlock">void meineMethode(int n) {
    if (n >= 0) {
        System.out.println(n);
    }
}</div>`,
    taskDescription: '<p>Schreibe den folgenden Code zu einem äquivalenten Programm so um, dass nirgendwo ein <code>else</code> vorkommt. Verwende hierfür <code>return</code>.</p>',
    jDoodleId: '4KLC',
    resultDescription: `<p>Vielleicht fragst du dich, was es für einen Sinn hat, anstelle von <code>else</code> <code>return</code> zu verwenden. Die Antwort lautet: Es macht fast keinen Unterschied.</p>
<p>Allerdings kannst du durch diese Art von Umstellung verhindern, dass du zu viele <code>if</code>-<code>else</code> Abfragen ineinander schachtelst und damit dein Programm unübersichtlich wird. Am Ende ist das alles lediglich eine Frage der Lesbarkeit. Manchmal ist es intuitiver, nach einer bestimmten Abfrage lieber mit <code>return</code> den Methodendurchlauf zu beenden, anstatt über ein <code>else</code> den restlichen Teil zu überspringen.</p>`,
    possibleMistakes: [
      '<code>if</code> Abfragen falsch: Du brauchst immernoch mindestens drei <code>if</code>s',
      'Vergleich falsch: Überlege genau, in welchem Fall welche Ausgabe geschehen soll',
      'Zu früh abgebrochen: Wenn <code>n</code> nicht negativ ist, dann fehlen noch weitere Abfragen!',
      'Konsolenausgabe vergessen'
    ],
    solutionCode: `public class NumberCheck {
    int n;

    public NumberCheck(int n) {
        this.n = n;
    }

    private void check() {
        if (n >= 100) {
            System.out.println(n + " ist mehr als zweistellig");
            return;
        }

        if (n >= 10) { // Durch die obere Abfrage ist garantiert, dass n < 100 ist
            System.out.println(n + " ist zweistellig");
            return;
        }

        if (n >= 0) { // Durch die oberen Abfragen ist garantiert, dass n < 10 ist
            System.out.println(n + " ist einstellig");
            return;
        }

        // Durch die oberen Abfragen ist garantiert, dass n < 0 ist
        System.out.println(n + " ist negativ");
    }

    public static void main(String[] args) {
        new NumberCheck(42).check();
    }
}`
  },
  {
    title: 'Getter & Setter</br>und Access Modifier',
    videoId: 'b-13FeYl62g',
    videoScript: `<p>Stell dir vor du entwickelst zusammen mit deinen Kollegen einen Online-Shop, in dem unter anderem mit Nutzer-Adressen hantiert wird. Dafür schreibst du folgende Klasse:</p>
<div class="codeBlock">class Address {
    String street;
    int number;
    int zipCode;
    String city;

    Address(String street, int number, int zipCode, String city) {
        this.street = street;
        this.number = number;
        this.zipCode = zipCode;
        this.city = city;
    }

    String createAddressString() {
        return street + " " + number + ", " + zipCode + " " + city;
    }
}</div>
<p>Beispielsweise kann in einem Objekt der Klasse <code>Address</code> die Adresse "Arcisstraße 21, 80333 München" gespeichert werden.</p>
<p>Dein Kollege verwendet deine Klasse an anderer Stelle in eurem Projekt, um darin auf Attribute wie z.B. <code>street</code> zuzugreifen. Er baut also <i>Abhängigkeiten</i> zu deiner Implementierung auf.</p>
<p>Nachdem euer Online-Shop ein voller Erfolg ist fällt dir auf, dass du nicht bedacht hast, dass es auch internationale Kunden gibt die keine deutsche Adresse besitzen. Beispielsweise die Adresse "240 W 55th St, New York, NY 10019" lässt sich mit deiner Implementierung nicht so wirklich gut speichern. Also veränderst du kurzerhand deinen Code:</p>
<div class="codeBlock">class Address {
    String firstLine;
    String secondLine;

    // Erster Konstruktor
    Address(String firstLine, String secondLine) {
        this.firstLine = firstLine;
        this.secondLine = secondLine;
    }

    // Zweiter Konstruktor
    Address(String street, int number, int zipCode, String city) {
        firstLine = street + " " + number;
        secondLine = zipCode + " " + city;
    }

    String createAddressString() {
        return firstLine + ", " + secondLine;
    }
}</div>
<p>Mit dieser Implementierung können nun sowohl internationale (Konstruktor 1) als auch speziell deutsche Adressen (Konstruktor 2) gespeichert werden. Die Ausgabe der Methode <code>createAddressString</code> ist für deutsche Adressen immer noch genau die gleiche wie zuvor.</p>
<p>Allerdings hast du mit dieser Änderung komplett die Funktionalität des Programmteils deines Kollegens zerstört. Dieser hat sich darauf verlassen, dass er auf das <code>street</code> Attribut zugreifen kann, welches jetzt nicht mehr existiert. Das ist sehr blöd. Noch blöder sieht sowas aus, wenn dein Code beispielsweise bereits in einer Programmbibliothek veröffentlicht wurde und von anderen Entwicklern verwendet wird, zu denen du keinen Kontakt hast.</p>
<h4>Die Lösung</h4>
<div class="attention">
    <p>Trenne grundsätzlich Schnittstelle von Implementierung!</p>
</div>
<p>In dem Beispiel hätte es gereicht, wenn dein Kollege nur Zugriff auf die Methode <code>createAddressString</code> gehabt hätte. Du hättest es also irgendwie schaffen müssen, deinen Kollegen davon abzuhalten, Abhängigkeiten zu Details deiner Implementierung aufzubauen, und stattdessen nur über die Schnittstelle <code>createAddressString</code> zu gehen. Überraschung, in Java geht das!</p>
<div class="info">
    <p>Mit Access Modifiers lässt sich festlegen, welche Programm-Teile nach außen hin sichtbar sind. Zwei davon lauten:</p>
    <ul>
        <li><code>public</code>: Das betroffene Element ist von <strong>überall</strong> aus sichtbar</li>
        <li><code>private</code>: Das betroffene Element ist <strong>nur innerhalb der eigenen Klasse</strong> sichtbar</li>
    </ul>
    <p>Im <a class="externalLink" href="https://de.wikipedia.org/wiki/Klassendiagramm" target="_blank">UML Klassendiagramm</a> markiert man <code>private</code> Felder mit einem <code>-</code>, und <code>public</code> Felder mit einem <code>+</code>.</p>
</div>
<p>Eine deutlich bessere Implementierung wäre also von Anfang an gewesen:</p>
<div class="codeBlock">// Die Klasse an sich ist überall sichtbar
public class Address {
    // Die Attribute sind nur innerhalb der Klasse sichtbar
    private String street;
    private int number;
    private int zipCode;
    private String city;

    // Der Konstruktor ist überall sichtbar
    public Address(String street, int number, int zipCode, String city) {
        this.street = street;
        this.number = number;
        this.zi  pCode = zipCode;
        this.city = city;
    }

    // Einzige Schnittstelle um von außen die Informationen der Attribute zu bekommen
    public String createAddressString() {
        return street + " " + number + ", " + zipCode + " " + city;
    }
}</div>
<p>Hättest du nun deine Implementierung ändern wollen, wäre dies kein Problem mehr gewesen. Die <code>private</code> Modifier garantieren, dass es keinen direkten Zugriff auf die jeweiligen Objektattribute von außerhalb der Klasse geben kann. Da du darauf achtest, die Funktionalität nach außen hin nicht zu verändern, gibt es keine Probleme für andere Entwickler.</p>
<div class="info">
    <p>Wenn du weder <code>public</code> noch <code>private</code> verwendest, wird in Java standardmäßig der <i>Package</i> Modifier verwendet. Dieser verhält sich für uns erst einmal ähnlich wie <code>public</code>. Es ist jedoch best practice, für Klassen, Objektattribute, Konstruktoren, und Methoden explizit anzugeben, ob diese <code>public</code> sichtbar sein sollen oder nicht.</p>
</div>
<h4>Was, wenn jetzt aber doch ein Attribut nach außen sichbar sein soll?</h4>
<p>Du möchtest ein Attribut für andere Klassen <code>public</code> zur Verfügung stellen, aber trotzdem die Macht darüber behalten, eventuell Änderungen an deiner Implementierung vorzunehmen. Genau dafür gibt es <i>Getter & Setter</i>.</p>
<div class="info">
    <p>Getter & Setter sind Methoden, die ein <code>private</code> Objektattribut nach außen hin lesbar bzw. modifizierbar machen. Für das Attribut</p>
    <div class="codeBlock">private String name;</div>
    <p>könnte ein Getter und ein Setter so aussehen:</p>
    <div class="codeBlock">// Getter
public String getName() {
    return name;
}

// Setter
public void setName(String name) {
    this.name = name; // Erinnerung: this.name bezieht sich auf das Objektattribut
}</div>
    <p>Über den Getter lässt sich <code>name</code> auslesen, ohne direkten Zugriff auf das Attribut zu benötigen. Über den Setter kann <code>name</code> durch einen neuen Wert ersetzt werden.</p>
    <p>Falls nun die Implementierung geändert wird, gibt es keine Abhängigkeiten zu <code>name</code>, die Probleme bereiten. Lediglich die Getter und Setter müssen so angepasst werden, dass die Funktionalität nach wie vor gegeben ist.</p>
</div>`,
    taskDescription: `<p>Für einen Musik-Streaming-Dienst wird eine Datenbank aus Songs angelegt. Deine Aufgabe ist es, eine Klasse <code>Song</code> zu erstellen, deren Instanzen jeweils Informationen über ein bestimmtes Lied enthalten.</p>
<p>a) Erstelle die Klasse <code>Song</code> mit den folgenden Attributen:</p>
<div class="tutorialImgCenter">java_song_uml_class_diagram.svg</div>
<ul>
    <li><code>String title</code>: Der Titel des Songs</li>
    <li><code>String artist</code>: Der Interpret des Songs</li>
    <li><code>String album</code>: Das Album des Songs</li>
    <li><code>int year</code>: Das Veröffentlichungsjahr des Songs</li>
    <li><code>String[] genres</code>: Die Genres, zu denen der Song passt</li>
    <li><code>double duration</code>: Die Dauer des Songs in Sekunden</li>
    <li><code>int streamCount</code>: Die Anzahl, wie oft dieser Song bereits abgespielt wurde</li>
</ul>
<p>b) Füge einen Konstruktor hinzu, der alle Attribute entgegen nimmt und setzt. Ausnahme: <code>streamCount</code> soll nicht übergeben werden, sondern mit <code>0</code> initialisiert werden.</p>
<p>c) Implementiere anschließend für alle Attribute Getter und Setter</p>`,
    jDoodleId: '4z7n',
    resultDescription: '<p>Durch Verwendung von Getter und Setter Methoden würde es nun nichts ausmachen, wenn beispielsweise das <code>duration</code> Attribut plötzlich nicht mehr als double, sondern als <code>String</code> gespeichert werden sollte. Es müssten lediglich Getter und Setter für dieses eine Attribut so angepasst werden, dass die Funktionalität bestehen bleibt. (z.B. durch Umwandlung von einer Dauer <code>123.0</code> in <code>"2:03"</code> und umgekehrt.)</p>',
    possibleMistakes: [
      '<code>public</code> vor <code>class</code> vergessen',
      '<code>public</code> und <code>private</code> vertauscht',
      '<code>error: constructor Song in class Song cannot be applied to given types</code>: Dein Konstruktor soll nur die ersten 6 Attribute in der richtigen Reihenfolge übergeben bekommen',
      'Rechtschreibfehler irgendwo in Getter & Setter'
    ],
    solutionCode: `public class Song {
    private String title;
    private String artist;
    private String album;
    private int year;
    private String[] genres;
    private double duration;
    private int streamCount;

    public Song(String title, String artist, String album, int year,
            String[] genres, double duration) {
        this.title = title;
        this.artist = artist;
        this.album = album;
        this.year = year;
        this.genres = genres;
        this.duration = duration;

        streamCount = 0; // (Optional, weil int sowieso standardmäßig 0 ist)
    }

    public static void main(String[] args) {
        Song[] songs = {
            new Song("Sweet Home Alabama", "Lynyrd Skynyrd", "Second Helping", 1974, new String[] {"Folk", "Rock"}, 274.0),
            new Song("Bangarang", "Skrillex", "Bangarang", 2011, new String[] {"Dubstep", "House", "Brostep"}, 215.0),
            new Song("Nachbeben", "Alligatoah", "Nachbeben", 2022, new String[] {"Hip-Hop", "Rap"}, 255.0)
        };

        // Beispiel Song wählen
        Song song = songs[0];

        // streamCount 10 mal inkrementieren
        for (int i = 0; i < 10; i++) {
            song.setStreamCount(song.getStreamCount() + 1);
            System.out.println(song.getTitle() + " wurde " + song.getStreamCount() + " mal gehört.");
        }
    }

    public String getTitle() {
        return title;
    }

    public void setTitle(String title) {
        this.title = title;
    }

    public String getArtist() {
        return artist;
    }

    public void setArtist(String artist) {
        this.artist = artist;
    }

    public String getAlbum() {
        return album;
    }

    public void setAlbum(String album) {
        this.album = album;
    }

    public int getYear() {
        return year;
    }

    public void setYear(int year) {
        this.year = year;
    }

    public String[] getGenres() {
        return genres;
    }

    public void setGenres(String[] genres) {
        this.genres = genres;
    }

    public double getDuration() {
        return duration;
    }

    public void setDuration(double duration) {
        this.duration = duration;
    }

    public int getStreamCount() {
        return streamCount;
    }

    public void setStreamCount(int streamCount) {
        this.streamCount = streamCount;
    }
}`
  },
  {
    title: 'Call By Value, Call By Reference</br>und was ist eigentlich null?',
    videoId: '5JfGx0X0_kw',
    videoScript: `<p>Schau dir diese Methode an:</p>
<div class="codeBlock">public void increment(int n) {
    n++;
}</div>
<p>Eigentlich ist doch klar, was passieren soll: Es wird eine beliebige Zahl <code>n</code> übergeben, die dann um 1 erhöht wird. Doch nach Ausführung von</p>
<div class="codeBlock">int a = 42;
increment(a);
System.out.println(a);</div>
<p>Erscheint auf der Konsole <code>42</code>. <code>a</code> hat sich also nicht verändert.</p>
<p>Zweites Beispiel:</p>
<div class="codeBlock">public void repeat(String s) {
    s = s + s;
}</div>
<p>Diese Methode soll einen <code>String</code> mit sich selbst konkatenieren. Doch</p>
<div class="codeBlock">String name = "Peter";
repeat(name);
System.out.println(name);</div>
<p>liefert <code>Peter</code>, nicht <code>PeterPeter</code>.</p>
<p>Ein letztes Beispiel, bevor ich den Zauber auflöse:</p>
<div class="codeBlock">public void rename(User u) {
    u.setLastName("Lustig");
}</div>
<p>Nach Ausführung von</p>
<div class="codeBlock">User peter = new User(0, "Peter", "Müller");
rename(peter);
System.out.println(peter.getLastName());</div>
<p>erscheint <code>Lustig</code> auf der Konsole. Was geht hier vor sich?</p>
<p>Um die Frage zu beantworten, müssen wir verstehen was Java beim Aufruf von Methoden mit den übergebenen Parametern macht. Generell gibt es zwei Möglichkeiten, was passieren könnte:</p>
<ul>
    <li>Der jeweilige Parameter wird beim Methodenaufruf kopiert (<i>Call By Value</i>)</li>
    <li>Es wird eine Referenz (also quasi ein Link) auf das tatsächliche Objekt übergeben (<i>Call By Reference</i>)</li>
</ul>
<h4>Die kurze Anwort:</h4>
<div class="info">
    <p>In Java wird <strong>immer</strong> Call By Value verwendet!</p>
</div>
<h4>Die lange Antwort:</h4>
<p>Wenn ein Basistyp (also sowas wie <code>int</code> oder <code>boolean</code>) einer Methode übergeben wird, dann wird der Wert dieser Variable beim Aufruf kopiert. Es gibt also keine Möglichkeit für die Methode, die ursprüngliche Variable zu verändern. Aus diesem Grund macht die <code>increment()</code> Methode im ersten Beispiel augenscheinlich nichts, weil <code>n</code> lediglich eine Kopie von <code>a</code> ist.</p>
<p>Wenn ein Objekt (also z.B. ein <code>String</code> oder <code>User</code> etc.) übergeben wird, dann wird die Speicheradresse dieses Objekts beim Aufruf kopiert. Eine Objektvariable (Referenzvariable) enthält nämlich nichts anderes als die Adresse, an der das Objekt in deinem Arbeitsspeicher abgelegt ist*. In der Methode <code>repeat()</code> wird lediglich die lokale Referenzvariable <code>s</code> verändert, die aber nur eine Kopie der Adresse von <code>name</code> ist. Wenn <code>s</code> neu zugewiesen wird, wird diese Kopie der Adresse überschrieben mit einer neuen Speicheradresse des neuen <code>String</code>s.</p>
<p>Im dritten Beispiel wird ebenfalls lediglich die Speicheradresse von <code>peter</code> in die lokale Variable <code>u</code> kopiert. Jedoch wird hier nicht die Variable <code>u</code> selbst verändert, sondern durch den Aufruf von <code>u.setLastName()</code> wird über die gegebene Speicheradresse auf das tatsächliche <code>User</code> Objekt <code>peter</code> zugegriffen.</p>
<h4>Wofür brauche ich das jetzt?</h4>
<p>Du solltest dir merken, dass bei Methodenaufrufen in Java alle Parameter nur lokale Kopien sind. Bei Basistypen ist das eine Kopie des Werts, bei Objekten eine Kopie der Speicheradresse. Wenn du die dahinter liegenden Variablen bzw. Objekte verändern möchtest, musst du den neuen Wert entweder mit <code>return</code> als Rückgabewert der Methode übertragen, oder das entsprechende Objekt über die Referenzvariable direkt modifizieren (Siehe drittes Beispiel).</p>
<h4>Und was ist eigentlich dieses null?</h4>
<p>Wie erwähnt enthalten Referenzvariablen lediglich Speicheradressen. Bei der Zuweisung einer Variable</p>
<div class="codeBlock">String name = "Hans";</div>
<p>beispielsweise enthält <code>name</code> die Adresse im Arbeitsspeicher, an der die Zeichenkette <code>"Hans"</code> abgelegt ist.</p>
<p>Was enthält aber eine Referenzvariable, der kein Wert zugewiesen wurde?</p>
<div class="codeBlock">String name;</div>
<p>Die Antwort lautet: <code>null</code>.</p>
<div class="info">
    <p><code>null</code> ist eine Konstante die verwendet wird, um auszudrücken, dass eine Referenz auf kein existierendes Objekt zeigt. <code>null</code> ist der Standardwert für Objektvariablen. Wenn man versucht, auf ein Attribut / eine Methode von einer Referenz zuzugreifen, die <code>null</code> ist, wirft Java eine <code>NullPointerException</code>.</p>
</div>
<p>(*Technisch gesehen muss eine Referenzvariable nicht direkt eine Speicheradresse enthalten, sondern nur irgendeine Verbindung um auf das Objekt zugreifen zu können.)</p>`,
    taskDescription: `<p>In dieser Aufgabe ist dir eine Klasse <code>Calculator</code> gegeben. Im Konstruktor wird dem Objekt ein Attribut <code>mode</code> übergeben, das bestimmt, welche Rechenoperation beim Aufruf der Methode <code>apply()</code> verwendet wird. In der <code>main</code>-Methode werden für die Eingaben <code>a = 7.0</code> und <code>b = 3.0</code> jeweils Multiplikation und Subtraktion getestet. Die Methode <code>changeMode</code> soll das <code>mode</code>-Attribut eines übergebenen <code>Calculator</code>-Objekts ändern.</p>
<p>a) Führe das Programm aus. Das Ergebnis sollte <code>21.0</code> bzw. <code>4.0</code> sein. Was wird stattdessen ausgegeben? Wie erklärst du dir das Ergebnis? Ändere die Methode <code>apply()</code> sowie die beiden Aufrufe in <code>main</code>, sodass die Multiplikation wieder funktioniert.</p>`,
    jDoodleId: '4zzA',
    resultDescription: '<p>b) Das Ergebnis für die Subtraktion sollte <code>4.0</code> sein. Warum ist es das nicht? Wie musst du <code>changeMode()</code> ändern, damit das korrekte Ergebnis ausgegeben wird?</p>',
    possibleMistakes: [
      '<code>return</code> im letzten <code>else</code> vergessen',
      '<code>return</code> Typ von <code>apply()</code> nicht geändert',
      'Ergebnis in <code>main</code> nicht in einer Variable gespeichert',
      'Variable in <code>System.out.println()</code> nicht angepasst'
    ],
    solutionCode: {
      'Lösungsvorschlag Teilaufgabe a)': `/*
 * Es wird beide Male 7.0 ausgegeben, weil das Ergebnis der Berechnungen in apply()
 * immer nur in der lokalen Variable a gespeichert wird, die nur eine Kopie der Variable
 * a aus main ist. Daher wird der Wert in main nie verändert.
 */
public class Calculator {
    private int mode;

    public Calculator(int mode) {
        this.mode = mode;
    }

    /**
     * Rückgabewert ist jetzt double statt void
     */
    public double apply(double a, double b) {
        if (mode == 0) {
            return a + b; // Ergebnis wird nicht in a gespeichert, sondern returned
        } else if (mode == 1) {
            return a - b; // Ergebnis wird nicht in a gespeichert, sondern returned
        } else if (mode == 2) {
            return a * b; // Ergebnis wird nicht in a gespeichert, sondern returned
        } else if (mode == 3) {
            return a / b; // Ergebnis wird nicht in a gespeichert, sondern returned
        } else {
            return Double.NaN;
            // Ergebnis wird nicht in a gespeichert, sondern returned
        }
    }

    public void changeMode(Calculator calc, int mode) {
        calc = new Calculator(mode);
    }

    public static void main(String[] args) {
        double a = 7.0;
        double b = 3.0;

        // Teil 1: Multiplikation
        Calculator calc = new Calculator(2);
        double res = calc.apply(a, b); // Ergebnis wird jetzt über return an res zurückgegeben
        System.out.println(res);

        // Teil 2: Subtraktion
        calc.changeMode(calc, 1);
        res = calc.apply(a, b); // Ergebnis wird jetzt über return an res zurückgegeben
        System.out.println(res); // Sollte 4.0 sein
    }
}`,
      'Lösungsvorschlag Teilaufgabe b)': `/*
 * Das Ergebnis ist nicht 4.0, weil changeMode() lediglich die lokale Referenzvariable
 * calc überschrieben hat, die mit dem Ende der Methode gelöscht wird.
 * Stattdessen soll aber das mode Attribut innerhalb des Objekts geändert werden,
 * auf das calc zeigt.
 */
public class Calculator {
    private int mode;

    public Calculator(int mode) {
        this.mode = mode;
    }

    public double apply(double a, double b) {
        if (mode == 0) {
            return a + b;
        } else if (mode == 1) {
            return a - b;
        } else if (mode == 2) {
            return a * b;
        } else if (mode == 3) {
            return a / b;
        } else {
            return Double.NaN;
        }
    }

    public void changeMode(Calculator calc, int mode) {
        calc.setMode(mode);
        // calc.mode = mode; würde auch gehen, aber mit Setter ist schöner
    }

    public static void main(String[] args) {
        double a = 7.0;
        double b = 3.0;

        // Teil 1: Multiplikation
        Calculator calc = new Calculator(2);
        double res = calc.apply(a, b);
        System.out.println(res);

        // Teil 2: Subtraktion
        calc.changeMode(calc, 1);
        res = calc.apply(a, b);
        System.out.println(res);
    }

    /**
     * Setter, um mode zu verändern
     */
    public void setMode(int mode) {
        this.mode = mode;
    }
}

/*
 * Eine Alternative wäre auch gewesen, die changeMode() Methode komplett zu
 * entfernen und das mode Attribut direkt über den Setter zu verändern.
 */`
    }
  },
  {
    title: 'Zeichen: Das char-Keyword',
    videoId: 'stXrro_9UQE',
    videoScript: `<p>Du kennst bereits die Klasse <code>String</code>. Mit <code>String</code>s lassen sich Zeichenketten speichern. Aber wie werden eigentlich die Zeichen in einem <code>String</code> gepeichert? Dafür gibt es das <code>char</code>-Keyword.</p>
<p><code>char</code> Steht für "Character" (engl. für "Zeichen") und ist ebenso wie <code>int</code>, <code>boolean</code>, oder <code>float</code> ein Basistyp. <code>char</code>s lassen sich folgendermaßen verwenden:</p>
<div class="codeBlock">char lowerCaseA = 'a';
char upperCaseA = 'A';
char hashtag = '#';
char quotes = '"';

System.out.println("Ein kleines a: " + lowerCaseA);</div>
<p>Achte dabei darauf, dass ein <code>char</code> im Gegensatz zu einem <code>String</code> immer von einzelnen Gänsefüßchen (<code>'</code>) umgeben ist.</p>
<p>Intern werden <code>char</code>s ähnlich wie <code>int</code> als Ganzzahlen gespeichert. Daher können sie auch mit <code>int</code> verrechnet werden:</p>
<div class="codeBlock">char c = 'A';
System.out.println(c); // A

c += 2;
System.out.println(c); // C

c--;
System.out.println(c); // B</div>
<p>Die Reihenfolge der Zeichen wird in einer <i>Encoding Table</i> definiert, die angibt, welches Zeichen welcher Ganzzahl entspricht. Wenn du wissen möchtest, welchen Code ein bestimmtes <code>char</code> hat, kannst du nach "ASCII table" im Internet suchen.</p>
<div class="attention">
    <p>Vorsicht bei der Konkatenation von <code>char</code>s! Die Zeile</p>
    <div class="codeBlock">System.out.println('a' + 'b');</div>
    <p>produziert nicht <code>ab</code> auf der Konsole, sondern <code>195</code>. Hier werden die Zeichen nämlich nicht zu einem <code>String</code> konkateniert, sondern der Ganzzahlwert von <code>'a'</code> (97) mit dem Ganzzahlwert von <code>'b'</code> (98) addiert und zu einem <code>int</code> umgewandelt.</p>
    <p>Wenn du <code>char</code>s konkatenieren möchtest, musst du das linke zuerst zu einem <code>String</code> umwandeln, beispielsweise so:</p>
    <div class="codeBlock">System.out.println("Ausgabe: " + 'a' + 'b');</div>
    <p>In diesem Fall wird der <code>String</code> von links nach rechts mit den <code>char</code>s zusammengefügt.</p>
</div>
<h4>Übersicht Bisherige Basistypen</h4>
<table class="infoTable">
    <tr>
        <th>Basistyp</th>
        <th>Art</th>
        <th>Beispiele</th>
    </tr>
    <tr>
        <td><code>boolean</code></td>
        <td>Wahrheitswert</td>
        <td><code>false</code>, <code>true</code></td>
    </tr>
    <tr class="catBlue">
        <td><code>int</code></td>
        <td>Ganzzahl</td>
        <td><code>-10</code>, <code>0</code>, <code>7</code>, <code>42</code>, <code>9999</code></td>
    </tr>
    <tr>
        <td><code>char</code></td>
        <td>Zeichen</td>
        <td><code>'A'</code>, <code>'a'</code>, <code>'?'</code>, <code>'#'</code>, <code>'"'</code></td>
    </tr>
    <tr class="catGreen">
        <td><code>double</code></td>
        <td>Fließkommazahl</td>
        <td><code>-1.125</code>, <code>0.0</code>, <code>3.14159</code>, <code>Double.NaN</code>, <code>Double.NEGATIVE_INFINITY</code></td>
    </tr>
</table>`,
    taskDescription: `<p>In dieser Aufgabe ist eine Klasse <code>CharacterTable</code> gegeben, die in ihrem Attribut <code>characters</code> alle Zeichen mit einem Code zwischen 0 und einem festgelegten maximum speichert.</p>
<p>a) Implementiere den Konstruktor, der das <code>characters</code> Array mit genau so vielen Plätzen initialisiert, wie es Zeichen mit einem Code zwischen <code>0</code> (inklusiv) und <code>maximum</code> (exklusiv) gibt. Die Werte dürfen vorerst alle den <code>char</code> Standardwert behalten.</p>
<p>b) Implementiere die Methode <code>init()</code>, die das <code>characters</code> Array mit Zeichen befüllt. Jeder Eintrag soll genau das Zeichen enthalten, dessen Code dem Index im Array entspricht.</p>
<p>c) Implementiere zuletzt die Methode <code>printRange()</code>, die alle Zeichen vom Index <code>from</code> (inklusiv) bis zum index <code>to</code> (exklusiv) auf der Konsole ausgibt. Dabei soll der Index vorangestellt werden, sodass eine Ausgabe so aussehen könnte:</p>
<div class="codeBlock">table.printRange(65, 71);</div>
<pre>65: A
66: B
67: C
68: D
69: E
70: F</pre>`,
    jDoodleId: '4zBa',
    resultDescription: '<p>Du siehst, dass die alphabetische Sortierung in der ASCII Tabelle beibehalten wurde. Wenn du mit dem Ausgabeintervall etwas herumspielst, kannst du dir auch anzeigen lassen, welche Sonderzeichen welchem Code entsprechen. Einige dieser Zeichen sind jedoch nicht anzeigbare Steuerzeichen, die durch ein Platzhalter-Zeichen auf der Konsole ausgegeben werden.</p>',
    possibleMistakes: [
      'Array zu klein: <code>characters</code> soll genau <code>maximum</code> Einträge haben',
      'In <code>init()</code> <code>int</code> als Laufvariable verwendet. Verwende <code>char</code>, um mit Zeichen zu hantieren',
      '<code>to</code> als inklusiven Index verwendet: Es sollen bei <code>printRange()</code> nur Zahlen bis <code>to - 1</code> ausgegeben werden'
    ],
    solutionCode: {
      'Lösungsvorschlag Teilaufgabe a)': `// ...
    public CharacterTable(int maximum) {
        characters = new char[maximum];
    }
// ...`,
      'Lösungsvorschlag Teilaufgabe b)': `// ...
    public void init() {
        // Iteriere durch alle Zeichen von 0 bis (characters.length - 1)
        for (char c = 0; c < characters.length; c++) {
            // Setze das Zeichen an Stelle c auf den Wert von c
            characters[c] = c;
        }
    }
// ...`,
      'Lösungsvorschlag Teilaufgabe c)': `public class CharacterTable {
    private char[] characters;

    public CharacterTable(int maximum) {
        characters = new char[maximum];
    }

    public void init() {
        for (char c = 0; c < characters.length; c++) {
            characters[c] = c;
        }
    }

    public void printRange(int from, int to) {
        // Iteriere durch alle Elemente von from bis (to - 1)
        for (int i = from; i < to; i++) {
            System.out.println(i + ": " + characters[i]);
        }
    }

    public static void main(String[] args) {
        CharacterTable table = new CharacterTable(256);
        table.init();
        table.printRange(60, 100);
    }
}`
    }
  },
  {
      title: 'Escape Character und Sonderzeichen</br>(\\n, \\t, \\", \\\\ etc.)',
    videoId: 'xHcUWonzCDM',
    videoScript: `<p>Falls du einmal versucht hast, in einem <code>String</code> ein doppeltes Gänsefüßchen (<code>"</code>) zu verwenden, wirst du bereits gemerkt haben, dass das ohne Weiteres gar nicht so funktioniert. Denn dieses Zeichen ist ja bereits reserviert, um den Anfang und das Ende des <code>String</code>s zu markieren. Der Compiler wüsste also gar nicht, an welcher Stelle der <code>String</code> enden soll.</p>
<div class="info">
    <p>Um dennoch besondere Zeichen wie dieses verwenden zu können, muss man diese mit einem vorangestellten Backslash (<code>\\</code>) <i>escapen</i>:</p>
    <div class="codeBlock">String text = "Ein Gänsefüßchen --> \\" <--";</div>
    <p><code>text</code> enthält damit: <code>Ein Gänsefüßchen --> " <--</code></p>
</div>
<p>Es gibt noch einige weitere Anwendungen für <code>\\</code>:</p>
<ul>
    <li>
        <p>Du möchtest <code>'</code> in einem <code>char</code> speichern:</p>
        <div class="codeBlock">char c = '\\'';</div>
    </li>
    <li>
        <p>Du möchtest einen Zeilenumbruch in einem <code>String</code> oder <code>char</code> haben:</p>
        <div class="codeBlock">String lines = "Zeile1\\nZeile2\\nZeile3";</div>
        <p><code>lines</code> enthält den Text:</p>
        <pre>Zeile1
Zeile2
Zeile3</pre>
        <p><code>\\n</code> hat hierbei nichts mit dem Zeichen <code>n</code> zu tun, sondern ist einfach nur ein Code für das ASCII Zeichen #10 ("New Line").</p>
    </li>
    <li>
        <p>Du möchtest ein Tabulatorzeichen in einem <code>String</code> oder <code>char</code> haben:</p>
        <div class="codeBlock">String aTabB = "Hallo\\tWelt";</div>
        <p><code>aTabB</code> enthält einen Text der in etwa so aussieht: <code>Hallo&nbsp;&nbsp;&nbsp;&nbsp;Welt</code></p>
    </li>
    <li>
        <p>Was wenn man das <code>\\</code> Zeichen selbst verwenden möchte?</p>
        <div class="codeBlock">String path = "C:\\\\Users\\\\Bill\\\\Desktop";</div>
        <p><code>path</code> enthält den Text: <code>C:\\Users\\Bill\\Desktop</code></p>
    </li>
    <li>
        <p>Und noch einige etwas seltener verwendete <i>Escape Sequences</i></p>
    </li>
</ul>`,
    taskDescription: `<p>Verändere den <code>String</code> <code>text</code> so, dass bei Ausführung des Programms eine Konsolenausgabe ähnlich zu folgender erscheint:</p>
<pre>    Name    Vorname
1)  "Euler" "Leonhard"
2)  "Curie" "Marie"
3)  "Tesla" "Nikola"</pre>
<p>Verwende hierbei <strong>keine</strong> Leerzeichen. Es darf nur einen Aufruf von <code>println()</code> geben!</p>`,
    jDoodleId: '4KL5',
    possibleMistakes: [
      'Zeilenumbrüche (<code>\\n</code>) vergessen',
      'Tabulatorzeichen (<code>\\t</code>) vergessen',
      'Zu viele Tabs. In diesem Beispiel reicht ein Tab an jeder entsprechenden Stelle.',
      'Gänsefüßchen (<code>\\"</code>) vergessen',
      'Backslash vor zu escapendem Zeichen vergessen',
      'Gänsefüßchen am Ende des <code>String</code>s vergessen',
      'Semikolon vergessen 🥴'
    ],
    solutionCode: `public class EscapeExample {
    public static void main(String[] args) {
        String text = "\\tName\\tVorname\\n1)\\t\\"Euler\\"\\t\\"Leonhard\\"\\n2)\\t\\"Curie\\"\\t\\"Marie\\"\\n3)\\t\\"Tesla\\"\\t\\"Nikola\\"";

        System.out.println(text);
    }
}`
  },
  {
    title: 'Noch Mehr Basistypen:</br>byte, short, long, float',
    videoId: '67Ys_saN3JY',
    videoScript: `<p>Du kennst bereits Ganzzahlen (<code>int</code>), Fließkommazahlen (<code>double</code>), Wahrheitswerte (<code>boolean</code>), und Zeichen (<code>char</code>). Was soll es denn bitte noch alles geben?</p>
<p>Antwort: Genau das gleiche, nur anders.</p>
<p>Ich zeige dir folgendes Beispiel:</p>
<div class="codeBlock">int number = 1;
for (int i = 0; i < 5; i++) {
    number *= 1000;
    System.out.println(number);
}</div>
<p>Jeder normale Mensch würde folgende Ausgabe erwarten:</p>
<pre>1000
1000000
1000000000
1000000000000
1000000000000000</pre>
<p>Tatsächlich bekommen wir aber diese skurrile Antwort:</p>
<pre>1000
1000000
1000000000
-727379968
-1530494976</pre>
<h4>Was zur Hölle geht hier ab?</h4>
<div class="info">
    <p>In Java haben Ganzzahlen einen maximalen und minimalen Wert. Wird dieser Bereich über- oder unterschritten, so fängt man einfach von der anderen Seite an, weiter zu zählen.</p>
    <p>Bei <code>int</code> liegt dieses Intervall zwischen -2.147.483.648 und 2.147.483.647 (also in etwa ±2 Milliarden). Für die Berechnung</p>
    <div class="codeBlock">int res = 2147483647 + 1;</div>
    <p>ist das Ergebnis also <code>-2147483648</code>.</p>
</div>
<h4>long</h4>
<p>Möchte man mit größeren (oder kleineren) Zahlen hantieren, kann man den Basistyp <code>long</code> verwenden:</p>
<div class="codeBlock">long largeNumber = 9000000000000L;</div>
<p>Mit dem Suffix <code>L</code> am Ende der Zahl teilen wir dem Compiler mit, dass wir hier kein <code>int</code>, sondern ein <code>long</code> Literal verwenden wollen.</p>
<h4>byte und short</h4>
<p>Wenn es noch größere Ganzzahl-Typen als <code>int</code> gibt, warum dann nicht auch noch kleinere? Mit <code>byte</code> lässt sich eine 8 bit Ganzzahl speichern, mit <code>short</code> eine 16 bit Ganzzahl. (Für diese beiden Datentypen gibt es bislang leider keine speziellen Literal-Suffixe wie das <code>L</code> bei <code>long</code>.)</p>
<h4>float</h4>
<p>Analog zu <code>byte</code>, <code>short</code>, <code>int</code>, <code>long</code> bei Ganzzahlen, gibt es für Fließkommazahlen neben <code>double</code> den kleineren Fließkomma-Typ <code>float</code>. Variablen dieses Typs sind zwar auf deutlich weniger Nachkommastellen genau (ca. 7 statt ca. 15 bei <code>double</code>), können jedoch in manchen Situationen nützlich sein, um weniger Speicher zu verbrauchen. Ein <code>float</code> Literal lässt sich folgendermaßen einer Variable zuweisen:</p>
<div class="codeBlock">float pi = 3.14159F;</div>
<p>Hierbei fungiert das <code>F</code> wieder wie bei <code>long</code> als Hinweis an den Compiler, dass mit einem <code>float</code> (und nicht mit <code>double</code>) gearbeitet wird.</p>
<h4>Übersicht Basistypen</h4>
<table class="infoTable">
    <tr>
        <th>Basistyp</th>
        <th colspan="2">Wertebereich</th>
        <th>Informationsgehalt</th>
    </tr>
    <tr>
        <td><code>boolean</code></td>
        <td colspan="2">false, true</td>
        <td>1 bit*</td>
    </tr>
    <tr class="catBlue">
        <td><code>byte</code></td>
        <td class="rightAlign">-128</td>
        <td class="rightAlign">127</td>
        <td>8 bit</td>
    </tr>
    <tr class="catBlue">
        <td><code>short</code></td>
        <td class="rightAlign">-32 768</td>
        <td class="rightAlign">32 767</td>
        <td>16 bit</td>
    </tr>
    <tr class="catBlue">
        <td><code>int</code></td>
        <td class="rightAlign">-2 147 483 648</td>
        <td class="rightAlign">2 147 483 647</td>
        <td>32 bit</td>
    </tr>
    <tr class="catBlue">
        <td><code>long</code></td>
        <td class="rightAlign">-9 223 372 036 854 775 808</sup></td>
        <td class="rightAlign">9 223 372 036 854 775 807</td>
        <td>64 bit</td>
    </tr>
    <tr>
        <td><code>char</code></td>
        <td class="rightAlign">0</td>
        <td class="rightAlign">65 535</td>
        <td>16 bit</td>
    </tr>
    <tr class="catGreen">
        <td><code>float</code></td>
        <td class="rightAlign">±1.4&middot;10<sup>-45</sup></td>
        <td class="rightAlign">±3.4028235&middot;10<sup>38</sup></td>
        <td>32 bit</td>
    </tr>
    <tr class="catGreen">
        <td><code>double</code></td>
        <td class="rightAlign">±4.9&middot;10<sup>-324</sup></td>
        <td class="rightAlign">±1.7976931348623157&middot;10<sup>308</sup></td>
        <td>64 bit</td>
    </tr>
</table>
<i>*Tatsächliche Größe undefiniert</i>
<p>Und damit haben wir auch schon alle Basistypen in Java kennengelernt. Alle anderen Typen sind nicht-primitive Datentypen, also Klassen und klassenähnliche Strukturen.</p>
<h4>Und wann soll ich welchen Basistyp verwenden?</h4>
<ul>
  <li>Bei Ganzzahlen fällt deine erste Wahl immer auf <code>int</code>. Sollte der Zahlenbereich nicht ausreichen, verwendest du <code>long</code>.</li>
  <li>Bei Fließkommazahlen verwendest du <code>double</code>, außer die Genauigkeit ist nicht so wichtig und du möchtest Speicherplatz sparen, dann <code>float</code>.</li>
  <li>Wenn du mit Rohdaten arbeitest, beispielsweise beim Einlesen von Dateien, dann verwendest du <code>byte</code>.</li>
  <li><code>boolean</code> bzw. <code>char</code> verwendest du, wenn du mit Wahrheitswerten bzw. einzelnen Zeichen arbeitest.</li>
  <li><code>short</code> findet nur in sehr wenigen Einzelfällen Anwendung</li>
</ul>
<h4>Casting</h4>
<p>Jetzt gibt es aber (wie immer) neu auftretende Probleme: Wie kann ich eine Variable eines Basistyps zu einer Variable eines anderen Basistyps konvertieren? Der Code</p>
<div class="codeBlock">double d = 4.2;
int i = d;</div>
<p>liefert den Fehler</p>
<pre>error: incompatible types: possible lossy conversion from double to int</pre>
<p>Darin lässt sich auch schon genau das Problem ablesen: Bei der Umwandlung einer Fließkommazahl in eine Ganzzahl kann Information verloren gehen. Die Zahl 4.2 kann nicht exakt als Ganzzahl dargestellt werden. Mit einem sogenannten <i>expliziten Cast</i> lassen sich dennoch "größere" Datentypen in "kleinere" umwandeln:</p>
<div class="codeBlock">double d = 4.2;
int i = (int) d; // Expliziter Cast</div>
<p>Der Wert von <code>i</code> ist danach genau <code>4</code></p>
<div class="info">
    <p>Bei expliziten Casts von Fließkommazahlen zu Ganzzahlen wird immer <strong>abgerundet</strong>!</p>
</div>
<p>Analog dazu lassen sich auch andere Kombinationen von Basistypen konvertieren:</p>
<div class="codeBlock">double d = 65.12345678901235;

long l  = (long) d;  // 65
int i   = (int) l;   // 65
short s = (short) d; // 65
float f = (float) d; // 65.12346
byte b  = (byte) f;  // 65
char c  = (char) i;  // 'A'</div>
<p>In die andere Richtung (von "kleinen" Typen zu "großen") ist kein expliziter Cast notwendig:</p>
<div class="codeBlock">int i = 42;

long l   = i; // 42
float f  = i; // 42.0
double d = i; // 42.0</div>
<h4>Blick ich nicht, wann genau braucht man denn jetzt einen expliziten Cast?</h4>
<p>Das kannst du folgendem Diagramm entnehmen:</p>
<div class="tutorialImg">java_implicit_cast.svg</div>
<div class="info">
    <p>Möchte man <strong>in Pfeilrichtung</strong> casten, benötigt man keinen expliziten Cast:</p>
    <div class="codeBlock">short s = 10;
float f = s; // Von short nach float ist in Pfeilrichtung</div>
    <p>Möchte man <strong>gegen die Pfeilrichtung</strong> casten, muss man explizit den Typen angeben, zu dem man casten möchte:</p>
    <div class="codeBlock">char c = 'A';
byte b = (byte) c; // Von char nach byte ist (zum Teil) gegen die Pfeilrichtung</div>
</div>
<div class="attention">
    <p>Von <code>boolean</code> und nach <code>boolean</code> kann nicht gecastet werden.</p>
</div>`,
    taskDescription: `<p>In dieser Aufgabe sollst du eine Methode <code>average()</code> implementieren, die den Mittelwert ("Durchschnitt") aller Zahlen im Array <code>numbers</code> als <code>double</code> zurückgibt.</p>
<p>Der Mittelwert von Zahlen a<sub>1</sub>, a<sub>2</sub>, ..., a<sub>n</sub> lässt sich folgendermaßen berechnen: (a<sub>1</sub> + a<sub>2</sub> + ... + a<sub>n</sub>) / n</p>
<p>Beachte hierbei, dass bei Ganzzahldivisionen in Java abgerundet wird. Wir wollen hier aber ein genaueres Ergebnis!</p>
<p>Verwende zum Aufsummieren der einzelnen Zahlen keinen Fließkomma-Typen, da sonst Ungenauigkeiten entstehen können!</p>`,
    jDoodleId: '4Lr9',
    resultDescription: '<p>Die korrekte Ausgabe lautet <code>4.904444914285714E8</code>, wohingegen <code>4.90444491E8</code> explizit falsch ist!</p>',
    possibleMistakes: [
      'Ergebnis negativ: Beim Aufsummieren der Zahlen entsteht eine sehr große Zahl. Reicht da noch <code>int</code>?',
      'Ergebnis ist <code>4.90444491E8</code>: Du dividierst zwei Ganzzahlen, dabei wird abgerundet. Berechne das Ergebnis stattdessen als Fließkommazahl!'
    ],
    solutionCode: `public class ArrayCalculator {
    private int[] numbers;

    public ArrayCalculator(int[] numbers) {
        this.numbers = numbers;
    }

    public double average() {
        long sum = 0L; /* Muss long sein, weil der Wertebereich
                          von int (für die Beispieleingabe) nicht ausreicht! */

        for (int i = 0; i < numbers.length; i++) {
            sum += numbers[i]; // Kein expliziter Cast von int nach long notwendig
        }

        double sumAsDouble = sum; // Kein expliziter Cast von long nach double notwendig
        double lengthAsDouble = numbers.length; // Kein expliziter Cast von int nach double notwendig
        return sumAsDouble / lengthAsDouble; // Fließkommazahl-Division

        /*
         * Auch richtig, weil bei "Ganzzahl / Fließkommazahl"
         * ein impliziter Cast zur Fließkommazahl stattfindet:
         *
         *   double sumAsDouble = sum;
         *   return sumAsDouble / numbers.length;
         *
         * - ODER -
         *
         *   double lengthAsDouble = numbers.length;
         *   return sum / lengthAsDouble;
         *
         * Alternativ wäre auch ein expliziter Cast in Ordnung gewesen,
         * um sum vor der Division in eine Fließkommazahl umzuwandeln:
         *
         *   return ((double) sum) / numbers.length;
         *
         * - ODER -
         *
         *   return sum / ((double) numbers.length);
         *
         * - ODER -
         *
         *   return ((double) sum) / ((double) numbers.length);
         */
    }

    public static void main(String[] args) {
        int[] numbers = {-10, 2, 5, 99333444, 2111444555, 0, 1222333444};

        ArrayCalculator calc = new ArrayCalculator(numbers);

        System.out.println(calc.average());
    }
}`
  },
  {
    title: 'Do-While Schleife',
    videoId: 'yNddRE5OYzo',
    videoScript: `<p>Du kennst bereits <code>while</code> Schleifen. Schau dir mal folgendes Beispiel an:</p>
<div class="codeBlock">double x;
double y;

x = Math.random();
y = Math.random();

while (x + y > 1.0) {
    x = Math.random();
    y = Math.random();
}</div>
<p>Die Methode <code>Math.random()</code> gibt eine Zufallszahl zwischen 0 und 1 zurück. Solange die beiden Zahlen <code>x</code> und <code>y</code> in Summe größer als <code>1.0</code> sind, wird dieser Zufallsprozess wiederholt.</p>
<p>Du siehst, dass die Zeilen 4-5 identisch zu den Zeilen 8-9 sind. In solchen Situationen kann es schnell passieren, dass dein Code unübersichtlich wird. Besser wäre es wenn wir eine Möglichkeit hätten diese Zeilen zusammenzufassen, sprich den Körper einer <code>while</code> Schleife <strong>immer mindestens 1x</strong> auszuführen.</p>
<p>Genau das geht mit einer <code>do</code> <code>while</code> Schleife:</p>
<div class="codeBlock">double x;
double y;

do {
    x = Math.random();
    y = Math.random();
} while (x + y > 1.0);</div>
<p>Die Zeilen 5-6 werden hier <strong>immer mindestens 1x</strong> ausgeführt. Falls <strong>danach</strong> die Bedingung dann <code>true</code> ist, werden die Zeilen so lange erneut ausgeführt, bis die Bedingung <code>false</code> ist.</p>`,
    taskDescription: `<p>Bei einem Brettspiel darf ein Spieler seine Figur um die Summe der Augenzahlen zweier Würfel bewegen. Hat er einen Pasch gewürfelt, so darf er noch einmal würfeln.</p>
<p>Implementiere die Methode <code>rollDices()</code>, die entsprechend der erklärten Regeln Würfelt und die Figur bewegt.</p>
<h4>Hinweise</h4>
<ul>
    <li>Die Methode <code>dice()</code> gibt eine Zufallszahl zwischen 1 und 6 (inklusive) zurück.</li>
    <li><code>movePlayer()</code> bewegt die Figur um die Summe der übergebenen Argumente und gibt ein paar nützliche Informationen auf der Konsole aus.</li>
    <li>Verwende eine <code>do</code> <code>while</code> Schleife!</li>
</ul>`,
    jDoodleId: '4Mxr',
    possibleMistakes: [
      '<code>error: cannot find symbol</code>: Die Variablen, die du in einer <code>do</code> <code>while</code> Schleife verwendest, musst du schon vorher deklarieren.',
      'Bedingung in der <code>do</code> <code>while</code> Schleife falsch: Der Würfelvorgang soll so lange wiederholt werden, wie ein Pasch vorliegt.',
      'Mit nur einem Würfel gewürfelt: Auch bei einem Pasch soll wieder mit beiden Würfeln gewürfelt werden.'
    ],
    solutionCode: `public class BoardGame {
    private int playerPos;

    public BoardGame() {
        playerPos = 0;
    }

    public void rollDices() {
        int firstNumber;
        int secondNumber;

        do {
            firstNumber = dice();
            secondNumber = dice();

            movePlayer(firstNumber, secondNumber);
        } while (firstNumber == secondNumber);
    }

    private void movePlayer(int firstNumber, int secondNumber) {
        int steps = firstNumber + secondNumber;
        playerPos += steps;

        System.out.println("Würfel: [" + firstNumber + "] [" + secondNumber
            + "] -> Der Spieler hat sich " + steps
            + " Schritte bewegt und steht jetzt auf Feld " + playerPos + ".");

        if (firstNumber == secondNumber) {
            System.out.println("Pasch!");
        }
    }

    private int dice() {
        return (int) (Math.random() * 6.0 + 1.0);
    }

    public static void main(String[] args) {
        BoardGame game = new BoardGame();
        game.rollDices();
        game.rollDices();
        game.rollDices();
    }
}`
  },
  {
    title: 'For-Each Schleife',
    videoId: 'SFbTxgE9eu8',
    videoScript: `<p>Folgenden Programmcode hast du bisher sicher schon das ein oder andere Mal so oder so ähnlich gesehen:</p>
<div class="codeBlock">String[] array = {"a", "ab", "abca", "defg"};

for (int i = 0; i < array.length; i++) {
    String s = array[i];
    // ...
}</div>
<p>Die Schleife iteriert durch das Array elementweise. Vielleicht hast du dich bereits gefragt, ob das nicht irgendwie schöner geht. Die überraschende Antwort: Ja! Mit einer <code>for</code> each Schleife:</p>
<div class="codeBlock">String[] array = {"a", "ab", "abca", "defg"};

for (String s : array) {
    // ...
}</div>
<div class="info">
    <p>Eine <code>for</code> each Schleife ist eine Kurzschreibweise für eine <code>for</code> Schleife, bei der <strong>durch alle</strong> Elemente <strong>von links nach rechts</strong> iteriert wird, und <strong>der Index</strong> des aktuell durchlaufenen Elements <strong>irrelevant</strong> ist.</p>
    <p><code>for</code> each Schleifen funktionieren in Java nicht nur für Arrays, sondern auch für viele weitere Datenstrukturen.</p>
</div>
<p>Für unser Beispiel lässt sich die <code>for</code> each Schleife folgendermaßen lesen:</p>
<p>"Für jedes Element <code>s</code> aus <code>array</code>, mache ..."</p>
<div class="attention">
    <h4>Achtung!</h4>
    <p>Eine <code>for</code> each Schleife ist nicht gleichmächtig zu einer <code>for</code> Schleife.</p>
    <p>Dinge die mit einer <code>for</code> each Schleife nicht so einfach gehen:</p>
    <ul>
        <li>Ein Array rückwärts durchlaufen</li>
        <li>Jedes zweite Element auf der Konsole ausgeben</li>
        <li>Für jedes Element die Position im Array ausgeben</li>
        <li>Alle Elemente ab dem vierten Element ausgeben</li>
    </ul>
</div>`,
    taskDescription: `<p>Gegeben ist folgende <code>Vector</code> Klasse. Falls du nicht weißt, was ein Vektor ist, ist das nicht schlimm. Wir verstehen hier einen Vektor lediglich als eine Klasse, die ein Array aus Zahlen enthält.</p>
<p>Deine Aufgabe ist es, einige Methoden in der Klasse zu implementieren. Verwende dabei immer entweder eine <code>for</code> Schleife oder, falls (einfach) möglich, eine <code>for</code> each Schleife.</p>
<ul>
    <li>Die Methode <code>printAllElements()</code> soll alle Elemente des <code>numbers</code> Arrays zeilenweise auf der Konsole ausgeben</li>
    <li>Die Methode <code>printLastThreeElements()</code> soll <strong>die letzten drei</strong> Elemente des <code>numbers</code> Arrays zeilenweise auf der Konsole ausgeben. Du kannst davon ausgehen, dass <code>numbers</code> immer mindestens drei Elemente hat.</li>
    <li><code>sum()</code> soll alle Elemente des <code>numbers</code> Arrays addieren und als <code>double</code> Wert zurückgeben</li>
    <li><code>max()</code> soll die größte Zahl aus <code>numbers</code> finden und zurückgeben. Falls <code>numbers</code> leer ist, soll <code>-Double.MAX_VALUE</code> zurückgegeben werden. (Das ist die kleinstmögliche Zahl die ein <code>double</code> darstellen kann.)</li>
    <li>
        <p><code>add(Vector otherVector)</code> soll elementweise die Zahlen von <code>numbers</code> mit den Zahlen aus dem <code>numbers</code> Arrays aus <code>otherVector</code> addieren. Das Ergebnis soll als neue <code>Vector</code> Instanz zurückgegeben werden.</p>
        <p>Beispiel: Zwei Vektoren mit den <code>numbers</code> Arrays <code>{1.0, 2.0, 3.0}</code> und <code>{4.0, 5.0, 6.0}</code> ergeben in Summe einen neuen Vektor <code>{5.0, 7.0, 9.0}</code>.</p>
        <p>Beachte, dass sich die Werte in den <code>numbers</code> Arrays der beiden Objekte nicht ändern dürfen! Du musst also dein Ergebnis als Kopie speichern.</p>
        <p>Du kannst davon ausgehen, dass beide Vektoren immer gleich lang sind.</p>
    </li>
</ul>`,
    jDoodleId: '4Myc',
    resultDescription: `<p>Das Ergebnis des gegebenen Beispiels sollte folgende Ausgabe erzeugen:</p>
<pre>a:
1.0
2.0
3.0
-1.0
Letzte drei Elemente von a:
2.0
3.0
-1.0
Summe aller Elemente in a:
5.0
Maximales Element in b:
-0.5
Ergebnis von a + b
0.5
-8.0
-3.0
-4.33</pre>`,
    possibleMistakes: [
      'Werte von <code>numbers</code> verändert: Du solltest hier <strong>nirgendwo</strong> <code>numbers[i] = ...</code> stehen haben!',
      'Indexfehler in der <code>for</code> Schleife in <code>printLastThreeElements()</code>',
      'In <code>max()</code> nicht beachtet, dass die größte Zahl in einem Array auch negativ sein kann, oder das Array auch komplett leer sein kann',
      'In <code>add()</code> keinen neuen Vektor oder kein neues Array erzeugt',
      'Indexfehler in <code>add()</code>: Es soll elementweise addiert werden, also <code>result[i] = array1[i] + array2[i]</code>'
    ],
    solutionCode: {
      'Lösungsvorschlag <code>printAllElements()</code>': `// ...
    public void printAllElements() {
        /*
         * For each, weil wir lediglich alle Elemente der Reihe nach
         * ausgeben wollen
         */
        for (double number : numbers) {
            System.out.println(number);
        }
    }
// ...`,
      'Lösungsvorschlag <code>printLastThreeElements()</code>': `// ...
    public void printLastThreeElements() {
        /*
         * For, weil wir den Index i benötigen, damit wir ab dem
         * drittletzten Element beginnen können
         */
        for (int i = numbers.length - 3; i < numbers.length; i++) {
            System.out.println(numbers[i]);
        }
    }
// ...`,
      'Lösungsvorschlag <code>sum()</code>': `// ...
    public double sum() {
        double result = 0.0;
        /*
         * For each, weil uns die Position der Elemente egal ist
         */
        for (double number : numbers) {
            result += number;
        }

        return result;
    }
// ...`,
      'Lösungsvorschlag <code>max()</code>': `// ...
    public double max() {
        double largest = -Double.MAX_VALUE;
        /*
         * For each, weil uns die Position der Elemente egal ist
         */
        for (double number : numbers) {
            if (number > largest) {
                largest = number;
            }
        }

        return largest;
    }
// ...`,
      'Lösungsvorschlag <code>add(Vector otherVector)</code>': `// ...
    public Vector add(Vector otherVector) {
        double[] otherNumbers = otherVector.getNumbers();

        double[] result = new double[numbers.length];
        /*
         * For, weil wir den Index i benötigen, damit in numbers
         * und otherNumbers auf die Elemente an gleicher Position
         * zugegriffen wird
         */
        for (int i = 0; i < numbers.length; i++) {
            result[i] = numbers[i] + otherNumbers[i];
        }

        return new Vector(result);
    }
// ...`,
      'Lösungsvorschlag Gesamt': `public class Vector {
    private double[] numbers;

    public Vector(double[] numbers) {
        this.numbers = numbers;
    }

    public void printAllElements() {
        for (double number : numbers) {
            System.out.println(number);
        }
    }

    public void printLastThreeElements() {
        for (int i = numbers.length - 3; i < numbers.length; i++) {
            System.out.println(numbers[i]);
        }
    }

    public double sum() {
        double result = 0.0;
        for (double number : numbers) {
            result += number;
        }

        return result;
    }

    public double max() {
        double largest = -Double.MAX_VALUE;
        for (double number : numbers) {
            if (number > largest) {
                largest = number;
            }
        }

        return largest;
    }

    public Vector add(Vector otherVector) {
        double[] otherNumbers = otherVector.getNumbers();

        double[] result = new double[numbers.length];
        for (int i = 0; i < numbers.length; i++) {
            result[i] = numbers[i] + otherNumbers[i];
        }

        return new Vector(result);
    }

    public static void main(String[] args) {
        Vector a = new Vector(new double[] {1.0, 2.0, 3.0, -1.0});
        Vector b = new Vector(new double[] {-0.5, -10.0, -6.0, -3.33});

        System.out.println("a:");
        a.printAllElements();

        System.out.println("Letzte drei Elemente von a:");
        a.printLastThreeElements();

        System.out.println("Summe aller Elemente in a:");
        System.out.println(a.sum());

        System.out.println("Maximales Element in b:");
        System.out.println(b.max());

        System.out.println("Ergebnis von a + b");
        a.add(b).printAllElements();
    }

    public double[] getNumbers() {
        return numbers;
    }
}`
    }
  },
  {
    title: 'Switch-Case',
    videoId: 'n-VQJgV_l3U',
    videoScript: `<p>Folgendes Beispiel:</p>
<div class="codeBlock">int someNumber = // ... irgendein Wert

if (someNumber == 1 || someNumber == 2 || someNumber == 3) {
    System.out.println("someNumber ist 1, 2, oder 3");
} else if (someNumber == 4 || someNumber == 5) {
    System.out.println("someNumber ist 4 oder 5");
} else if (someNumber == 6) {
    System.out.println("someNumber ist 6");
} else {
    System.out.println("someNumber ist nicht in [1, 6]");
}</div>
<p>Hier wird abhängig von einer Variable <code>someNumber</code> ein Text ausgegeben. Allerdings können Verkettungen von Äquivalenz-Abfragen dieser Art schnell unübersichtlich werden. Dafür gibt es eine schönere Variante: <code>switch</code> <code>case</code></p>
<div class="info">
    <p>Mit einer <code>switch</code> <code>case</code> Abfrage lässt sich eine bestimmte Eingabe auf Gleichheit mit unterschiedlichen Werten prüfen. Es gibt mittlerweile <strong>zwei</strong> unterschiedliche Varianten von <code>switch</code> <code>case</code>:</p>
    <p>Das "alte" und das "neue".</p>
</div>
<h4>Das "alte" <code>switch</code> <code>case</code></h4>
<div class="codeBlock">int someNumber = // ... irgendein Wert

switch (someNumber) {
    case 1, 2, 3:
        System.out.println("someNumber ist 1, 2, oder 3");
        break;
    case 4, 5:
        System.out.println("someNumber ist 4 oder 5");
        break;
    case 6:
        System.out.println("someNumber ist 6");
        break;
    default:
        System.out.println("someNumber ist nicht in [1, 6]");
        break;
}</div>
<p>Hiermit kannst du die <strong>selbe</strong> Eingabe (hier <code>someNumber</code>) auf Gleichheit mit <strong>mehreren Werten</strong> prüfen. Die einzelnen <code>case</code>s entsprechen den <code>if</code> Abfragen.</p>
<p>Das <code>default</code> Keyword wird verwendet, um alle anderen Fälle abzudecken. Es entspricht also dem <code>else</code> bei <code>if</code> <code>else</code>.</p>
<div class="attention">
    <h4>Achtung!</h4>
    <p><strong>Jeder</strong> <code>case</code> sollte durch ein <code>break</code> beendet werden. Dieses sorgt dafür, dass das <code>switch</code> Statement beendet wird, sobald einmal eine Übereinstimmung gefunden wurde.</p>
    <p>Wenn das <code>break</code> weggelassen wird, können ungewollte Effekte auftreten:</p>
    <div class="codeBlock">switch (2) {
    case 1:
        System.out.print("A");
    case 2:
        System.out.print("B");
    case 3:
        System.out.print("C");
    default:
        System.out.print("D");
}</div>
    <p>Der obige Code gibt <code>BCD</code> auf der Konsole aus, nicht <code>B</code>!</br>Dieses Verhalten nennt man <i>fall-through</i>.</p>
</div>
<h4>Das "neue" <code>switch</code> <code>case</code></h4>
<div class="codeBlock">int someNumber = // ... irgendein Wert

switch (someNumber) {
    case 1, 2, 3 -> {
        System.out.println("someNumber ist 1, 2, oder 3");
    }
    case 4, 5 -> {
        System.out.println("someNumber ist 4 oder 5");
    }
    case 6 -> {
        System.out.println("someNumber ist 6");
    }
    default -> {
        System.out.println("someNumber ist nicht in [1, 6]");
    }
}</div>
<p>Beim neuen <code>switch</code> <code>case</code> verwendest du keine Doppelpunkte, sondern Pfeile (<code>-></code>). Außerdem benötigst du kein <code>break</code>. Die Körper der einzelnen <code>case</code>s sind von geschweiften Klammern umgeben.</p>
<p>Es gibt noch eine weitere sehr nützliche Funktionalität des neuen <code>switch</code> <code>case</code>:</p>
<div class="codeBlock">int month = // ... irgendein Wert

String season = switch (month) {
    case 3, 4, 5 -> "Spring";
    case 6, 7, 8 -> "Summer";
    case 9, 10, 11 -> "Autumn";
    case 12, 1, 2 -> "Winter";
    default -> null;
};</div>
<p>Hier verhält sich das <code>switch</code> <code>case</code> wie ein Ausdruck, der zu einem bestimmten Wert evaluiert. Die einzelnen <code>case</code>s enhalten direkt den Wert, der in dem jeweiligen Fall an <code>season</code> zurückgegeben werden soll.</p>
<p>Möchte man weiterhin mehrere Zeilen Code in einen <code>case</code> schreiben, so kann man ebenfalls geschweifte Klammern verwenden. Dann ist es aber notwendig, explizit anzugeben, was der Rückgabewert des <code>switch</code> Ausdrucks sein soll. Hierfür gibt es <code>yield</code>:</p>
<div class="codeBlock">String season = switch (4) {
    case 3, 4, 5 -> "Spring";
    case 6, 7, 8 -> {
        System.out.println("Es ist Sommer!");
        yield "Summer";
    }
    case 9, 10, 11 -> "Autumn";
    case 12, 1, 2 -> "Winter";
    default -> null;
};</div>
<div class="info">
    <p>Der <code>yield</code> <i>Identifier</i> funktioniert für das <code>switch</code> <code>case</code> ähnlich wie das <code>return</code> Keyword für Methoden funktioniert:</p>
    <p>Der Wert nach <code>yield</code> ist der Wert, zu dem der gesamte Audruck am Ende evaluiert, falls der jeweilige <code>case</code> eingetreten ist.</p>
    <p><code>yield</code> lässt sich auch mit dem alten <code>switch</code> <code>case</code> verwenden.</p>
    <h4>Übersicht reservierter Wörter für Kontrollstrukturen</h4>
    <table class="infoTable">
        <tr>
            <th>Keyword / Identifier</th>
            <th>Verwendung</th>

        </tr>
        <tr>
            <td><code>return</code></td>
            <td>Beendet einen Methodendurchlauf und gibt ggf. einen Wert aus der Methode zurück</td>
        </tr>
        <tr>
            <td><code>break</code></td>
            <td>Beendet die nächstäußere Schleife oder das nächstäußere <code>switch</code> <code>case</code></td>
        </tr>
        <tr>
            <td><code>yield</code></td>
            <td>Gibt einen Wert an, zu dem das <code>switch</code> <code>case</code> bei Eintritt dieses Falls evaluiert</td>
        </tr>
    </table>
</div>`,
    taskDescription: `<p>Die Klasse <code>Date</code> repräsentiert einen bestimmten Tag eines bestimmten Jahres. Deine Aufgabe ist es, drei mal die gleiche Methode auf eine unterschiedliche Art und Weise zu implementieren:</p>
<ul>
    <li><code>daysInMonthIfElse()</code> gibt die Anzahl an Tagen zurück, die der Monat <code>month</code> des Jahres <code>year</code> hat. Hierbei sollst du <strong>kein</strong> <code>switch</code> <code>case</code> verwenden, sondern ausschließlich <code>if</code> und <code>else</code> Abfragen nutzen.</li>
    <li>
        <p><code>daysInMonthSwitchCaseOld()</code> macht genau das gleiche wie <code>daysInMonthIfElse()</code>. Hier sollst du aber kein <code>if</code> <code>else</code> verwenden, sondern (das alte) <code>switch</code> <code>case</code>.</p>
        <p><strong>Ausnahme:</strong> Für die Überprüfung, ob der Februar 28 oder 29 Tage hat, darfst du <code>if</code> <code>else</code> verwenden.</p>
    </li>
    <li><code>daysInMonthSwitchCaseNew()</code> macht ebenfalls das gleiche. Hier sollst du das neue <code>switch</code> <code>case</code> verwenden. (Auch hier ist nur für den Februar Check <code>if</code> <code>else</code> erlaubt.)</li>
</ul>
<div class="info">
    <h4>Hinweise</h4>
    <ul>
        <li>Falls eine Methode eine ungültige Monats-Nummer bekommt, soll <code>-1</code> zurückgegeben werden</li>
        <li>Die Methode <code>isLeapYear()</code> gibt genau dann <code>true</code> zurück, falls das Jahr <code>year</code> ein Schaltjahr ist.</li>
        <li>
            <p>Die Anzahl der Tage im Monat kannst du dieser Tabelle entnehmen:</p>
            <table class="infoTable">
                <tr>
                    <th>Monat</th>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                    <td>5</td>
                    <td>6</td>
                    <td>7</td>
                    <td>8</td>
                    <td>9</td>
                    <td>10</td>
                    <td>11</td>
                    <td>12</td>
                </tr>
                <tr>
                    <th>Anzahl Tage</th>
                    <td>31</td>
                    <td>28 (Schaltjahr: 29)</td>
                    <td>31</td>
                    <td>30</td>
                    <td>31</td>
                    <td>30</td>
                    <td>31</td>
                    <td>31</td>
                    <td>30</td>
                    <td>31</td>
                    <td>30</td>
                    <td>31</td>
                </tr>
            </table>
        </li>
    </ul>
</div>`,
    jDoodleId: '4Mzp',
    resultDescription: `<p>Das Ergebnis des Beispiels sollte folgende Ausgabe produzieren:</p>
<pre>31, 31, 31
30, 30, 30
29, 29, 29
28, 28, 28
29, 29, 29</pre>`,
    possibleMistakes: [
      '<code>break</code> vergessen',
      'Anzahl Tage je Monat falsch eingetragen',
      '<code>yield</code> vergessen',
      'Semikolon bei <code>switch</code> <code>case</code> mit Rückgabewert vergessen',
      'Sonstige Syntaxfehler (Doppelpunkt / Pfeil vergessen, Klammern falsch etc.)'
    ],
    solutionCode: {
      'Lösungsvorschlag <code>if</code> <code>else</code>': `// ...
    public int daysInMonthIfElse() {
        if (month == 1
                || month == 3
                || month == 5
                || month == 7
                || month == 8
                || month == 10
                || month == 12) {
            return 31;
        } else if (month == 4
                || month == 6
                || month == 9
                || month == 11) {
            return 30;
        } else if (month == 2) {
            if (isLeapYear()) {
                return 29;
            } else {
                return 28;
            }
        } else {
            return -1;
        }
    }
// ...`,
      'Lösungsvorschlag <code>switch</code> <code>case</code> (alt)': `// ...
    public int daysInMonthSwitchCaseOld() {
        int result = 0;

        switch (month) {
            case 1, 3, 5, 7, 8, 10, 12:
                result = 31;
                break;
            case 4, 6, 9, 11:
                result = 30;
                break;
            case 2:
                if (isLeapYear()) {
                    result = 29;
                } else {
                    result = 28;
                }
                break;
            default:
                result = -1;
        }

        return result;
    }
// ...

// - ODER -

// ...
    public int daysInMonthSwitchCaseOld() {
        switch (month) {
            case 1, 3, 5, 7, 8, 10, 12:
                return 31;
            case 4, 6, 9, 11:
                return 30;
            case 2:
                if (isLeapYear()) {
                    return 29;
                } else {
                    return 28;
                }
            default:
                return -1;
        }
    }
// ...

// - ODER -

// ...
    public int daysInMonthSwitchCaseOld() {
        return switch (month) {
            case 1, 3, 5, 7, 8, 10, 12:
                yield 31;
            case 4, 6, 9, 11:
                yield 30;
            case 2:
                if (isLeapYear()) {
                    yield 29;
                } else {
                    yield 28;
                }
            default:
                yield -1;
        };
    }
// ...`,
      'Lösungsvorschlag <code>switch</code> <code>case</code> (neu)': `// ...
    public int daysInMonthSwitchCaseNew() {
        return switch (month) {
            case 1, 3, 5, 7, 8, 10, 12 -> 31;
            case 4, 6, 9, 11 -> 30;
            case 2 -> {
                if (isLeapYear()) {
                    yield 29; // Ergebnis aus switch zurückgeben
                } else {
                    yield 28;
                }
            }
            default -> -1;
        };
    }
// ...`,
      'Lösungsvorschlag Gesamt': `public class Date {
    private int year;
    private int month;
    private int day;

    public Date(int year, int month, int day) {
        this.year = year;
        this.month = month;
        this.day = day;
    }

    public int daysInMonthIfElse() {
        if (month == 1
                || month == 3
                || month == 5
                || month == 7
                || month == 8
                || month == 10
                || month == 12) {
            return 31;
        } else if (month == 4
                || month == 6
                || month == 9
                || month == 11) {
            return 30;
        } else if (month == 2) {
            if (isLeapYear()) {
                return 29;
            } else {
                return 28;
            }
        } else {
            return -1;
        }
    }

    public int daysInMonthSwitchCaseOld() {
        int result = 0;

        switch (month) {
            case 1, 3, 5, 7, 8, 10, 12:
                result = 31;
                break;
            case 4, 6, 9, 11:
                result = 30;
                break;
            case 2:
                if (isLeapYear()) {
                    result = 29;
                } else {
                    result = 28;
                }
                break;
            default:
                result = -1;
        }

        return result;
    }

    public int daysInMonthSwitchCaseNew() {
        return switch (month) {
            case 1, 3, 5, 7, 8, 10, 12 -> 31;
            case 4, 6, 9, 11 -> 30;
            case 2 -> {
                if (isLeapYear()) {
                    yield 29;
                } else {
                    yield 28;
                }
            }
            default -> -1;
        };
    }

    public boolean isLeapYear() {
        return year % 4 == 0 && year % 100 != 0 || year % 400 == 0;
    }

    public static void main(String[] args) {
        Date[] dates = {
            new Date(2023, 3, 14),
            new Date(1989, 11, 9),
            new Date(2000, 2, 29),
            new Date(2100, 2, 29),
            new Date(2400, 2, 29)
        };

        for (Date date : dates) {
            System.out.println(date.daysInMonthIfElse() + ", "
                + date.daysInMonthSwitchCaseOld() + ", "
                + date.daysInMonthSwitchCaseNew());
        }
    }
}`
    }
  },
  {
    title: 'IDE Installieren',
    videoId: 'TB2b0a9ONyA',
    videoScript: `<p>Wir haben uns jetzt seeehr lange mit diesem Online-Editor ausgetobt. Und für den Start war das auch eine perfekte Möglichkeit, die Einstiegshürde zu senken. Allerdings wirst du so natürlich nicht dein Leben lang programmieren. Dafür gibt es weitaus bessere Editoren.</p>
<p>Diese Art von Editoren nennt man <i>IDE</i>s, was für <i>Integrated Development Environment</i> steht, also "Integrierte Entwicklungsumgebung". Wir werden nun zusammen eine Java IDE installieren:</p>
<p>IntelliJ ist eine sehr bekannte IDE aus dem Hause JetBrains. Bevor ich mich rechtfertigen muss, warum ich hier IntelliJ empfehle: Es gibt keine beste IDE. Da gehen die Meinungen komplett auseinander. Jeder Entwickler muss für sich selbst herausfinden, mit welchen Tools er am besten zurecht kommt.</p>
<p>Du findest einen Link zur kostenlosen Community Edition von IntelliJ <a class="externalLink" href="https://www.jetbrains.com/de-de/idea/download/" target="_blank">hier</a>. Lade dir die entsprechende Version herunter:</p>
<details>
    <summary>Windows</summary>
    <div class="content">
        <ol>
            <li>Rufe den oben genannten <a class="externalLink" href="https://www.jetbrains.com/de-de/idea/download/" target="_blank">Downloadlink</a> auf</li>
            <li>Wähle auf der rechten Seite unter "Community" bei der schwarzen Auswahlbox <i>.exe</i> aus und lade dir den Installer herunter</li>
            <li>Führe die Datei aus und folge den Anweisungen des Installationsprogramms</li>
        </ol>
    </div>
</details>
<details>
    <summary>MacOS</summary>
    <div class="content">
        <ol>
            <li>Finde heraus, ob du einen Intel oder einen Apple Prozessor hast.</br>Das geht einfach, indem du auf deinem Desktop oben links auf den Apfel klickst, und dann auf "Über diesen Mac". Im sich öffnenden Fenster siehst du bei dem Punkt "Chip" bzw. "Prozessor", ob du eine Apple oder Intel CPU hast.</li>
            <li>Rufe den oben genannten <a class="externalLink" href="https://www.jetbrains.com/de-de/idea/download/" target="_blank">Downloadlink</a> auf</li>
            <li>Wähle auf der rechten Seite unter "Community" bei der schwarzen Auswahlbox entsprechend <i>.dmg (Intel)</i> oder <i>.dmg (Apple Silicon)</i> und lade dir den Installer herunter</li>
            <li>Führe die .dmg Datei aus und folge den Anweisungen des Installationsprogramms</li>
        </ol>
    </div>
</details>
<details>
    <summary>Linux</summary>
    <div class="content">
        <p>Die einfachste Art, IntelliJ für Linux zu installieren, sind Snaps:</p>
        <pre>sudo snap install intellij-idea-community --classic --edge</pre>
        <p>Aber ganz ehrlich: Wenn du Linux verwendest bekommst du es auch selber hin ein Programm zu installieren ;)</p>
    </div>
</details>
<h4>Projekt erstellen</h4>
<p>Starte IntelliJ und erstelle ein erstes Beispiel Projekt. Wähle einen beliebigen Namen.</p>
<p>Falls du unten bei "JDK" ein rotes <code>&lt;No SDK&gt;</code> siehst, klicke darauf und wähle "Download JDK..." aus. Klicke noch einmal auf "Download" und warte einen Moment.</p>
<p>Alle anderen Felder kannst du auf den Voreinstellungen lassen.</p>
<p>Du siehst, dass sich ein neues Fenster öffnet. Auf der linken Seite befindet sich ein <i>Project Explorer</i>, der alle Dateien in deinem Projekt auflistet. Rechts ist der Bereich, den wir zum Schreiben unseres Codes nutzen werden.</p>
<h4>Klasse erstellen</h4>
<p>Eine neue Klasse kannst du über einen Rechtsklick auf den <i>src</i> Ordner erstellen. Wähle <i>New</i> > <i>Java Class</i> und gib deiner Klasse einen Namen. Erinnerung: Klassen schreibt man in Java <strong>immer</strong> groß!</p>
<p>Bestätige mit <i>Enter</i>. Du siehst, dass die Signatur der Klasse bereits für dich erstellt wurde.</p>`,
    taskDescription: `<div class="info">
    <h4>Downloadlink IntelliJ</h4>
    <a class="externalLink" href="https://www.jetbrains.com/de-de/idea/download/" target="_blank">https://www.jetbrains.com/de-de/idea/download/</a>
</div>
<p>Sobald du deine erste Klasse erstellt hast, ergänze noch eine <code>main</code> Methode und gib wie im ersten Tutorial noch einmal etwas auf der Konsole aus.</p>
<div class="info">
    <p>Anstatt die <code>main</code> Methode selber zu schreiben, kannst du einfach innerhalb der Klasse <i>psvm</i> schreiben, und dann <i>Enter</i> drücken.</p>
    <p>Analog dazu kannst du <code>System.out.println();</code> generieren lassen, indem du <i>sout</i> tippst.</p>
</div>
<p>Führe das Programm nun aus, indem du im Project Explorer unter <i>src</i> auf deine Klasse rechtsklickst und <i>Run '[Klassenname].main()'</i> auswählst. Es sollte sich unten eine Konsole öffnen, auf der die Programmausgabe zu sehen ist.</p>
<div class="tutorialImg">ide_console.png</div>`,
    resultDescription: '<p>Ab sofort kannst du dein Programm auch über <span class="tutorialImgInline">ide_run.png</span> in der oberen rechten Ecke oder über SHIFT + F10 ausführen.</p>'
  },
  {
    title: 'Packages, package Modifier,</br>und das import Keyword',
    videoId: 'LezFWyzewAk',
    videoScript: `<p>Jetzt wo du mit einer richtigen IDE programmierst, hast du genug Platz um mehr als nur eine Klasse zu erstellen. In Java sollte nämlich (bis auf wenige Ausnahmen) jede Klasse in einer eigenen <i>.java</i> Datei liegen. Diese muss genauso heißen wie die Klasse selbst, also z.B. <i>Calculator.java</i> für die Klasse <code>Calculator</code>.</p>
<p>Ich denke du glaubst mir wenn ich dir sage, dass es bei großen Softwareprojekten seeehr viele Klassen und damit ebenso viele Dateien geben kann. Was aber, wenn mehrere dieser Klassen einen identischen Namen haben?</p>
<p>Um dieses Problem zu lösen, und um dem Projekt eine gute Struktur zu geben, gibt es <i>Packages</i>.</p>
<div class="info">
    <p>Packages sind Ordner, in denen <i>.java</i> Dateien liegen, und werden verwendet um zusammengehörende Klassen zu gruppieren. Wenn eine Klasse in einem anderen Package liegt als dem <i>default</i> Package, muss dies mit dem <code>package</code> Keyword explizit deklariert werden:</p>
    <div class="codeBlock">package game.util; // Die Datei Calculator.java liegt im Ordner src/game/util/

public class Calculator {
    // ...
}</div>
</div>
<p>In IntelliJ kannst du ein neues Package über <i>Rechtsklick auf src Ordner > New > Package</i> erstellen.</p>
<p>Wenn nun in einer Klasse ein Objekt einer anderen Klasse verwendet wird, muss dem Compiler zuerst mitgeteilt werden, in welcher Datei sich diese Klasse befindet. Hierfür gibt es das <code>import</code> Keyword:</p>
<div class="codeBlock">package game; // Game.java befindet sich in src/game/

/*
 * Calculator muss importiert werden, weil es nicht im
 * gleichen Package liegt wie Game
 */
import game.util.Calculator;

public class Game {
    // ...
    public static void main(String[] args) {
        // ...
        new Calculator();
        // ...
    }
}</div>
<h4>Noch eine Sache</h4>
<p>Du kennst schon die Access modifier <code>private</code> und <code>public</code>. Was aber, wenn du (wie zu Beginn dieses Kurses) gar keinen Access Modifier vor Attributen bzw. Methoden verwendest?</p>
<div class="info">
    <p>Der <i>package private</i> Modifier (oder auch <i>default</i> Modifier) wird implizit immer dann verwendet, wenn kein konkreter Access Modifier angegeben wurde.</p>
    <p>Attribute und Methoden, die <i>package private</i> sind, sind nur innerhalb des eigenen Packages sichtbar. Für Klassen innerhalb des Packages verhält sich dieser also wie <code>public</code>, für Klassen außerhalb wie <code>private</code>.</p>
    <table class="infoTable">
        <tr>
            <th>Modifier</th>
            <th><code>private</code></th>
            <th><i>package</i> (kein Modifier)</th>
            <th><code>public</code></th>
        </tr>
        <tr>
            <td>Zugriff aus eigener Klasse</td>
            <td>✔</td>
            <td>✔</td>
            <td>✔</td>
        </tr>
        <tr>
            <td>Zugriff aus eigenem Package</td>
            <td>❌</td>
            <td>✔</td>
            <td>✔</td>
        </tr>
        <tr>
            <td>Zugriff aus anderen Packages</td>
            <td>❌</td>
            <td>❌</td>
            <td>✔</td>
        </tr>
    </table>
</div>
<p>Du solltest in den allermeisten Fällen jedoch explizit <code>private</code> oder <code>public</code> als Modifier angeben.</p>`,
    taskDescription: `<h4>Tic-Tac-Toe</h4>
<p>Diese Übung wird ein bisschen größer als sonst. Dafür wirst du am Ende ein funktionierendes Tic-Tac-Toe Spiel für zwei Spieler programmiert haben.</p>
<h4>Teil 1: Nutzereingabe</h4>
<p>Bisher hast du immer nur Ausgaben auf der Konsole angezeigt. Wir wollen aber auch Nutzereingaben über diese entgegennehmen. Hierfür gibt es in der Java-Bibliothek die Klasse <code>Scanner</code> des Packages <code>java.util</code>. Du kannst beispielsweise mit der folgenden Methode <code>readInt(String inputText)</code> eine Ganzzahl einlesen:</p>
<div class="codeBlock">public class UserInput {
    private Scanner scanner;

    public UserInput() {
        scanner = new Scanner(System.in); /* System.in ist wie System.out,
                                             nur in die andere Richtung */
    }

    /**
     * Gibt eine Zahl zurück, die der Nutzer über die Konsole eingibt.
     * inputText ist ein Text, der links neben der Nutzereingabe angezeigt wird, z.B.
     *
     *     Dein Name: [Eingabe]
     */
    public int readInt(String inputText) {
        System.out.print(inputText);
        return scanner.nextInt();
    }
}</div>
<p>Aufgaben</p>
<ul>
    <li>Erstelle ein neues Java Projekt</li>
    <li>Erstelle eine neue Klasse <code>UserInput</code> im Package <code>tictactoe.input</code> und füge den Code von oben ein</li>
    <li>Importiere <code>Scanner</code> aus dem Package <code>java.util</code></li>
</ul>
<h4>Teil 2: Spielfeld</h4>
<p>Wir benötigen ein Spielfeld. Bei Tic-Tac-Toe ist das ein 3 x 3 Gitter aus einzelnen Feldern, die entweder leer (<code>' '</code>) sind oder von einem Spieler gesetzt wurden (<code>'X'</code> / <code>'O'</code>). Wir modellieren dieses Spielfeld mit einer neuen Klasse, die ein 9-elementiges <code>char</code> Array enthält.</p>
<p>Erstelle eine Klasse <code>GameGrid</code> im Package <code>tictactoe.grid</code> und implementiere die folgenden Programmteile:</p>
<ul>
    <li>Ein Objektattribut <code>content</code>, das ein <code>char</code> Array ist und später speichert, welche Felder von welchem Spieler belegt sind. Index 0 soll hierbei die obere linke Ecke sein, Index 8 die untere rechte.</li>
    <li>Ein Konstruktor, der <code>content</code> mit 9 Leerzeichen initialisiert</li>
    <li>Eine Methode <code>void set(int position, char value)</code>, die das Feld an Position <code>position</code> mit dem Wert <code>value</code> füllt (Du kannst davon ausgehen, dass <code>position</code> ein valider Index ist)</li>
    <li>Eine Methode <code>boolean freePosition(int position)</code>, die genau dann <code>true</code> zurückgibt, wenn <code>position</code> ein gültiger Index für <code>content</code> ist, und das Feld an diesem Index leer ist</li>
    <li>Eine Methode <code>boolean full()</code>, die genau dann <code>true</code> zurückgibt, wenn alle Felder durch einen Spieler gesetzt wurden, also keines mehr leer ist</li>
    <li>
        <p>Eine Methode <code>void printToConsole()</code>, die zuerst eine Leerzeile auf der Konsole ausgibt, und anschließend das Spielfeld in folgender Form (Beispiel):</p>
        <pre> O |   |
---|---|---
 O | X |
---|---|---
   |   | X</pre>
    </li>
    <li>Eine Methode <code>boolean won()</code>, die genau dann <code>true</code> zurückgibt, wenn ein Spieler gewonnen hat (egal welcher). Das ist der Fall, wenn eine Zeile, eine Spalte, oder eine Diagonale nur noch aus <code>'X'</code> bzw. <code>'O'</code> besteht.</li>
</ul>
<h4>Teil 3: Das Eigentliche Spiel</h4>
<p>Lass uns nun das eigentliche Spiel zusammenbauen:</p>
<ul>
    <li>Erstelle eine Klasse <code>TicTacToe</code> im Package <code>tictactoe</code></li>
    <li>Erstelle die Objektattribute <code>GameGrid gameGrid</code> und <code>UserInput userInput</code>, die in einem parameterlosen Konstruktor instanziiert werden</li>
    <li>Erstelle eine Methode <code>int readValidPosition(char currentPlayer)</code>, die eine vom Nutzer eingegebene Spielfeld-Position zurückgibt. Falls diese jedoch kein gültiger Index ist oder das Feld bereits belegt ist, soll der Vorgang wiederholt werden, bis eine korrekte Eingabe vorliegt</li>
    <li>
        <p>Erstelle eine Methode <code>void gameLoop()</code>, die vom Konstruktor aufgerufen wird und den Spielablauf steuert. Dieser ist folgendermaßen:</p>
        <ul>
            <li>Das Spielfeld wird vor jedem Zug auf der Konsole ausgegeben</li>
            <li>Spieler X beginnt</li>
            <li>Nach jedem Zug ist der jeweils andere Spieler an der Reihe</li>
            <li>Hat ein Spieler gewonnen, so ist das Spiel beendet. Es soll ausgegeben werden, wer der Gewinner ist</li>
            <li>Sind alle 9 Felder belegt und kein Spieler hat gewonnen, ist das Spiel unentschieden und endet</li>
        </ul>
    </li>
    <li>Erstelle eine <code>main</code> Funktion, die den Konstruktor aufruft</li>
</ul>
<p>Denk daran, dass du die Methoden der anderen Klassen nicht zum Spaß implementiert hast!</p>
<h4>Fertig!</h4>
<p>Teste dein Spiel, ob alles so funktioniert wie erwartet. Vermutlich musst du noch einige Fehler suchen und beheben.</p>`,
    possibleMistakes: [
      '<code>Missing package statement</code>: Du musst in jeder Klasse angeben, in welchem Package diese sich befindet',
      '<code>Cannot resolve symbol \'Xyz\'</code>: Der Import für <code>Xyz</code> fehlt',
      'Es passiert nichts: Ruft <code>main</code> den Konstruktor auf? Ruft der Konstruktor <code>gameLoop()</code> auf?',
      'Das Spiel endet sofort nach dem ersten Zug: Gibt deine <code>won()</code> Methode nur <code>true</code> zurück, wenn die Felder nicht leer sind?',
      '<code>\'xyz()\' has private access in \'Abc\'</code> Methoden in <code>Abc</code> sind nicht <code>public</code>'
    ],
    solutionCode: {
      'Lösungsvorschlag UserInput.java': `package tictactoe.input; // UserInput.java befindet sich in src/tictactoe/input

import java.util.Scanner;

public class UserInput {
    private Scanner scanner; // Scanner ist Teil der Java-Bibliothek und ermöglicht Nutzereingaben

    public UserInput() {
        scanner = new Scanner(System.in);
    }

    /**
     * Gibt eine Zahl zurück, die der Nutzer über die Konsole eingibt.
     */
    public int readInt(String inputText) {
        System.out.print(inputText);
        return scanner.nextInt();
    }
}`,
      'Lösungsvorschlag GameGrid.java': `package tictactoe.grid; // GameGrid.java befindet sich in src/tictactoe/grid

public class GameGrid {
    private char[] content;

    public GameGrid() {
        content = new char[9]; // 3 x 3 Gitter hat insgesamt 9 Felder
        for (int i = 0; i < content.length; i++) {
            content[i] = ' '; // Jedes Feld ist leer
        }
    }

    public void set(int position, char value) {
        content[position] = value;
    }

    public boolean freePosition(int position) {
        return position >= 0 && position < 9 && content[position] == ' ';
    }

    public boolean full() {
        for (int i = 0; i < 9; i++) {
            if (content[i] == ' ') {
                return false;
            }
        }
        return true;
    }

    public void printToConsole() {
        System.out.println(); // Leerzeile
        System.out.println(" " + content[0] + " | " + content[1] + " | " + content[2]); // Zeile 1
        System.out.println("---|---|---");
        System.out.println(" " + content[3] + " | " + content[4] + " | " + content[5]); // Zeile 2
        System.out.println("---|---|---");
        System.out.println(" " + content[6] + " | " + content[7] + " | " + content[8]); // Zeile 3
    }

    public boolean won() {
        // Zeilen
        for (int y = 0; y < 3; y++) {
            if (content[y * 3] != ' ' && content[y * 3] == content[y * 3 + 1]
                                      && content[y * 3] == content[y * 3 + 2]) {
                return true;
            }
        }

        // Spalten
        for (int x = 0; x < 3; x++) {
            if (content[x] != ' ' && content[x] == content[x + 3]
                                  && content[x] == content[x + 6]) {
                return true;
            }
        }

        // Diagonalen
        boolean diag1Full = content[0] == content[4] && content[0] == content[8];
        boolean diag2Full = content[2] == content[4] && content[2] == content[6];
        return content[4] != ' ' && (diag1Full || diag2Full);
    }
}`,
      'Lösungsvorschlag TicTacToe.java': `package tictactoe; // TicTacToe.java befindet sich in src/tictactoe

import tictactoe.grid.GameGrid;
import tictactoe.input.UserInput;

public class TicTacToe {
    private GameGrid gameGrid;
    private UserInput userInput;

    public TicTacToe() {
        gameGrid = new GameGrid();
        userInput = new UserInput();

        gameLoop(); // Spiel starten
    }

    public void gameLoop() {
        char currentPlayer = 'X';

        while (!gameGrid.full()) { // Solange nicht alle 9 Felder belegt sind
            gameGrid.printToConsole();

            // Feld setzen
            int pos = readValidPosition(currentPlayer);
            gameGrid.set(pos, currentPlayer);

            // Prüfen, ob jemand gewonnen hat
            if (gameGrid.won()) {
                gameGrid.printToConsole();
                System.out.println("Spieler " + currentPlayer + " hat gewonnen!");
                return; // Methode abbrechen
            }

            // Anderer Spieler ist am Zug
            if (currentPlayer == 'X') {
                currentPlayer = 'O';
            } else {
                currentPlayer = 'X';
            }
        }

        gameGrid.printToConsole();
        System.out.println("Unentschieden!");
    }

    private int readValidPosition(char currentPlayer) {
        int pos;
        do {
            pos = userInput.readInt("Spieler " + currentPlayer + ": ");
        } while (!gameGrid.freePosition(pos));
        return pos;
    }

    public static void main(String[] args) {
        new TicTacToe();
    }
}`
    }
  },
  {
    title: 'Vererbung',
    videoId: 'sU22DGddZz0',
    videoScript: `<p>Keine Sorge, beim Thema Vererbung geht es weder um Todesfälle noch um Debatten über soziale Gerechtigkeit. Worum geht es dann?</p>
<p>Stell dir vor, dass ein Computerspiel programmiert werden soll. Schau dir dazu die folgenden beiden Klassen an:</p>
<h4>Alien.java</h4>
<div class="codeBlock">public class Alien {
    private float posX;
    private float posY;
    private int health;

    public Alien(float posX, float posY, int health) {
        this.posX = posX;
        this.posY = posY;
        this.health = health;
    }

    public void attack(Sheep s) {
        // ... Greife ein bestimmtes Schaf an
    }

    public float getPosX() {
        return posX;
    }

    public float getPosY() {
        return posY;
    }

    public int getHealth() {
        return health;
    }

    // Optional noch Setter
}</div>
<h4>Sheep.java</h4>
<div class="codeBlock">public class Sheep {
    private float posX;
    private float posY;
    private int health;
    private Color color;

    public Alien(float posX, float posY, int health, Color color) {
        this.posX = posX;
        this.posY = posY;
        this.health = health;
        this.color = color;
    }

    public float getPosX() {
        return posX;
    }

    public float getPosY() {
        return posY;
    }

    public int getHealth() {
        return health;
    }

    public Color getColor() {
        return color;
    }

    // Optional noch Setter
}</div>
<p>Du siehst, dass die Attribute und Methoden der beiden Klassen teilweise übereinstimmen. Das liegt daran, dass beide Klassen irgendeine Art von Kreatur repräsentieren und damit eine Ähnlichkeit aufweisen.</p>
<p>Wollte man nun beispielsweise bei allen Kreaturen ein Attribut oder eine Methode hinzufügen, verändern, oder entfernen, so müsste man dies in allen zugehörigen Klassen tun. Du weißt was jetzt kommt: "Das ist unübersichtlich und geht besser."</p>
<h4>Das <code>extends</code> Keyword</h4>
<p>Lass uns eine dritte Klasse <code>Creature</code> erstellen, die alle Attribute und Methoden enthält, die <code>Alien</code> und <code>Sheep</code> teilen:</p>
<h4>Creature.java</h4>
<div class="codeBlock">public class Creature {
    private float posX;
    private float posY;
    private int health;

    public Creature(float posX, float posY, int health) {
        this.posX = posX;
        this.posY = posY;
        this.health = health;
    }

    public float getPosX() {
        return posX;
    }

    public float getPosY() {
        return posY;
    }

    public int getHealth() {
        return health;
    }
}</div>
<p>Nun lassen wir <code>Alien</code> und <code>Sheep</code> von <code>Creature</code> <i>erben</i>.</p>
<div class="info">
    <p>Wenn eine Klasse <code>B</code> von einer Klasse <code>A</code> erbt, dann übernimmt <code>B</code> implizit alle Attribute und Methoden von <code>A</code>. Man kann dann also beispielsweise eine Methode der Oberklasse <code>A</code> auch über eine Instanz der Unterklasse <code>B</code> aufrufen, obwohl diese Methode nirgendwo in <code>B</code> vorkommt.</p>
    <p>Vererbung lässt sich in Java über das Keyword <code>extends</code> realisieren. Dieses wird einfach in die Signatur der Klasse <code>B</code> aufgenommen:</p>
    <div class="codeBlock">public class B extends A { // B erbt alle Attribute und Methoden von A
    // ...
}</div>
</div>
<p>Grafisch lässt sich dieser Zusammenhang nun in einem <a class="externalLink" href="https://de.wikipedia.org/wiki/Klassendiagramm" target="_blank">UML Klassendiagramm</a> folgendermaßen darstellen:</p>
<div class="tutorialImgCenter">java_inheritance_uml_class_diagram.svg</div>
<p>(Getter & Setter kann man hier weglassen.)</p>
<p>Die Zusammenhänge sind nun folgende:</p>
<ul>
    <li>Jedes <code>Alien</code> ist eine <code>Creature</code></li>
    <li>Jedes <code>Sheep</code> ist eine <code>Creature</code></li>
    <li>Kein <code>Alien</code> ist ein <code>Sheep</code></li>
    <li>Kein <code>Sheep</code> ist ein <code>Alien</code></li>
    <li>Jede <code>Creature</code> ist entweder ein <code>Alien</code> oder ein <code>Sheep</code> oder keines der beiden</li>
</ul>
<p>Du siehst, dass <code>Alien</code> und <code>Sheep</code> nur noch die Attribute bzw. Methoden enthalten, die nicht sowieso schon in <code>Creature</code> enthalten sind:</p>
<h4>Alien.java</h4>
<div class="codeBlock">public class Alien extends Creature { // Alien erbt von Creature
    public Alien(float posX, float posY, int health) {
        super(posX, posY, health);
    }

    public void attack(Sheep s) {
        // ... Greife ein bestimmtes Schaf an
    }
}</div>
<h4>Sheep.java</h4>
<div class="codeBlock">public class Sheep extends Creature { // Sheep erbt von Creature
    private Color color;

    public Sheep(float posX, float posY, int health, Color color) {
        super(posX, posY, health);

        this.color = color;
    }

    public Color getColor() {
        return color;
    }
}</div>
<p>Dir ist sicher das <code>super</code> Keyword aufgefallen.</p>
<div class="info">
    <p>Mit <code>super</code> ist immer die Oberklasse gemeint. Beispielsweise ruft die Zeile</p>
    <div class="codeBlock">super(a, b, c);</div>
    <p>den Konstruktor der Klasse auf, von der die aktuelle Klasse erbt, wobei die Argumente <code>a</code>, <code>b</code> und <code>c</code> übergeben werden.</p>
    <p><code>super</code> kann auch verwendet werden, um explizit ein Attribut oder eine Methode aus der Oberklasse zu referenzieren, ähnlich wie <code>this</code> für die eigene Klasse funktioniert:</p>
    <div class="codeBlock">super.methodeXyz(); // Ruft methodeXyz() in der Oberklasse auf</div>
</div>
<h4>Eine Sache noch</h4>
<p>Der <code>private</code> Modifier verhindert ja, dass von außerhalb einer Klasse auf ein Attribut oder eine Methode zugegriffen werden kann. Das gilt auch, wenn die aufrufende Klasse von dieser erbt. Häufig will man aber von einer Unterklasse auf ein Attribut einer Oberklasse zugreifen, ohne einen Getter verwenden zu müssen. Hiefür gibt es den <code>protected</code> Modifier:</p>
<div class="info">
    <p>Mit dem <code>protected</code> Modifier gekennzeichnete Attribute und Methoden sind nicht nur von Klassen im selben Package erreichbar, sondern auch <strong>von allen</strong> eigenen <strong>Unterklassen</strong>:</p>
    <h4>A.java</h4>
    <div class="codeBlock">public class A {
    private int x;
    protected int y;
}</div>
    <h4>B.java</h4>
    <div class="codeBlock">public class B extends A {
    public void blah() {
        System.out.println(x); // Compilerfehler
        System.out.println(y); // Funktioniert
    }
}</div>
    <p>Felder, die <code>protected</code> sind, werden im <a class="externalLink" href="https://de.wikipedia.org/wiki/Klassendiagramm" target="_blank">UML Klassendiagramm</a> mit einem <code>#</code> markiert.</p>
</div>
<p>Damit kennst du nun <strong>alle</strong> Access Modifiers in Java:</p>
<table class="infoTable">
    <tr>
        <th>Modifier</th>
        <th><code>private</code></th>
        <th><i>package</i> (kein Modifier)</th>
        <th><code>protected</code></th>
        <th><code>public</code></th>
    </tr>
    <tr>
        <td>Zugriff aus eigener Klasse</td>
        <td>✔</td>
        <td>✔</td>
        <td>✔</td>
        <td>✔</td>
    </tr>
    <tr>
        <td>Zugriff aus eigenem Package</br>(Außerhalb der eigenen Klasse)</td>
        <td>❌</td>
        <td>✔</td>
        <td>✔</td>
        <td>✔</td>
    </tr>
    <tr>
        <td>Zugriff aus Unterklassen</br>(Außerhalb des eigenen Packages)</td>
        <td>❌</td>
        <td>❌</td>
        <td>✔</td>
        <td>✔</td>
    </tr>
    <tr>
        <td>Zugriff aus anderen Packages</br>(Und keine Unterklasse)</td>
        <td>❌</td>
        <td>❌</td>
        <td>❌</td>
        <td>✔</td>
    </tr>
</table>`,
    taskDescription: `<p>Erinnerst du dich an die Klasse <code>Rectangle</code> aus dem Einführungsthema zu objektorientierter Programmierung? Wir werden die Idee von geometrischen Formen als Klassen aufgreifen und um einige weitere Formen erweitern.</p>
<p>Deine Aufgabe ist es, in deiner IDE ein Projekt mit der folgenden Klassenhierarchie zu erstellen:</p>
<div class="tutorialImgCenter">java_inheritance_exercise_uml_class_diagram.svg</div>
<ul>
    <li>Erstelle alle 5 Klassen mit korrekter Verwendung von <code>extends</code></li>
    <li>Erstelle alle Attribute. <code>posX</code> und <code>posY</code> sollen <code>protected</code> sein, alle anderen <code>private</code></li>
    <li>Ergänze passende Konstruktoren in allen Klassen (denke an <code>super()</code>!)</li>
    <li>Füge alle Getter & Setter hinzu</li>
    <li>Implementiere die Methode <code>move()</code> in der Klasse <code>Shape</code>, die die X- und Y-Position der Form um (<code>dx</code> | <code>dy</code>) verändert</li>
</ul>`,
    resultDescription: '<p>Bitte überprüfe anschließend deine Lösung <strong>genau</strong>!</p>',
    possibleMistakes: [
      'Modifier vertauscht oder vergessen: <code>private</code> / <code>protected</code> / <code>public</code>',
      'Argumente im Konstruktor falsch: Du benötigst zusätzlich zu den eigenen Attributen vorher noch die der Oberklasse!',
      '<code>super()</code> Aufrufe vergessen oder falsch: Es müssen immer die Argumente übergeben werden, die der Konstruktor der Oberklasse benötigt',
      'Bei <code>Square</code> <code>width</code> und <code>height</code> übergeben, obwohl ein Quadrat nur eine Seitenlänge hat',
      'Bei <code>move()</code> die Position gesetzt, anstatt zu addieren',
      'Getter / Setter vergessen'
    ],
    solutionCode: {
      'Lösungsvorschlag Shape.java': `public class Shape {
    protected double posX;
    protected double posY;

    public Shape(double posX, double posY) {
        this.posX = posX;
        this.posY = posY;
    }

    public void move(double dx, double dy) {
        posX += dx;
        posY += dy;
    }

    public double getPosX() {
        return posX;
    }

    public void setPosX(double posX) {
        this.posX = posX;
    }

    public double getPosY() {
        return posY;
    }

    public void setPosY(double posY) {
        this.posY = posY;
    }
}`,
      'Lösungsvorschlag Rectangle.java': `public class Rectangle extends Shape {
    private double width;
    private double height;

    public Rectangle(double posX, double posY, double width, double height) {
        super(posX, posY);

        this.width = width;
        this.height = height;
    }

    public double getWidth() {
        return width;
    }

    public void setWidth(double width) {
        this.width = width;
    }

    public double getHeight() {
        return height;
    }

    public void setHeight(double height) {
        this.height = height;
    }
}`,
      'Lösungsvorschlag Square.java': `public class Square extends Rectangle {
    public Square(double posX, double posY, double size) {
        super(posX, posY, size, size); // Quadrat ist genauso breit wie hoch
    }
}`,
      'Lösungsvorschlag Circle.java': `public class Circle extends Shape {
    private double radius;

    public Circle(double posX, double posY, double radius) {
        super(posX, posY);

        this.radius = radius;
    }

    public double getRadius() {
        return radius;
    }

    public void setRadius(double radius) {
        this.radius = radius;
    }
}`
    }
  },
  {
    title: 'Methoden Überschreiben</br>und @Override',
    videoId: 'R5wzWT9fa7A',
    videoScript: `<p>Wenn eine Klasse von einer anderen erbt, werden ja alle Attribute und Methoden implizit von der Oberklasse in die Unterklasse übernommen. Folgendes Beispiel:</p>
<h4>Car.java</h4>
<div class="codeBlock">public class Car {
    protected float speed;

    // ... Konstruktor

    public void slowDown() {
        speed *= 0.8;
    }
}</div>
<h4>GolfCar.java</h4>
<div class="codeBlock">public class GolfCar extends Car {
}</div>
<p><code>GolfCar</code> erbt von <code>Car</code> und übernimmt daher implizit die Methode <code>slowDown()</code>. Wenn also irgendwo <code>someGolfCar.slowDown()</code> aufgerufen wird, passiert für das <code>GolfCar</code> genau das gleiche wie beim Aufruf von <code>slowDown()</code> auf einem <code>Car</code> Objekt. Was, wenn das Verhalten der Methode in der Unterklasse aber anders sein soll?</p>
<p>Hierfür ist es möglich, Methoden zu <i>überschreiben</i>:</p>
<div class="info">
    <p>Wenn die Signatur einer Methode der Unterklasse mit der Signatur einer Methode der Oberklasse übereinstimmt, wird beim Aufruf die Methode der Unterklasse ausgeführt. Man spricht hierbei davon, dass die Methode <i>überschrieben</i> wurde.</p>
    <p>Der Übersicht halber vermerkt man eine überschriebene Methode mit <code>@Override</code>.</p>
</div>
<p>Im gegebenen Beispiel könnte also die Klasse <code>GolfCar</code> wie folgt verändert werden:</p>
<div class="codeBlock">public class GolfCar extends Car {
    @Override
    public void slowDown() {
        speed *= 0.5;
    }
}</div>
<p>Falls nun auf ein <code>GolfCar</code> Objekt <code>slowDown()</code> aufgerufen wird, wird anstatt der Methode in <code>Car</code> nun die Methode in <code>GolfCar</code> ausgeführt.</p>
<h4>Eine Sache habe ich dir verschwiegen</h4>
<p>Wenn du eine Klasse nicht (durch Verwendung von <code>extends</code>) von einer anderen erben lässt, hat sie trotzdem eine Oberklasse. Der Grund dafür ist folgender:</p>
<div class="info">
    <p><strong>Jede</strong> Klasse (ohne explizite Oberklasse) erbt implizit von der Klasse <code>Object</code>.</p>
</div>
<p>Und das hat einige Vorteile. Beispielsweise sind in der (standardmäßig vorhandenen) Klasse <code>Object</code> die Methoden <code>toString()</code> und <code>equals()</code> implementiert:</p>
<ul>
    <li><code>toString()</code> wandelt ein Objekt in eine <code>String</code> Darstellung um. (Praktisches Feature: Falls ein Objekt an <code>System.out.println()</code> übergeben wird, gibt <code>println()</code> immer genau diese <code>String</code> Darstellung aus.)</li>
    <li><code>equals(Object o)</code> gibt <code>true</code> genau dann zurück, wenn das übergebene Objekt gleich ist wie die eigene Instanz</li>
</ul>
<p>Jede Klasse in Java kann diese Methoden überschreiben, und damit selbst angeben, wie ein Objekt dieser Klasse als <code>String</code> aussehen soll, bzw. wann zwei Objekte dieser Klasse als gleich angesehen werden. Beispiel:</p>
<div class="codeBlock">public class Employee { // Erbt implizit von Object
    private String firstName;
    private String lastName;
    private long id;

    // ... Konstruktor

    @Override
    public String toString() {
        return firstName + " " + lastName;
    }
}</div>
<p>Hier wird beim Aufruf von <code>toString()</code> nun der <code>String</code> <code>"VORNAME NACHNAME"</code> erzeugt, und nicht mehr der ursprüngliche Rückgabewert, den die Methode <code>toString()</code> in <code>Object</code> produziert hätte.</p>`,
    taskDescription: `<p>a) Füge zu der Klasse <code>Shape</code> aus der letzten Aufgabe eine Methode <code>area()</code> hinzu, die den Flächeninhalt der jeweiligen Figur als <code>double</code> zurückgibt:</p>
<div class="tutorialImgCenter">java_inheritance_exercise_2_uml_class_diagram.svg</div>
<p>In der Klasse <code>Shape</code> selbst darf als Platzhalter <code>0.0</code> zurückgegeben werden. Die restlichen Klassen sollen die Methode (falls sinnvoll) überschreiben und abhängig von der eigenen Figur einen passenden Wert zurückgeben.</p>
<h4>Formeln für Flächeninhalte</h4>
<table class="infoTable">
    <tr>
        <th>Figur</th>
        <th>Attribute</th>
        <th>Formel für Flächeninhalt</th>
    </tr>
    <tr>
        <td>Rechteck</td>
        <td>Breite a, Höhe b</td>
        <td>a &middot; b</td>
    </tr>
    <tr>
        <td>Quadrat</td>
        <td>Seitenlänge a</td>
        <td>a<sup>2</sup></td>
    </tr>
    <tr>
        <td>Kreis</td>
        <td>Radius r</td>
        <td>π &middot; r<sup>2</sup></td>
    </tr>
</table>
<p>Eine Annäherung für π bekommst du in Java über <code>Math.PI</code>.</p>`,
    resultDescription: `<p>b) Überschreibe anschließend in allen Klassen die <code>toString()</code> Methode, sodass der Code</p>
<div class="codeBlock">public static void main(String[] args) {
    System.out.println(new Shape(2.0, 3.0));
    System.out.println(new Rectangle(2.0, 3.0, 4.0, 5.0));
    System.out.println(new Square(2.0, 3.0, 4.0));
    System.out.println(new Circle(2.0, 3.0, 6.0));
}</div>
<p>folgende Ausgabe erzeugt:</p>
<pre>Figur an Position (2.0, 3.0) mit Flächeninhalt 0.0
Rechteck-Figur an Position (2.0, 3.0) mit Flächeninhalt 20.0 und den Maßen 4.0 x 5.0
Gleichseitige Rechteck-Figur an Position (2.0, 3.0) mit Flächeninhalt 16.0 und den Maßen 4.0 x 4.0
Kreis-Figur an Position (2.0, 3.0) mit Flächeninhalt 113.09733552923255 und Radius 6.0</pre>`,
    possibleMistakes: [
      '<code>java: method does not override or implement a method from a supertype</code>: Du hast ein <code>@Override</code> vor einer Methode, die gar nicht in einer Oberklasse existiert',
      'Falsche Berechnung der Flächeninhalte',
      'Falsche Verwendung der <code>super</code> Aufrufe'
    ],
    solutionCode: {
      'Lösungsvorschlag Shape.java': `public class Shape {
    protected double posX;
    protected double posY;

    // ... Konstruktor

    public void move(double dx, double dy) {
        posX += dx;
        posY += dy;
    }

    public double area() { // Kein @Override, weil das ja die originale Methode ist
        return 0.0; // Platzhalter
    }

    @Override
    public String toString() {
        return "Figur an Position (" + posX + ", " + posY + ") mit Flächeninhalt "
            + area();
    }

    // ... Getter & Setter
}`,
      'Lösungsvorschlag Rectangle.java': `public class Rectangle extends Shape {
    private double width;
    private double height;

    // ... Konstruktor

    @Override
    public double area() {
        return width * height;
    }

    @Override
    public String toString() {
        return "Rechteck-" + super.toString() + " und den Maßen "
            + width + " x " + height;

        /*
         * Der mittlere Teil ("Figur an Position [...]") kann durch super.toString()
         * ersetzt werden, weil die exakt gleiche Ausgabe sowieso schon durch die
         * toString() Methode in der Oberklasse Shape implementiert ist.
         */
    }

    // ... Getter & Setter
}`,
      'Lösungsvorschlag Square.java': `public class Square extends Rectangle {
    // ... Konstruktor

    /*
     * Keine area() Methode, weil ja in der Oberklasse Rectangle
     * schon die korrekte Implementierung steht
     *
     * (size * size ist hier das gleiche wie width * height)
     */

    @Override
    public String toString() {
        return "Gleichseitige " + super.toString();

        /*
         * Der hintere Teil ("Rechteck-Figur an Position [...]") kann durch
         * super.toString() ersetzt werden, weil die exakt gleiche Ausgabe
         * sowieso schon durch die toString() Methode in der Oberklasse
         * Rectangle implementiert ist.
         */
    }
}`,
      'Lösungsvorschlag Circle.java': `public class Circle extends Shape {
    private double radius;

    // ... Konstruktor

    @Override
    public double area() {
        return Math.PI * radius * radius;
    }

    @Override
    public String toString() {
        return "Kreis-" + super.toString() + " und Radius " + radius;

        // Erklärung analog zu Rectangle
    }

    // ... Getter & Setter
}`
    }
  },
  {
    title: 'Abstrakte Klassen',
    videoId: 'oa3SHXUkN4o',
    videoScript: `<p>Schau dir die folgende Klassenhierarchie an:</p>
<div class="tutorialImgCenter">java_inheritance_uml_class_diagram_2.svg</div>
<p>Die Methode <code>makeSound()</code> wird nun in den beiden Unterklassen überschrieben, sodass jede <code>Creature</code> einen passenden Ton erzeugt. (Das könnte z.B. das Abspielen einer Sound-Datei in einem Computerspiel sein.) Welches Geräusch macht dann aber die Kreatur <code>Creature</code> selbst?</p>
<p>Diese Frage erscheint dämlich. <code>Creature</code> ist ja nur ein Oberbegriff für die beiden <i>konkreten</i> Unterklassen <code>Alien</code> und <code>Sheep</code>. Wir sollten in der Oberklasse eigentlich gar nicht gezwungen sein, eine spezielle Implementierung für diese Methode anzugeben. Wie lösen wir das Problem?</p>
<h4>Abstrakte Klassen</h4>
<div class="info">
    <p>Eine <i>abstrakte</i> Klasse ist eine Klasse, die selbst nicht instanziiert werden kann. Man kann also nicht über den Konstruktor ein neues Objekt dieser Klasse erzeugen.</p>
    <ul>
        <li>
            <p>Abstrakte Klassen werden mit dem <code>abstract</code> Keyword gekennzeichnet:</p>
            <div class="codeBlock">public abstract class Creature {
    // ...
}</div>
        </li>
        <li>Eine abstrakte Klasse kann trotzdem einen Konstruktor haben. Dieser kann jedoch nur über <code>super()</code> (bzw. <code>this()</code>) aufgerufen werden, um beispielsweise Attribute zu setzen.</li>
        <li>
            <p>Eine abstrakte Klasse kann <i>abstrakte Methoden</i> enthalten, die zwar nur eine Methodensignatur enthalten, aber keinen Methoden-Körper:</p>
            <div class="codeBlock">public abstract void makeSound(); // Kein Körper in geschweiften Klammern!</div>
        </li>
        <li>
            <p>Abstrakte Klassen bzw. Methoden kennzeichnet man in <a class="externalLink" href="https://de.wikipedia.org/wiki/Klassendiagramm" target="_blank">UML</a> durch kursive Schrift, manchmal auch zusätzlich mit <i> &lt;&lt;abstract&gt;&gt;</i>:</p>
            <div class="tutorialImgCenter">java_uml_class_diagram_abstract.svg</div>
        </li>
    </ul>
</div>
<p>Durch verwendung von <code>abstract</code> Können wir also eine abstrakte Methode <code>makeSound()</code> in <code>Creature</code> deklarieren, ohne dabei angeben zu müssen, was bei der Ausführung passiert. Dieser Teil wird erst dadurch erledigt, dass wir die Methode in den Unterklassen überschreiben. Es kann dabei keine Probleme geben, weil durch <code>abstract</code> sichergestellt wird, dass niemals eine Instanz von <code>Creature</code> selbst existiert:</p>
<div class="codeBlock">Creature creature = new Creature(); /* error: Creature is abstract;
                                              cannot be instantiated */
creature.makeSound();</div>
<h4>Wann brauche ich das?</h4>
<p>Du brauchst abstrakte Klassen immer dann, wenn du eine Oberklasse hast, bei der es keinen Sinn ergeben würde, wenn ein Objekt dieser Klasse (und nur dieser Klasse selbst) existieren würde.</p>
<p>Beispielsweise für die Klassen <code>Car</code> und <code>GolfCar</code> sollte <code>Car</code> vermutlich <strong>nicht</strong> <code>abstract</code> sein, weil ein Auto selbst ausreichend spezifisch ist um zu existieren. Wir können direkt in der Klasse <code>Car</code> angeben, wie beispielsweise das Fahrverhalten eines (gewöhnlichen) Autos funktioniert. Für das obige Beispiel mit <code>Creature</code> und <code>Alien</code> / <code>Sheep</code> erscheint es aber sinnvoll, <code>Creature</code> als <code>abstract</code> zu deklarieren, weil die Verhaltensweise einer allgemeinen Kreatur nicht genau genug beschrieben ist. Man müsste erst wissen, um <strong>welche Art</strong> von Kreatur <strong>genau</strong> es sich handelt, um bestimmte Methoden zu implementieren.</p>
<p>Du merkst selbst, dass es hier viel Spielraum für Diskussion gibt. Wie du deine Klassen modellierst bleibt letztlich dir überlassen. Am Ende ist am wichtigsten, dass der Code nicht nur funktional, sondern auch verständlich und erweiterbar ist.</p>`,
    taskDescription: `<p>Und noch einmal das Beispiel mit den geometrischen Formen (Zum letzten Mal, versprochen!):</p>
<div class="tutorialImgCenter">java_inheritance_exercise_2_uml_class_diagram.svg</div>
<p>Überlege dir, welche der Klassen und welche der Methoden <code>abstract</code> sein sollten. Ändere deinen Code entsprechend.</p>`,
    possibleMistakes: [
      'Überflüssiger Methodenkörper in abstrakter Methode: <strong>Entweder</strong> deine Methode ist <code>abstract</code>, <strong>oder</strong> deine Methode hat geschweifte Klammern mit einem Methodenkörper.',
      'Klassen abstrakt, die nicht abstrakt sein sollten, oder umgekehrt',
      'Methoden abstrakt, die nicht abstrakt sein sollten, oder umgekehrt'
    ],
    solutionCode: `/*
 * Nur die Klasse Shape muss abstract sein. Bei allen anderen ändert sich nichts.
 * Hierdurch sparen wir uns die überflüssige Implemetierung von area()
 */
public abstract class Shape {
    protected double posX;
    protected double posY;

    /*
     * Konstruktor bleibt bestehen, weil die Unterklassen diesen
     * für den super() Aufruf benötigen
     */
    public Shape(double posX, double posY) {
        this.posX = posX;
        this.posY = posY;
    }

    // Nicht abstrakt, weil für alle Unterklassen gleiche Ausführung
    public void move(double dx, double dy) {
        posX += dx;
        posY += dy;
    }

    // Abstrakt, weil für jede Form anders
    public abstract double area();

    /*
     * Nicht abstrakt, weil von allen Unterklassen gleichermaßen benötigt
     * (für super.toString())
     */
    @Override
    public String toString() {
        return "Figur an Position (" + posX + ", " + posY + ") mit Flächeninhalt "
            + area();
    }

    // ... Getter & Setter
}`
  },
  {
    title: 'Das final Keyword',
    videoId: 'zXbvrXrInmM',
    videoScript: `<p>Bisher haben wir nur mit Variablen hantiert, deren Werte sich immer wieder ändern lassen. Was aber, wenn du eine Konstante erstellen willst?</p>
<div class="info">
    <p>Mit dem <code>final</code> Keyword lassen sich Variablen als konstant deklarieren. Eine <code>final</code> Variable kann, nachdem sie einmal gesetzt wurde, nicht mehr verändert werden.</p>
    <div class="codeBlock">final int x = 5;
x = 42; // error: cannot assign a value to final variable x</div>
</div>
    <p>Nicht nur lokale Variablen innerhalb einer Methode, sondern auch Objektvariablen können <code>final</code> sein:</p>
    <div class="codeBlock">public class Example {
    private final int x;

    public Example(int x) {
        this.x = x; // x wird ein einziges Mal gesetzt und danach nie wieder verändert
    }
}</div>
<div class="attention">
    <h4>Achtung!</h4>
    <p>Wenn eine Referenzvariable <code>final</code> ist, bedeutet das nicht, dass die Attribute des dahinterliegenden Objekts konstant sind, sondern lediglich dass sich die Referenz selbst nicht mehr ändern kann:</p>
    <div class="codeBlock">final User user = new User("Hans", "Müller", 42);

user.setFirstName("Peter"); /* Kein Fehler, weil nur Attribute
                               von user verändert werden */

user = new User("Peter", "Müller", 42); /* Fehler, weil Referenz
                                           selbst verändert wird */</div>
    <p>Gleiches Verhalten für Arrays:</p>
    <div class="codeBlock">final int[] numbers = {1, 2, 3};

numbers[2] = 7; // Kein Fehler
numbers = {1, 2, 7}; // Fehler</div>
</div>
<p>Auch Argumente von Methoden können <code>final</code> sein:</p>
<div class="codeBlock">public void f(final double x) {
    x = 2.0; // Fehler
}</div>
<h4><code>final</code> Klassen und Methoden</h4>
<p>Das <code>final</code> Keyword hat noch weitere Bedeutungen, wenn es in einer Klassen- oder Methodensignatur verwendet wird:</p>
<div class="codeBlock">public final class A {
    // ...
}</div>
<p>In diesem Fall bedeutet <code>final</code>, dass keine Klasse von <code>A</code> erben kann. Ein Beispiel kennst du hierfür bereits: Die Klasse <code>String</code> ist <code>final</code>, sie lässt sich also nicht erweitern.</p>
<div class="codeBlock">public final void doSomething() {
    // ...
}</div>
<p>In diesem Fall bedeutet <code>final</code>, dass die Methode <code>doSomething()</code> nicht in Unterklassen überschrieben werden kann.</p>`,
    taskDescription: '<p>Welche der unten stehenden Aufrufe sind zulässig, und welche erzeugen einen Fehler? Gehe jeweils davon aus, dass alle Aufrufe zuvor mit ausgeführt werden, sofern diese selbst keinen Fehler erzeugt haben.</p>',
    jDoodleId: '4QgO',
    solutionCode: `/*
 * Aufruf 1: Zulässig. Die Objektvariable a ist zwar final, aber noch nicht
 *           initialisiert. Daher darf ihr ein einziges Mal im Konstruktor ein
 *           Wert zugewiesen werden. Dass der zugewiesene Wert null ist ist
 *           irrelevant, es könnte auch eine Referenz auf jedes beliebige
 *           tatsächliche Objekt der Klasse A sein.
 *
 * Aufruf 2: Fehler. Die konstante Objektvariable b hat bereits den Wert 3.
 *
 * Aufruf 3: Fehler. Die Referenz der Objektvariable c kann nicht verändert
 *           werden, weil die Variable final ist.
 *
 * Aufruf 4: Zulässig. Das Array c selbst ist zwar final, aber die Werte
 *           innerhalb des Arrays lassen sich trotzdem verändern.
 *
 * Aufruf 5: Fehler. Das Argument d ist final.
 *
 * Aufruf 6: Zulässig. Das Argument e ist nicht final.
 *
 * Aufruf 7: Zulässig. (Analog zu 4)
 *
 * Aufruf 8: Zulässig. f[0] ist ein Objekt der Klasse A, das ein finales
 *           Objektattribut c des typs int[] hat. Da nicht die Referenz
 *           selbst verändert wird, sondern nur der Inhalt des Arrays,
 *           entsteht kein Fehler.
 *
 * Aufruf 9: Fehler. f[0] ist ein Objekt der Klasse A, das ein finales
 *           Objektattribut b des typs int hat. Die primitive Variable
 *           kann nicht verändert werden, weil sie final ist.
 */`
  },
  {
    title: 'Statische Attribute und Methoden',
    videoId: 'XDpmnaDwRmg',
    videoScript: `<p>Wie du weißt, hat jedes ausführbare Java-Programm eine <code>main</code> Methode:</p>
<div class="codeBlock">public static void main(String[] args) {
    // ...
}</div>
<p>Du kennst bereits die Bedeutung fast aller Keywords und Namen:</p>
<ul>
    <li><code>public</code>: Die Methode ist aus anderen Klassen sichtbar</li>
    <li><code>void</code>: Die Methode hat keinen Rückgabewert</li>
    <li><code>main</code>: Die Methode heißt <i>main</i></li>
    <li><code>String[]</code>: Es gibt einen Parameter des Typs <code>String</code>-Array (also mehrere Text-Variablen)</li>
    <li><code>args</code>: Dieser Parameter heißt <i>args</i></li>
</ul>
<h4>Aber was zur Hölle heißt dieses <code>static</code>?</h4>
<div class="info">
    <p><code>static</code> ist ein Keyword, das es (<strong>entgegen klassischer Konventionen</strong> der Objektorientierten Programmierung) erlaubt, ein Attribut oder eine Methode <strong>unabhängig von seiner Klasse</strong> zu machen.</p>
</div>
<h4>Beispiel: Statische Attribute</h4>
<div class="codeBlock">public class User {
    public static int totalNumberOfUsers;

    public User() {
        totalNumberOfUsers++;
    }
}</div>
<p>In dieser Klasse gibt es das <i>statische</i> Attribut <code>totalNumberOfUsers</code>. Man nennt solche Attribute (statt Objektattribut) dann auch <i>Klassenattribut</i>.</p>
<p>Bei jedem Aufruf des Konstruktors wird dieses Klassenattribut inkrementiert. Da <code>totalNumberOfUsers</code> <code>static</code> ist, existiert <strong>nicht jeweils ein Wert</strong> für jedes existierende <code>User</code> Objekt, sondern <strong>ein einziger Wert für alle Objekte</strong>.</p>
<h4>Statische Methoden</h4>
<p>Es gibt (offensichtlich) auch statische Methoden. Diese benötigen <strong>kein existierendes Objekt</strong> der Klasse, in der sie implementiert sind. Stattdessen können sie direkt über den Namen der Klasse aufgerufen werden:</p>
<div class="codeBlock">public class MathUtil {
    public static double square(double x) {
        return x * x;
    }
}</div>
<p>In dieser Klasse könnte die Methode <code>square()</code> beispielsweise über <code>MathUtil.square(42.0)</code> aufgerufen werden, ohne dass jemals ein Objekt der Klasse <code>MathUtil</code> existieren muss.</p>
<h4>Und jetzt kommt das ABER</h4>
<p>Ich möchte dich auf folgende, nicht repräsentative Statistik hinweisen:</p>
<div class="attention">
    <p><strong>In 99% aller Fällen sollte dein Attribut nicht <code>static</code> sein.</strong></p>
    <p>Statische Attribute zerreißen die Grundidee der objektorientierten Programmierung, dass jedes "Ding" ein Objekt ist, und jedes Objekt aus Attributen <strong>mit eigenen Werten</strong> besteht. Ein statisches Attribut hat <strong>nichts</strong> mit den einzelnen Instanzen der Klasse zu tun.</p>
</div>
<p>Im obigen Beispiel mit der Klasse <code>User</code> wäre es also besser, die Anzahl der existierenden Objekte in der Klasse zu zählen, in der die Instanzen auch erstellt werden. Dann könnte man sich das <code>static</code> sparen.</p>
<p>Eine Ausnahme, in der es völlig legitim sein <strong>kann</strong>, statische Attribute zu deklarieren, ist bei Konstanten:</p>
<div class="codeBlock">public class SuperFancyAlgorithm {
    public static final double SQRT_2_HALF = Math.sqrt(2.0) / 2.0;

    // ...
}</div>
<div class="info">
    <p>Statische Konstanten sollten in Java immer in Großbuchstaben und durch Unterstriche getrennt benannt werden.</p>
</div>
<p>Übrigens: Die Verwendung statischer Methoden ist deutlich gängiger. Aber auch hier solltest du dir immer die Frage stellen, ob diese Methode jetzt wirklich mit <strong>genau der</strong> Klasse zusammenhängt, in der du sie implementierst. Deine statische Methode könnte schließlich auch in jeder anderen Klasse stehen.</p>
<h4>Beispiele, wo du statische Attribute / Methoden schon kennengelernt hast</h4>
<ul>
    <li><code>public static void main(String[] args)</code>: Die <code>main</code> Methode muss statisch sein, damit sie ohne Existenz eines Objekts ausgeführt werden kann.</li>
    <li><code>System.out.println()</code>: Die Klasse <code>System</code> enthält das statische Attribut <code>out</code>, was wiederum eine Instanz der Klasse <code>java.io.PrintStream</code> ist, die die Methode <code>println()</code> enthält.</li>
    <li><code>Math.sqrt()</code> und ähnliche statische Methoden können aufgerufen werden, ohne dass es ein Objekt der Klasse <code>Math</code> geben muss.</li>
</ul>`,
    taskDescription: `<p>Wie du bereits weißt, sind <code>double</code> Werte in Java nicht exakt. Wir wollen nun eine Klasse <code>Rational</code> erstellen, die es uns ermöglicht, mit exakten rationalen Zahlen zu rechnen.</p>
<p>Eine rationale Zahl besteht aus einem Zähler (<i>numerator</i>) und einem Nenner (<i>denominator</i>), die beide Ganzzahlen sind. Der Nenner darf dabei nicht 0 sein.</p>
<p>Deine Aufgabe ist es, die folgenden Methoden zu implementieren:</p>
<ul>
    <li><code>Rational fromLong(long l)</code> bekommt eine Ganzzahl übergeben und konvertiert diese in ein neues <code>Rational</code> Objekt, sodass z.B. aus der Zahl <code>7L</code> der Bruch 7/1 wird</li>
    <li><code>Rational mul(Rational r)</code> multipliziert die eigene Instanz mit <code>r</code> und gibt das Ergebnis <strong>als neue Instanz</strong> zurück</li>
    <li><code>Rational div(Rational r)</code> dividiert die eigene Instanz durch <code>r</code> und gibt das Ergebnis <strong>als neue Instanz</strong> zurück</li>
    <li><code>Rational add(Rational r)</code> addiert die eigene Instanz mit <code>r</code> und gibt das Ergebnis <strong>als neue Instanz</strong> zurück</li>
    <li><code>Rational sub(Rational r)</code> subtrahiert <code>r</code> von der eigenen Instanz und gibt das Ergebnis <strong>als neue Instanz</strong> zurück</li>
    <li><code>double toDouble()</code> konvertiert den Bruch zu einer (ggf. fehlerbehafteten) Fließkommazahl</li>
    <li><code>String toString()</code> erzeugt beispielsweise für den Bruch 3/4 den <code>String</code> <code>"3/4"</code></li>
</ul>
<p>Außerdem sollst du die folgenden Brüche als konstante Attribute der Klasse hinzufügen:</p>
<ul>
    <li><code>ZERO</code> (0)</li>
    <li><code>ONE_TENTH</code> (1/10)</li>
    <li><code>ONE_HALF</code> (1/2)</li>
    <li><code>ONE</code> (1)</li>
    <li><code>TWO</code> (2)</li>
    <li><code>TEN</code> (10)</li>
</ul>
<p>Entscheide hierbei jeweils, ob die Methode bzw das Attribut <code>static</code> sein sollte oder nicht.</p>
<div class="info">
    <h4>Formeln zur Verrechnung zweier Brüche</h4>
    <div class="tutorialImgCenter">fraction_calculations.svg</div>
</div>
<p>Die Methode <code>reduce()</code> ist bereits implementiert und kürzt den jeweiligen Bruch maximal. Die Funktionsweise dieser Methode ist nicht relevant.</p>
<p>Du kannst diese Aufgabe entweder online oder in deiner IDE bearbeiten.</p>`,
    jDoodleId: '4Q4q',
    resultDescription: `<p>Die Konsolenausgabe sollte so aussehen:</p>
<pre>42 als Bruch ist 42/1
1/2 + 3/4 = 5/4
1/2 - 3/4 = -1/4
1/2 * 3/4 = 3/8
(1/2) / (3/4) = 2/3
7/4 als Fließkommazahl ist 1.75
0.7 + 0.2 = 0.8999999999999999
7/10 + 2/10 = 9/10</pre>`,
    possibleMistakes: [
      'Instanzen verwechselt oder Reihenfolge verdreht (z.B. <code>this</code> statt <code>r</code>)',
      '<code>static</code> fehlt oder ist überflüssig',
      'Ganzzahldivision statt Fließkommadivision bei <code>toDouble()</code>'
    ],
    solutionCode: `public class Rational {
    // Statische Konstanten (Existieren unabhängig von einer einzelnen Rational Instanz)
    public static final Rational ZERO = new Rational(0L, 1L);
    public static final Rational ONE_TENTH = new Rational(1L, 10L);
    public static final Rational ONE_HALF = new Rational(1L, 2L);
    public static final Rational ONE = new Rational(1L, 1L);
    public static final Rational TWO = new Rational(2L, 1L);
    public static final Rational TEN = new Rational(10L, 1L);

    private final long numerator;
    private final long denominator;

    public Rational(long numerator, long denominator) {
        if (denominator < 0L) {
            numerator *= -1L;
            denominator *= -1L;
        }
        this.numerator = numerator;
        this.denominator = denominator;
    }

    /*
     * Statische Methode, weil hier ein Rational Objekt erstellt wird,
     * ohne dass vorher schon eine Instanz existieren muss. Dadurch kann
     * man später z.B das hier machen:
     *
     * Rational seven = Rational.fromLong(7L);
     */
    public static Rational fromLong(long l) {
        return new Rational(l, 1L); // Jede ganze Zahl ist gleich sich selbst geteilt durch 1
    }

    /*
     * Die arithmetischen Methoden sind nicht static, weil man hier die
     * existierende (eigene) Instanz als linken Operanden verwendet.
     */
    public Rational mul(Rational r) {
        return new Rational(this.numerator * r.numerator, this.denominator * r.denominator);
    }

    public Rational div(Rational r) {
        return new Rational(this.numerator * r.denominator, this.denominator * r.numerator);
    }

    public Rational add(Rational r) {
        return new Rational(this.numerator * r.denominator + r.numerator * this.denominator,
            this.denominator * r.denominator);
    }

    public Rational sub(Rational r) {
        return new Rational(this.numerator * r.denominator - r.numerator * this.denominator,
            this.denominator * r.denominator);
    }

    public Rational reduce() {
        long gcd = numerator;
        long b = denominator;
        while (b != 0L) {
            long tmp = b;
            b = gcd % b;
            gcd = tmp;
        }
        return new Rational(numerator / gcd, denominator / gcd);
    }

    // Auch hier nicht static, weil es um die eigene Instanz geht
    public double toDouble() {
        return ((double) numerator) / ((double) denominator);
    }

    @Override
    public String toString() {
        return numerator + "/" + denominator;
    }

    public static void main(String[] args) {
        System.out.println("42 als Bruch ist " + Rational.fromLong(42L).reduce());

        Rational threeFourths = new Rational(3L, 4L);
        System.out.println(Rational.ONE_HALF + " + " + threeFourths + " = " + (Rational.ONE_HALF.add(threeFourths).reduce()));
        System.out.println(Rational.ONE_HALF + " - " + threeFourths + " = " + (Rational.ONE_HALF.sub(threeFourths).reduce()));
        System.out.println(Rational.ONE_HALF + " * " + threeFourths + " = " + (Rational.ONE_HALF.mul(threeFourths).reduce()));
        System.out.println("(" + Rational.ONE_HALF + ") / (" + threeFourths + ") = " + (Rational.ONE_HALF.div(threeFourths).reduce()));

        System.out.println("7/4 als Fließkommazahl ist " + new Rational(7L, 4L).toDouble());

        // Vergleich double VS Rational
        System.out.println("0.7 + 0.2 = " + (0.7 + 0.2));
        System.out.println("7/10 + 2/10 = " + new Rational(7L, 10L).add(new Rational(2L, 10L)).reduce());
    }
}`
  },
  {
    title: 'Alles hat ein Ende...',
    videoId: '15M_5m91pVI',
    videoScript: `<p>Und hiermit sind wir auch schon am Ende dieses Grundlagen-Kurses. Versteh mich bitte nicht falsch, wir haben noch lange nicht alle Themen in Java behandelt. Aber bevor du dein vermutlich sowieso schon qualmendes Hirn mit weiterem Input belastest, solltest du lieber erst einmal selbst weiter üben. Setz dir ein Ziel und fang einfach an, zu programmieren. Wenn du nicht weiter weißt, hat das Internet auf fast jede deiner Fragen eine Antwort parat.</p>
<p>Falls du dich doch dafür interessierst, noch mehr zu lernen, findest du hier eine Liste an Themen, die wir noch nicht behandelt haben:</p>
<ul>
    <li>Interfaces</li>
    <li>Enums</li>
    <li>Exceptions (<code>throw</code>, <code>throws</code>, <code>try</code>, <code>catch</code>, <code>finally</code>)</li>
    <li>Listen und andere Datenstrukturen, Iteratoren (<code>Iterator</code>, <code>Iterable</code> Interfaces)</li>
    <li>Generische Typen</li>
    <li>Polymorphie: Statische und Dynamische Typen, Dynamic Dispatch, <code>instanceof</code></li>
    <li>Bitweise Binäre Operatoren</li>
    <li>Ternärer Operator</li>
    <li>Verschachtelte (nested) Klassen</li>
    <li>Funktionale Programmierung: Lambda Ausdrücke und Streams</li>
    <li>Nebenläufigkeit (Threads)</li>
    <li>Netzwerkprogrammierung mit Sockets</li>
    <li>Grafische Benutzeroberflächen (GUIs)</li>
    <li>Unit Testing</li>
</ul>`,
    taskDescription: `<h4>Hat dir dieser Kurs gefallen?</h4>
<iframe id="kofiframe" src="https://ko-fi.com/javaabc/?hidefeed=true&widget=true&embed=true&preview=true" allowtransparency="true" style="border:none;width:100%;background:#ffffff;" height="712" title="javaABC"></iframe>`,
    jDoodleId: '4zze',
    solutionCode: {}
  }
]

/**
 * Formats a given string to be URL-safe while preserving great readability.
 *
 * @param title the string to format
 * @returns {string} the formatted URL-safe string
 */
function urlTitle (title) {
  return '/basics/' + encodeURIComponent(title //
    .replace(/(<([^>]+)>)/ig, '_') //
    .replace(/\s/g, '_') //
    .replace(/[^a-zA-ZäöüÄÖÜß0-9-_()!~*'.]/g, '') //
    .replace(/(_+)/g, '_') //
    .replace(/_$/g, '') //
    .replace(/^_/g, ''))
}

// Remove non-public tutorials
tutorials = tutorials.filter((t) => t.public !== false)

// Add links
tutorials[0].previousUrl = null
tutorials[tutorials.length - 1].nextUrl = null
tutorials[tutorials.length - 1].tutorialId = tutorials.length - 1
for (let i = 0; i < tutorials.length - 1; i++) {
  const curr = tutorials[i]
  const next = tutorials[i + 1]
  curr.nextUrl = urlTitle(next.title)
  next.previousUrl = urlTitle(curr.title)
  curr.path = next.previousUrl
  curr.tutorialId = i

  if (typeof curr.solutionCode === 'string') {
    curr.solutionCode = { Lösungsvorschlag: curr.solutionCode }
  }
}
tutorials[tutorials.length - 1].path = tutorials[tutorials.length - 2].nextUrl

// List of topics for navigation bar
const topicList = tutorials.map(t => {
  return ({
    title: t.title.replaceAll('</br>', ' '),
    link: t.path
  })
})
topicList[0].title = 'Konsolenausgabe'
for (let i = 0; i < tutorials.length; i++) {
  tutorials[i].topicListBefore = topicList.slice(0, i)
  tutorials[i].topicListCurrent = topicList[i]
  tutorials[i].topicListAfter = topicList.slice(i + 1)
}

export default tutorials
