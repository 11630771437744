<template>
  <div id="headerArea">
    <a href="/">
      <img id="logo" :src="getLogoUrl()" alt="javaABC"/>
    </a>
  </div>
</template>

<script>
export default {
  name: 'HeaderArea',
  props: {
    topic: String
  },
  methods: {
    getLogoUrl () {
      const images = require.context('../assets', false, /\.(png|jpg|jpeg|svg)$/)
      return images('./javaABC_logo.svg')
    }
  }
}
</script>

<style scoped>
#headerArea {
  padding-left: 8px;
  background: white;
  box-shadow: 0 0 8px #00000080;
}

#logo {
  height: 64px;
}
</style>
