/* eslint space-before-function-paren: 0 */
/* eslint object-curly-spacing: 0 */

import { createRouter, createWebHistory } from 'vue-router'
import tutorials from '../../data/tutorial_basics'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: tutorials[0].path
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/AboutPage.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../views/ImpressumPage.vue')
  },
  {
    path: '/privacy',
    name: 'Datenschutz',
    component: () => import('../views/PrivacyPage.vue')
  }
]

for (let i = 0; i < tutorials.length; i++) {
  routes.push(
    {
      path: tutorials[i].path,
      component: () => import('../views/TutorialPage.vue'),
      props: tutorials[i]
    }
  )
}

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, _from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else {
      window.scrollTo(0, 0)
    }
  }
})
