<template>
  <div id="footerArea">
    <table id="footerTable">
      <tr>
        <th>Allgemein</th>
        <th>Social</th>
        <th>Rechtliches</th>
      </tr>
      <tr>
        <td>
          <router-link to="/">Home</router-link>
        </td>
        <td>
          <a href="https://www.instagram.com/javaabcde" target="_blank">Instagram</a>
        </td>
        <td>
          <router-link to="/privacy">Datenschutz</router-link>
        </td>
      </tr>
      <tr>
        <td>
          <router-link to="/about">Über</router-link>
        </td>
        <td>
          <a href="https://www.youtube.com/channel/UCqiZYrwXyMA_FYoNA90d21Q" target="_blank">YouTube</a>
        </td>
        <td>
          <router-link to="/impressum">Impressum</router-link>
        </td>
      </tr>
      <tr>
        <td>
        </td>
        <td>
          <a href="https://www.tiktok.com/@javaabcde" target="_blank">TikTok</a>
        </td>
        <td>
        </td>
      </tr>
      <tr>
        <td>
        </td>
        <td>
          <a href="https://ko-fi.com/javaabc" target="_blank">Ko-fi</a>
        </td>
        <td>
        </td>
      </tr>
    </table>
    <p id="loveSlogan">Made with love by a former noob.</p>
    <p>© 2022-2024 Timo Friedl</p>
  </div>
</template>

<script>
export default {
  name: 'FooterArea'
}
</script>

<style scoped>
#footerArea {
  padding: 36px;
  background: #303030;
  color: lightgray;
  horiz-align: center;
  text-align: center;
}

table {
  table-layout: revert;
  width: fit-content;
}

td {
  padding-left: 16px;
  padding-right: 16px;
}

th {
  color: white;
}

a {
  color: lightgray;
}

p {
  color: gray;
}

#footerTable {
  margin-left: auto;
  margin-right: auto;
}

#loveSlogan {
  font-family: 'Lobster', serif;
}
</style>
