<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | javaABC` : `javaABC` }}</template>
  </metainfo>
  <div id="farApp">
    <HeaderArea/>
    <router-view id="content"/>
    <div id="footerSpace"/>
    <FooterArea/>
  </div>
</template>

<script>
/* eslint vue/no-unused-components: 0 */
import HeaderArea from '@/components/HeaderArea'
import FooterArea from '@/components/FooterArea'
import {useMeta} from 'vue-meta'

export default {
  name: 'App',
  components: {
    HeaderArea: HeaderArea,
    FooterArea: FooterArea
  },
  setup() {
    useMeta({
      title: '',
      htmlAttrs: {lang: 'de', amp: true}
    })
  }
}
</script>

<style>
@font-face {
  font-family: "Sora";
  src: local("Sora"), url(./fonts/Sora/static/Sora-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lobster";
  src: local("Lobster"), url(./fonts/Lobster/Lobster-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "JetBrains Mono";
  src: local("JetBrains Mono"), url(./fonts/JetBrains_Mono/static/JetBrainsMono-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  background: white;
  position: relative;
}

html, body, form, fieldset, table, tr, td, img {
  margin: 0;
  padding: 0;
  font-family: 'Sora', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

button, select, optgroup, option, code, pre, a {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

#farApp {
  color: black;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

h1 {
  font-size: min(28px, 4.5vw);
  text-align: center;
  margin: 64px 0 32px 0;
}

p {
}

.justify {
  text-align: justify;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.infoTable {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;
  border-spacing: 4px;
  border-collapse: separate;
  word-wrap: break-word;
}

.infoTable td {
  background: #f0f0f0;
  padding: 4px;
}

.infoTable th {
  background: #dddddd;
  padding: 4px;
}

.rightAlign {
  text-align: right;
}

.leftAlign {
  text-align: left;
}

.catBlue td {
  background: #ddddff;
}

.catGreen td {
  background: #ddffdd;
}

#footerSpace {
  flex: 1;
  min-height: 64px;
}

#content {
  align-self: center;
  width: 768px;
  max-width: calc(100% - 16px);
  margin: 0 8px 0 8px;
}

code, pre {
  font-size: 90%;
  font-family: 'JetBrains Mono', 'Consolas';
  font-variant-ligatures: none;
  background: #303030;
  color: white;
}

code {
  padding: 0 2px;
}

pre {
  width: calc(100% - 8px);
  padding: 2px 4px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

li {
  margin: 8px 0;
}

.attention {
  border-left: 4px solid #e00000;
  padding: 8px;
  margin: 16px 0;
  background: #ffe0d0;
}

.info {
  border-left: 4px solid #e0c000;
  padding: 8px;
  margin: 16px 0;
  background: #ffffd0;
}

.good {
  border-left: 4px solid #00e000;
  padding: 8px;
  margin: 16px 0;
  background: #d0ffd0;
}

summary {
  outline: none;
  display: block;
  background-color: #dddddd;
  padding: 8px 8px 8px 2.2rem;
  position: relative;
  cursor: pointer;
}

summary::selection {
  background: transparent;
}

summary::-moz-selection {
  background: transparent;
}

details {
  width: 100%;
  box-sizing: border-box;
  margin: 8px 0;
}

details summary::-webkit-details-marker {
  display: none;
}

details[open] > summary:before {
  transform: rotate(90deg);
}

summary:before {
  content: '';
  border-width: .4rem;
  border-style: solid;
  border-color: transparent transparent transparent #303030;
  position: absolute;
  top: 12px;
  left: 1rem;
  transform: rotate(0);
  transform-origin: .2rem 50%;
  transition: .25s transform ease;
}

.content {
  border: 2px solid #dddddd;
  border-top: none;
  padding: 8px;
}

.externalLink {
  color: orange;
}
</style>
